import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/AuthContext";
import { BenefitDxProvider } from "./contexts/BenefitDxContext";

const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | BenefitDx"
          defaultTitle="BenefitDx - Your Healthcare Management Monitor"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <BenefitDxProvider>{content}</BenefitDxProvider>
              </QueryClientProvider>
            </AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
