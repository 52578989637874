import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridRowId, DataGridProProps } from "@mui/x-data-grid-pro";
import CRUDTable from "../../../components/Table";
import { useProcedures } from "../../../hooks/useProcedures";

export function Hospitals() {
  const { t } = useTranslation();
  const {
    procedures,
    createProcedure,
    updateProcedure,
    deleteProcedure,
    isListProceduresPending,
    isUpdateProcedurePending,
    isDeleteProcedurePending,
    isCreateProcedurePending,
    listProceduresNextPage,
  } = useProcedures();

  return (
    <Paper sx={{ width: "100%", marginTop: 5 }}>
      <Helmet title={t("Procedures") as string} />
      <Grid container direction={"column"}>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <CRUDTable
              entity={{
                Type: "procedure",
                Fields: [
                  {
                    Name: "Name",
                    Label: "Name",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                  {
                    Name: "HMSCode",
                    Label: "HMS Code",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                  {
                    Name: "HUVCode",
                    Label: "HUV Code",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                  {
                    Name: "SUTCode",
                    Label: "SUT Code",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                  {
                    Name: "TDBCode",
                    Label: "TDB Code",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                ],
              }}
              loading={
                isListProceduresPending ||
                isCreateProcedurePending ||
                isUpdateProcedurePending ||
                isDeleteProcedurePending
              }
              rows={procedures}
              generateUrl={(id: GridRowId) => {
                return `/procedures/${id}`;
              }}
              DetailsView={null}
              create={createProcedure as any}
              update={updateProcedure as any}
              delete={deleteProcedure as any}
              fetchNextPage={listProceduresNextPage}
            />
          </CardContent>
        </Card>
      </Grid>
    </Paper>
  );
}

export default Hospitals;
