import axios from "../utils/axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { Doctor } from "../types";

export function useDoctors() {
  const client = useQueryClient();
  const { session } = useAuth();

  const {
    error: listDoctorsError,
    data: doctors,
    isFetching: isListDoctorsFetching,
  } = useQuery({
    enabled: session?.isValid(),
    refetchOnWindowFocus: false,

    queryKey: ["doctors"],
    queryFn: async () => (await axios.post(`/doctors`)).data.Items,
  });

  const {
    mutate: createDoctor,
    isPending: isCreateDoctorPending,
    error: createDoctorError,
  } = useMutation({
    mutationFn: async (payload: Partial<Doctor>) =>
      await axios.post(`/doctors/new`, {
        ...payload,
      }),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["doctors"],
      });
    },
  });

  const {
    mutate: updateDoctor,
    isPending: isUpdateDoctorPending,
    error: updateDoctorError,
  } = useMutation({
    mutationFn: async (updatedDoctor: Doctor) =>
      await axios.put(`/doctors/${updatedDoctor?.Id}`, updatedDoctor),
    onSuccess: (_data, variables, _context) => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["doctors", variables?.Id],
      });
    },
  });

  const {
    mutate: deleteDoctor,
    isPending: isDeleteDoctorPending,
    error: deleteDoctorError,
  } = useMutation({
    mutationFn: async (Id: string) => await axios.delete(`/doctors/${Id}`),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["doctors"],
      });
    },
  });

  return {
    listDoctorsError,
    createDoctorError,
    updateDoctorError,
    deleteDoctorError,
    isListDoctorsPending: isListDoctorsFetching,
    isCreateDoctorPending,
    isUpdateDoctorPending,
    isDeleteDoctorPending,
    doctors: doctors || [],
    createDoctor,
    updateDoctor,
    deleteDoctor,
  };
}
