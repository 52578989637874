import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { AccessLevel } from "../../../types";
import { useNavigate } from "react-router-dom";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";
import { FormProps, BenefitDxForm } from "../../../components/Form";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

function CreateUser() {
  const { createUser } = useBenefitDxContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formProps: FormProps = {
    properties: [
      {
        name: "Email",
        label: "Email",
        required: true,
        type: "text",
        initialValue: "",
      },
      {
        name: "FirstName",
        label: "First name",
        required: true,
        type: "text",
        initialValue: "",
      },
      {
        name: "LastName",
        label: "Last name",
        required: true,
        type: "text",
        initialValue: "",
      },
      {
        name: "AccessLevel",
        label: "Access level",
        required: true,
        type: "select",
        initialValue: AccessLevel.Department,
        options: [
          {
            label: "Department",
            value: 0,
          },
          {
            label: "Hospital",
            value: 1,
          },
        ],
      },
    ],
    onSubmit: async (values: any) => {
      const { submit, AccessLevel: Level, ...userData } = values;
      userData.Access = {
        Level,
      };
      const user = await createUser(userData);
      navigate(`/users/${user.Id}`);
    },
  };
  return (
    <React.Fragment>
      <Helmet title={t("Add new user") as string} />
      <Grid
        justifyContent="space-between"
        container
        spacing={6}
        direction={"column"}
      >
        <Grid>
          <Typography variant="h3" gutterBottom>
            {t("New user")}
          </Typography>
        </Grid>
        <Divider my={6} />
        <Grid>
          <BenefitDxForm {...formProps} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateUser;
