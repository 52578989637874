import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 510 510"
    {...props}
  >
    <path
      fill="#8d9ca8"
      d="M287.755 470.924v7.584c0 9.123-7.364 16.488-16.487 16.488h-32.206c-9.123 0-16.597-7.365-16.597-16.488v-7.584H40.883c-5.056 0-9.233-4.177-9.233-9.343V158.872c0-5.166 4.177-9.343 9.233-9.343h428.124a9.335 9.335 0 0 1 9.343 9.343v302.709a9.335 9.335 0 0 1-9.343 9.343z"
    />
    <path
      fill="#8192a5"
      d="M60.558 149.529h388.774v275.56c0 12.201-9.782 22.203-22.093 22.313-51.111.55-115.962 5.716-161.687 30.227-6.705 3.627-14.509 3.627-21.104 0-45.835-24.511-110.686-29.677-161.797-30.227-12.201-.11-22.093-10.112-22.093-22.313z"
    />
    <path
      fill="#d1d1d6"
      d="M82.981 123.369c84.416.659 108.268 9.123 172.019 26.16 63.751-17.037 87.493-25.501 172.019-26.16 2.528 0 4.726.989 6.595 2.748 1.759 1.868 2.748 4.066 2.748 6.594v292.378c0 5.056-4.177 9.233-9.233 9.233-61.883.659-123.766 8.244-167.732 31.876-2.858 1.538-6.046 1.538-8.794 0-44.076-23.632-105.959-31.217-167.842-31.876-5.056 0-9.233-4.177-9.233-9.233V132.711c0-2.528.989-4.726 2.748-6.594 1.869-1.759 4.067-2.748 6.705-2.748z"
    />
    <path
      fill="#b1b1b5"
      d="M436.362 406.403v18.686c0 5.056-4.177 9.233-9.233 9.233-61.883.659-123.766 8.244-167.732 31.876-2.858 1.538-6.046 1.538-8.794 0-44.076-23.632-105.959-31.217-167.842-31.876-5.056 0-9.233-4.177-9.233-9.233v-18.686c0 5.166 4.177 9.233 9.233 9.343 61.883.66 123.766 8.134 167.842 31.766 2.748 1.539 5.936 1.539 8.794 0 43.966-23.632 105.849-31.106 167.732-31.766 5.056-.11 9.233-4.177 9.233-9.343z"
    />
    <path
      fill="#eceff1"
      d="M255 149.529c63.751-17.037 87.493-25.501 172.019-26.16 2.528 0 4.726.989 6.595 2.748 1.759 1.868 2.748 4.066 2.748 6.594v292.378c0 5.056-4.177 9.233-9.233 9.233-61.883.659-123.766 8.244-167.732 31.876a9.31 9.31 0 0 1-4.397 1.099z"
    />
    <path
      fill="#d1d1d6"
      d="M436.362 406.403v18.686c0 5.056-4.177 9.233-9.233 9.233-61.883.659-123.766 8.244-167.732 31.876a9.31 9.31 0 0 1-4.397 1.099v-18.576c1.429 0 2.968-.44 4.397-1.209 43.966-23.632 105.849-31.106 167.732-31.766 5.056-.11 9.233-4.177 9.233-9.343z"
    />
    <path
      fill="#6cf5c2"
      d="m255 15.004 143.28 61.417v115.858c0 80.622-58.677 150.291-138.059 164.13l-5.221.912-5.221-.912c-79.382-13.839-138.059-83.508-138.059-164.13V76.421z"
    />
    <path
      fill="#00e499"
      d="m250 17.147 5-2.143 143.28 61.417v115.858c0 80.622-58.677 150.291-138.059 164.13l-5.221.912-5-.874.221-.038c79.382-13.839 138.059-83.508 138.059-164.13V76.421z"
    />
    <path
      fill="#eceff1"
      d="m255 56.447-105.196 45.059v90.773c0 61.672 44.693 115.165 105.196 126.374 60.54-11.209 105.233-64.702 105.233-126.374v-90.773z"
    />
    <path
      fill="#d1d1d6"
      d="m260.001 58.589-100.197 42.917v90.773c0 59.961 42.247 112.19 100.197 125.344-1.654.376-3.321.719-5.001 1.03-60.503-11.209-105.196-64.702-105.196-126.374v-90.773L255 56.447z"
    />
    <path
      fill="#fe646f"
      d="M234.003 126.305c0-1.326.527-2.598 1.465-3.535a4.997 4.997 0 0 1 3.535-1.465h31.994c1.326 0 2.598.527 3.535 1.465a4.997 4.997 0 0 1 1.465 3.535v33.861c0 1.326.527 2.598 1.464 3.536a5.004 5.004 0 0 0 3.536 1.464h33.861c1.326 0 2.598.527 3.535 1.465a4.997 4.997 0 0 1 1.465 3.535v31.994a4.997 4.997 0 0 1-1.465 3.535 4.997 4.997 0 0 1-3.535 1.465h-33.861a5 5 0 0 0-5 5v33.861a4.999 4.999 0 0 1-5 5h-31.994a4.999 4.999 0 0 1-5-5V212.16a5 5 0 0 0-5-5h-33.861a4.997 4.997 0 0 1-3.535-1.465 4.997 4.997 0 0 1-1.465-3.535v-31.994c0-1.326.527-2.598 1.465-3.535a4.997 4.997 0 0 1 3.535-1.465h33.861a5.004 5.004 0 0 0 3.536-1.464 5.004 5.004 0 0 0 1.464-3.536z"
    />
    <path
      fill="#fd4755"
      d="M205.142 207.16h-10a4.997 4.997 0 0 1-3.535-1.465 4.997 4.997 0 0 1-1.465-3.535v-31.994c0-1.326.527-2.598 1.465-3.535a4.997 4.997 0 0 1 3.535-1.465h10a4.997 4.997 0 0 0-3.535 1.465 4.997 4.997 0 0 0-1.465 3.535v31.994c0 1.326.527 2.598 1.465 3.535a4.997 4.997 0 0 0 3.535 1.465zm23.861-41.994a5.004 5.004 0 0 0 3.536-1.464 5.004 5.004 0 0 0 1.464-3.536v-33.861c0-1.326.527-2.598 1.465-3.535a4.997 4.997 0 0 1 3.535-1.465h10a4.997 4.997 0 0 0-3.535 1.465 4.997 4.997 0 0 0-1.465 3.535v33.861a5.004 5.004 0 0 1-1.464 3.536 5.004 5.004 0 0 1-3.536 1.464zm20 85.855h-10a4.999 4.999 0 0 1-5-5V212.16a5 5 0 0 0-5-5h10a5 5 0 0 1 5 5v33.861a4.999 4.999 0 0 0 5 5z"
    />
  </svg>
);
export default SvgComponent;
