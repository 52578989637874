import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import {
  Unstable_Grid2 as Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";
import Logo from "../Logo";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  onDrawerToggle?: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={1}>
        <Toolbar>
          <Grid
            container
            direction={"row"}
            alignItems="center"
            justifyContent={"flex-end"}
            sx={{ width: "100%" }}
          >
            <Grid flexGrow={0} sx={{ display: { xs: "block", md: "none" } }}>
              {onDrawerToggle ? (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <Logo />
              )}
            </Grid>
            <Grid
              flexGrow={1}
              container
              justifyContent={"flex-end"}
              direction={"row"}
            >
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
