import axios from "../utils/axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { Upload } from "../types";

export type useUploadProps = {
  UploadId?: string;
  MappingId?: string;
};

export function useUpload({ UploadId, MappingId }: useUploadProps) {
  const client = useQueryClient();
  const { session } = useAuth();

  const {
    error: fetchUploadError,
    data: upload,
    isPending: isFetchUploadPending,
  } = useQuery({
    enabled: Boolean(MappingId) && Boolean(UploadId) && session?.isValid(),
    queryKey: ["mappings", MappingId, "uploads", UploadId],
    refetchOnWindowFocus: false,
    queryFn: async () => (await axios.get(`/uploads/${UploadId}`)).data,
  });

  const update = async (payload: Partial<Upload>) =>
    await axios.put(`/uploads/${UploadId}`, {
      ...(upload || {}),
      MappingId,
      ...payload,
    });

  const {
    mutate: updateUpload,
    isPending: isUpdateUploadPending,
    error: updateUploadError,
  } = useMutation({
    mutationFn: update,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["uploads", UploadId],
      });
    },
  });

  return {
    upload,
    isFetchUploadPending,
    fetchUploadError,
    updateUpload,
    isUpdateUploadPending,
    updateUploadError,
  };
}
