import { GridRowId } from "@mui/x-data-grid-pro";

export * from "./sidebar";
export * from "./theme";
export * from "./emotion.d";

export enum Country {
  Afghanistan = "AF",
  AlandIslands = "AX",
  Albania = "AL",
  Algeria = "DZ",
  AmericanSamoa = "AS",
  Andorra = "AD",
  Angola = "AO",
  Anguilla = "AI",
  Antarctica = "AQ",
  AntiguaAndBarbuda = "AG",
  Argentina = "AR",
  Armenia = "AM",
  Aruba = "AW",
  Australia = "AU",
  Austria = "AT",
  Azerbaijan = "AZ",
  Bahamas = "BS",
  Bahrain = "BH",
  Bangladesh = "BD",
  Barbados = "BB",
  Belarus = "BY",
  Belgium = "BE",
  Belize = "BZ",
  Benin = "BJ",
  Bermuda = "BM",
  Bhutan = "BT",
  Bolivia = "BO",
  BonaireSintEustatiusSaba = "BQ",
  BosniaAndHerzegovina = "BA",
  Botswana = "BW",
  BouvetIsland = "BV",
  Brazil = "BR",
  BritishIndianOceanTerritory = "IO",
  BruneiDarussalam = "BN",
  Bulgaria = "BG",
  BurkinaFaso = "BF",
  Burundi = "BI",
  Cambodia = "KH",
  Cameroon = "CM",
  Canada = "CA",
  CapeVerde = "CV",
  CaymanIslands = "KY",
  CentralAfricanRepublic = "CF",
  Chad = "TD",
  Chile = "CL",
  China = "CN",
  ChristmasIsland = "CX",
  CocosKeelingIslands = "CC",
  Colombia = "CO",
  Comoros = "KM",
  Congo = "CG",
  CongoDemocraticRepublic = "CD",
  CookIslands = "CK",
  CostaRica = "CR",
  CoteDIvoire = "CI",
  Croatia = "HR",
  Cuba = "CU",
  Curaçao = "CW",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Djibouti = "DJ",
  Dominica = "DM",
  DominicanRepublic = "DO",
  Ecuador = "EC",
  Egypt = "EG",
  ElSalvador = "SV",
  EquatorialGuinea = "GQ",
  Eritrea = "ER",
  Estonia = "EE",
  Ethiopia = "ET",
  FalklandIslands = "FK",
  FaroeIslands = "FO",
  Fiji = "FJ",
  Finland = "FI",
  France = "FR",
  FrenchGuiana = "GF",
  FrenchPolynesia = "PF",
  FrenchSouthernTerritories = "TF",
  Gabon = "GA",
  Gambia = "GM",
  Georgia = "GE",
  Germany = "DE",
  Ghana = "GH",
  Gibraltar = "GI",
  Greece = "GR",
  Greenland = "GL",
  Grenada = "GD",
  Guadeloupe = "GP",
  Guam = "GU",
  Guatemala = "GT",
  Guernsey = "GG",
  Guinea = "GN",
  GuineaBissau = "GW",
  Guyana = "GY",
  Haiti = "HT",
  HeardIslandMcdonaldIslands = "HM",
  HolySeeVaticanCityState = "VA",
  Honduras = "HN",
  HongKong = "HK",
  Hungary = "HU",
  Iceland = "IS",
  India = "IN",
  Indonesia = "ID",
  Iran = "IR",
  Iraq = "IQ",
  Ireland = "IE",
  IsleOfMan = "IM",
  Israel = "IL",
  Italy = "IT",
  Jamaica = "JM",
  Japan = "JP",
  Jersey = "JE",
  Jordan = "JO",
  Kazakhstan = "KZ",
  Kenya = "KE",
  Kiribati = "KI",
  Korea = "KR",
  KoreaDemocraticPeoplesRepublic = "KP",
  Kuwait = "KW",
  Kyrgyzstan = "KG",
  LaoPeoplesDemocraticRepublic = "LA",
  Latvia = "LV",
  Lebanon = "LB",
  Lesotho = "LS",
  Liberia = "LR",
  LibyanArabJamahiriya = "LY",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Macao = "MO",
  Macedonia = "MK",
  Madagascar = "MG",
  Malawi = "MW",
  Malaysia = "MY",
  Maldives = "MV",
  Mali = "ML",
  Malta = "MT",
  MarshallIslands = "MH",
  Martinique = "MQ",
  Mauritania = "MR",
  Mauritius = "MU",
  Mayotte = "YT",
  Mexico = "MX",
  Micronesia = "FM",
  Moldova = "MD",
  Monaco = "MC",
  Mongolia = "MN",
  Montenegro = "ME",
  Montserrat = "MS",
  Morocco = "MA",
  Mozambique = "MZ",
  Myanmar = "MM",
  Namibia = "NA",
  Nauru = "NR",
  Nepal = "NP",
  Netherlands = "NL",
  NewCaledonia = "NC",
  NewZealand = "NZ",
  Nicaragua = "NI",
  Niger = "NE",
  Nigeria = "NG",
  Niue = "NU",
  NorfolkIsland = "NF",
  NorthernMarianaIslands = "MP",
  Norway = "NO",
  Oman = "OM",
  Pakistan = "PK",
  Palau = "PW",
  PalestinianTerritory = "PS",
  Panama = "PA",
  PapuaNewGuinea = "PG",
  Paraguay = "PY",
  Peru = "PE",
  Philippines = "PH",
  Pitcairn = "PN",
  Poland = "PL",
  Portugal = "PT",
  PuertoRico = "PR",
  Qatar = "QA",
  Reunion = "RE",
  Romania = "RO",
  RussianFederation = "RU",
  Rwanda = "RW",
  SaintBarthelemy = "BL",
  SaintHelena = "SH",
  SaintKittsAndNevis = "KN",
  SaintLucia = "LC",
  SaintMartin = "MF",
  SaintPierreAndMiquelon = "PM",
  SaintVincentAndGrenadines = "VC",
  Samoa = "WS",
  SanMarino = "SM",
  SaoTomeAndPrincipe = "ST",
  SaudiArabia = "SA",
  Senegal = "SN",
  Serbia = "RS",
  Seychelles = "SC",
  SierraLeone = "SL",
  Singapore = "SG",
  SintMaarten = "SX",
  Slovakia = "SK",
  Slovenia = "SI",
  SolomonIslands = "SB",
  Somalia = "SO",
  SouthAfrica = "ZA",
  SouthGeorgiaAndSandwichIsl = "GS",
  SouthSudan = "SS",
  Spain = "ES",
  SriLanka = "LK",
  Sudan = "SD",
  Suriname = "SR",
  SvalbardAndJanMayen = "SJ",
  Swaziland = "SZ",
  Sweden = "SE",
  Switzerland = "CH",
  SyrianArabRepublic = "SY",
  Taiwan = "TW",
  Tajikistan = "TJ",
  Tanzania = "TZ",
  Thailand = "TH",
  TimorLeste = "TL",
  Togo = "TG",
  Tokelau = "TK",
  Tonga = "TO",
  TrinidadAndTobago = "TT",
  Tunisia = "TN",
  Turkey = "TR",
  Turkmenistan = "TM",
  TurksAndCaicosIslands = "TC",
  Tuvalu = "TV",
  Uganda = "UG",
  Ukraine = "UA",
  UnitedArabEmirates = "AE",
  UnitedKingdom = "GB",
  UnitedStates = "US",
  UnitedStatesOutlyingIslands = "UM",
  Uruguay = "UY",
  Uzbekistan = "UZ",
  Vanuatu = "VU",
  Venezuela = "VE",
  Vietnam = "VN",
  VirginIslandsBritish = "VG",
  VirginIslandsUS = "VI",
  WallisAndFutuna = "WF",
  WesternSahara = "EH",
  Yemen = "YE",
  Zambia = "ZM",
  Zimbabwe = "ZW",
}

export enum City {
  Adana = "Adana",
  Adiyaman = "Adiyaman",
  Afyonkarahisar = "Afyonkarahisar",
  Agri = "Agri",
  Amasya = "Amasya",
  Ankara = "Ankara",
  Antalya = "Antalya",
  Artvin = "Artvin",
  Aydin = "Aydin",
  Balikesir = "Balikesir",
  Bilecik = "Bilecik",
  Bingol = "Bingol",
  Bitlis = "Bitlis",
  Bolu = "Bolu",
  Burdur = "Burdur",
  Bursa = "Bursa",
  Canakkale = "Canakkale",
  Cankiri = "Cankiri",
  Corum = "Corum",
  Denizli = "Denizli",
  Diyarbakir = "Diyarbakir",
  Edirne = "Edirne",
  Elazig = "Elazig",
  Erzincan = "Erzincan",
  Erzurum = "Erzurum",
  Eskisehir = "Eskisehir",
  Gaziantep = "Gaziantep",
  Giresun = "Giresun",
  Gumushane = "Gumushane",
  Hakkari = "Hakkari",
  Hatay = "Hatay",
  Isparta = "Isparta",
  Mersin = "Mersin",
  Istanbul = "Istanbul",
  Izmir = "Izmir",
  Kars = "Kars",
  Kastamonu = "Kastamonu",
  Kayseri = "Kayseri",
  Kirklareli = "Kirklareli",
  Kirsehir = "Kirsehir",
  Kocaeli = "Kocaeli",
  Konya = "Konya",
  Kutahya = "Kutahya",
  Malatya = "Malatya",
  Manisa = "Manisa",
  Kahramanmaras = "Kahramanmaras",
  Mardin = "Mardin",
  Mugla = "Mugla",
  Mus = "Mus",
  Nevsehir = "Nevsehir",
  Nigde = "Nigde",
  Ordu = "Ordu",
  Rize = "Rize",
  Sakarya = "Sakarya",
  Samsun = "Samsun",
  Siirt = "Siirt",
  Sinop = "Sinop",
  Sivas = "Sivas",
  Tekirdag = "Tekirdag",
  Tokat = "Tokat",
  Trabzon = "Trabzon",
  Tunceli = "Tunceli",
  Sanliurfa = "Sanliurfa",
  Usak = "Usak",
  Van = "Van",
  Yozgat = "Yozgat",
  Zonguldak = "Zonguldak",
  Aksaray = "Aksaray",
  Bayburt = "Bayburt",
  Karaman = "Karaman",
  Kirikkale = "Kirikkale",
  Batman = "Batman",
  Sirnak = "Sirnak",
  Bartin = "Bartin",
  Ardahan = "Ardahan",
  Igdir = "Igdir",
  Yalova = "Yalova",
  Karabuk = "Karabuk",
  Kilis = "Kilis",
  Osmaniye = "Osmaniye",
  Duzce = "Duzce",
}

export enum HospitalType {
  Private = "Private",
  Public = "Public",
  Nonprofit = "Nonprofit",
  University = "University",
  Research = "Research",
}

export enum DepartmentClass {
  Income = "Income",
  Expense = "Expense",
  AuxiliaryIncome = "AuxiliaryIncome",
  AuxiliaryExpense = "AuxiliaryExpense",
  Other = "Other",
}

export enum MedicalSpecialty {
  AEROSPACE_MEDICINE = "AEROSPACE_MEDICINE",
  ALGOLOGY_ANESTHESIOLOGY_AND_REANIMATION = "ALGOLOGY_ANESTHESIOLOGY_AND_REANIMATION",
  ALGOLOGY_NEUROLOGY = "ALGOLOGY_NEUROLOGY",
  ALGOLOGY_PHYSICAL_THERAPY_AND_REHABILITATION = "ALGOLOGY_PHYSICAL_THERAPY_AND_REHABILITATION",
  ANESTHESIOLOGY_AND_REANIMATION = "ANESTHESIOLOGY_AND_REANIMATION",
  AVIATION_MEDICAL_CENTER = "AVIATION_MEDICAL_CENTER",
  BRAIN_AND_NERVE_SURGERY = "BRAIN_AND_NERVE_SURGERY",
  CARDIAC_SURGERY = "CARDIAC_SURGERY",
  CARDIOLOGY = "CARDIOLOGY",
  CHEST_DISEASES = "CHEST_DISEASES",
  CHILD_ALLERGY = "CHILD_ALLERGY",
  CHILD_AND_ADOLESCENT_MENTAL_HEALTH_AND_DISEASES = "CHILD_AND_ADOLESCENT_MENTAL_HEALTH_AND_DISEASES",
  CHILD_EMERGENCY = "CHILD_EMERGENCY",
  CHILD_HEALTH_AND_DISEASES = "CHILD_HEALTH_AND_DISEASES",
  CHILD_IMMUNOLOGY = "CHILD_IMMUNOLOGY",
  CHILD_NEUROLOGY = "CHILD_NEUROLOGY",
  DENTAL_DISEASES_AND_TREATMENT = "DENTAL_DISEASES_AND_TREATMENT",
  DEVELOPMENTAL_PEDIATRICS = "DEVELOPMENTAL_PEDIATRICS",
  EAR_NOSE_THROAT_DISEASES = "EAR_NOSE_THROAT_DISEASES",
  EMERGENCY_MEDICINE = "EMERGENCY_MEDICINE",
  ENDOCRINOLOGY_AND_METABOLIC_DISEASES = "ENDOCRINOLOGY_AND_METABOLIC_DISEASES",
  ENDODONTICS = "ENDODONTICS",
  EYE_DISEASES = "EYE_DISEASES",
  FAMILY_MEDICINE = "FAMILY_MEDICINE",
  GASTROENTEROLOGY = "GASTROENTEROLOGY",
  GASTROENTEROLOGY_SURGERY = "GASTROENTEROLOGY_SURGERY",
  GENERAL_SURGERY = "GENERAL_SURGERY",
  GERIATRICS = "GERIATRICS",
  GYNECOLOGICAL_ONCOLOGY_SURGERY = "GYNECOLOGICAL_ONCOLOGY_SURGERY",
  GYNECOLOGY_AND_OBSTETRICS = "GYNECOLOGY_AND_OBSTETRICS",
  HAND_SURGERY_GENERAL_SURGERY = "HAND_SURGERY_GENERAL_SURGERY",
  HAND_SURGERY_ORTHOPEDICS_AND_TRAUMATOLOGY = "HAND_SURGERY_ORTHOPEDICS_AND_TRAUMATOLOGY",
  HAND_SURGERY_PLASTIC_RECONSTRUCTIVE_AND_AESTHETIC_SURGERY = "HAND_SURGERY_PLASTIC_RECONSTRUCTIVE_AND_AESTHETIC_SURGERY",
  HEMATOLOGY = "HEMATOLOGY",
  IMMUNOLOGY_AND_ALLERGY_DISEASES_CHEST_DISEASES = "IMMUNOLOGY_AND_ALLERGY_DISEASES_CHEST_DISEASES",
  IMMUNOLOGY_AND_ALLERGY_DISEASES_INTERNAL_MEDICINE = "IMMUNOLOGY_AND_ALLERGY_DISEASES_INTERNAL_MEDICINE",
  IMMUNOLOGY_AND_ALLERGY_DISEASES_SKIN_AND_VENEREAL_DISEASES = "IMMUNOLOGY_AND_ALLERGY_DISEASES_SKIN_AND_VENEREAL_DISEASES",
  INFECTIOUS_DISEASES_AND_CLINICAL_MICROBIOLOGY = "INFECTIOUS_DISEASES_AND_CLINICAL_MICROBIOLOGY",
  INTERNAL_DISEASES = "INTERNAL_DISEASES",
  MEDICAL_ECOLOGY_AND_HYDROCLIMATOLOGY = "MEDICAL_ECOLOGY_AND_HYDROCLIMATOLOGY",
  MEDICAL_GENETICS = "MEDICAL_GENETICS",
  MEDICAL_ONCOLOGY = "MEDICAL_ONCOLOGY",
  MENTAL_HEALTH_AND_DISEASES = "MENTAL_HEALTH_AND_DISEASES",
  NEONATOLOGISTS = "NEONATOLOGISTS",
  NEPHROLOGY = "NEPHROLOGY",
  NEUROLOGY = "NEUROLOGY",
  NONE = "NONE",
  NUCLEAR_MEDICINE = "NUCLEAR_MEDICINE",
  OCCUPATIONAL_AND_OCCUPATIONAL_DISEASES = "OCCUPATIONAL_AND_OCCUPATIONAL_DISEASES",
  ORAL_DENTAL_AND_MAXILLOFACIAL_RADIOLOGY = "ORAL_DENTAL_AND_MAXILLOFACIAL_RADIOLOGY",
  ORAL_DENTAL_AND_MAXILLOFACIAL_SURGERY = "ORAL_DENTAL_AND_MAXILLOFACIAL_SURGERY",
  ORAL_FACIAL_AND_MAXILLOFACIAL_SURGERY = "ORAL_FACIAL_AND_MAXILLOFACIAL_SURGERY",
  ORTHODONTICS = "ORTHODONTICS",
  ORTHOPEDICS_AND_TRAUMATOLOGY = "ORTHOPEDICS_AND_TRAUMATOLOGY",
  PEDIATRIC_CARDIOLOGY = "PEDIATRIC_CARDIOLOGY",
  PEDIATRIC_CARDIOVASCULAR_SURGERY = "PEDIATRIC_CARDIOVASCULAR_SURGERY",
  PEDIATRIC_CHEST_DISEASES = "PEDIATRIC_CHEST_DISEASES",
  PEDIATRIC_DENTISTRY = "PEDIATRIC_DENTISTRY",
  PEDIATRIC_ENDOCRINOLOGY = "PEDIATRIC_ENDOCRINOLOGY",
  PEDIATRIC_ENDOCRINOLOGY_AND_METABOLIC_DISEASES = "PEDIATRIC_ENDOCRINOLOGY_AND_METABOLIC_DISEASES",
  PEDIATRIC_GASTROENTEROLOGY = "PEDIATRIC_GASTROENTEROLOGY",
  PEDIATRIC_GENETIC_DISEASES = "PEDIATRIC_GENETIC_DISEASES",
  PEDIATRIC_HEMATOLOGY = "PEDIATRIC_HEMATOLOGY",
  PEDIATRIC_HEMATOLOGY_AND_ONCOLOGY = "PEDIATRIC_HEMATOLOGY_AND_ONCOLOGY",
  PEDIATRIC_IMMUNOLOGY_AND_ALLERGY_DISEASES = "PEDIATRIC_IMMUNOLOGY_AND_ALLERGY_DISEASES",
  PEDIATRIC_INFECTIOUS_DISEASES = "PEDIATRIC_INFECTIOUS_DISEASES",
  PEDIATRIC_METABOLIC_DISEASES = "PEDIATRIC_METABOLIC_DISEASES",
  PEDIATRIC_NEPHROLOGY = "PEDIATRIC_NEPHROLOGY",
  PEDIATRIC_ONCOLOGY = "PEDIATRIC_ONCOLOGY",
  PEDIATRIC_RHEUMATOLOGY = "PEDIATRIC_RHEUMATOLOGY",
  PEDIATRIC_SURGERY = "PEDIATRIC_SURGERY",
  PEDIATRIC_UROLOGY = "PEDIATRIC_UROLOGY",
  PERINATOLOGY = "PERINATOLOGY",
  PERIODONTOLOGY = "PERIODONTOLOGY",
  PHYSICAL_MEDICINE_AND_REHABILITATION = "PHYSICAL_MEDICINE_AND_REHABILITATION",
  PLASTIC_RECONSTRUCTIVE_AND_AESTHETIC_SURGERY = "PLASTIC_RECONSTRUCTIVE_AND_AESTHETIC_SURGERY",
  PROSTHETIC_DENTISTRY = "PROSTHETIC_DENTISTRY",
  RADIATION_ONCOLOGY = "RADIATION_ONCOLOGY",
  RADIOLOGY = "RADIOLOGY",
  RESTORATIVE_DENTISTRY = "RESTORATIVE_DENTISTRY",
  RHEUMATOLOGY_INTERNAL_MEDICINE = "RHEUMATOLOGY_INTERNAL_MEDICINE",
  RHEUMATOLOGY_PHYSICAL_MEDICINE_AND_REHABILITATION = "RHEUMATOLOGY_PHYSICAL_MEDICINE_AND_REHABILITATION",
  SKIN_AND_VENEREAL_DISEASES = "SKIN_AND_VENEREAL_DISEASES",
  SPORTS_MEDICINE = "SPORTS_MEDICINE",
  SURGICAL_ONCOLOGY = "SURGICAL_ONCOLOGY",
  THORACIC_SURGERY = "THORACIC_SURGERY",
  UNDERWATER_MEDICINE_AND_HYPERBARIC_MEDICINE = "UNDERWATER_MEDICINE_AND_HYPERBARIC_MEDICINE",
  UROLOGY = "UROLOGY",
}

export enum MedicalTitle {
  Expert = "Expert",
  AssociateProfessor = "AssociateProfessor",
  Professor = "Professor",
  Practitioner = "Practitioner",
  AssistantProfessor = "AssistantProfessor",
  Dentist = "Dentist",
  InstructorDoctor = "InstructorDoctor",
  Nutritionist = "Nutritionist",
  Esthetician = "Esthetician",
  Physiotherapist = "Physiotherapist",
}

export enum ServiceCategory {}

export enum ManagementSoftware {
  Pusula = "Pusula",
  Nucleus = "Nucleus",
  Probel = "Probel",
  Other = "Other",
}

export enum AccountingSoftware {
  Logo = "Logo",
  Oracle = "Oracle",
  Other = "Other",
}

export enum KeySourceType {
  Manual = "Manual",
  Automatic = "Automatic",
}

export enum KeySource {
  Payroll = "Payroll",
  ManagementSoftware = "ManagementSoftware",
  ExpenseAnalysis = "ExpenseAnalysis",
  AccountingSoftware = "AccountingSoftware",
  Template = "Template",
}

export enum KeyUsageArea {
  Indirect = "Indirect",
  IndirectAndBilling = "IndirectAndBilling",
}

export type Account = {
  Id: string;
  OwnerId: string;
  AccountingData: boolean;
  MedicalData: boolean;
  Billing: {
    [key: string]: { Due: number; Paid: number };
  };
};
export type Hospital = {
  Id: string;
  AccountId: string;
  Name: string;
  Branch?: string;
  Country?: Country;
  City: City;
  Type: HospitalType;
  ManagementSoftware: ManagementSoftware;
  AccountingSoftware: AccountingSoftware;
};

export type User = {
  Id: string;
  AccountId?: string;
  FirstName?: string;
  LastName?: string;
  Email: string;
  Phone?: string;
  Cognito?: any;
  Access: {
    Level: AccessLevel;
    Resources: {
      [key: string]: {
        Read: boolean;
        Write: boolean;
      };
    };
  };
};

export type Procedure = {
  Id: string;
  Name: string;
  Type: string;
  SubType: string;
  PublicCode?: string;
  PublicName?: string;
  PublicCategoryCode?: string;
  PublicCategoryName?: string;
  SUTCode?: string;
  SUTName?: string;
  SUTCategoryCode?: string;
  SUTCategoryName?: string;
  HUVCode?: string;
  HUVName?: string;
  HUVCategoryCode?: string;
  HUVCategoryName?: string;
  TDBCode?: string;
  TDBName?: string;
  TDBCategoryCode?: string;
  TDBCategoryName?: string;
  Duration?: number;
};

export type Credentials = {
  Email: string;
  Password: string;
};

export enum PatientType {
  InPatient = "InPatient",
  OutPatient = "OutPatient",
  ShortStay = "ShortStay",
}
export enum MappingType {
  Transaction = "Transaction",
  Service = "Service",
  Material = "Material",
  Surgery = "Surgery",
  Earning = "Earning",
  Procedure = "Procedure",
  Department = "Department",
  Ledger = "Ledger",
}

export enum MappingClass {
  General = "General",
  Uploadable = "Uploadable",
}

export type Mapping = {
  Id: string;
  AccountId: string;
  HospitalId: string;
  Type: MappingType;
  Class?: MappingClass.Uploadable;
  Name: string;
  Map: Record<string, string>;
};

export type MaterialMapping = {
  Id: string;
  AccountId: string;
  HospitalId: string;
  Name: string;
  Type: MappingType.Material;
  Class: MappingClass.Uploadable;
  Map: {
    Name: string;
    Time: string;
    Date: string;
    ProtocolIdentifier: string;
    ProtocolDepartmentIdentifier: string;
    OperatingDepartmentIdentifier: string;
    ProtocolDoctorIdentifier: string;
    OperatingDoctorIdentifier: string;
    PatientIdentifier: string;
    PublicCoverage: string;
    ComplementaryCoverage: string;
    PrivateCoverage: string;
    SelfCoverage: string;
    InstitutionWithAgreement: string;
    DiscountGroup: string;
    PublicCoverageAmount: string;
    ComplementaryCoverageAmount: string;
    PrivateCoverageAmount: string;
    SelfCoverageAmount: string;
    InstitutionWithAgreementAmount: string;
    DiscountGroupAmount: string;
  };
};

export type Department = {
  Id: string;
  AccountId: string;
  HospitalId: string;
  Code: string;
  Name: string;
  Class: DepartmentClass;
  DistributionOrder: number;
  DistributionKeys: DistributionKey[];
  DistributionDefaults?: {
    [key: string]: {
      Setting: DistributionSetting;
      Value?: any;
    };
  };
};

export enum AccessLevel {
  Department,
  Hospital,
  Account,
  Advisor,
  Admin,
  Developer,
  God,
}

export enum AuthStatus {
  NOT_AUTHENTICATED,
  AUTHENTICATED_NOT_CONFIRMED,
  AUTHENTICATED_NEW_PASSWORD_REQUIRED,
  AUTHENTICATED,
}

export type DistributionKey = {
  Id: string;
  Name: string;
  Description: string;
  Source: KeySource;
  Type: KeySourceType;
  UsageArea: KeyUsageArea;
};

export enum DistributionSetting {
  Constant = "Constant",
  Average = "Average",
  Previous = "Previous",
}

export type TableData = {
  rows: any[];
  columns: any[];
};

export type Workbook = {
  [key: string]: {
    [key: string]: TableData;
  };
};

export type LedgerItem = {
  Id: string;
  LedgerCode: string;
  LedgerName: string;
  CategoryCode: string;
  CategoryName: string;
  SubCategoryCode: string;
  SubCategoryName: string;
};

export type Doctor = {
  Id: string;
  FirstName: string;
  LastName: string;
  DoctorLicenseId: string;
  Specialty: string;
};

export enum UploadStatus {
  Created = "Created",
  Uploading = "Uploading",
  Processing = "Processing",
  Completed = "Completed",
  GeneratingCSV = "GeneratingCSV",
  AllDone = "AllDone",
}

export type UploadError = {
  Id?: string;
  MappingId?: string;
  UploadId?: string;
  Row?: number;
  Column?: string;
  Message?: string;
};

export type Upload = {
  Id: string;
  MappingId: string;
  HospitalId: string;
  FileName: string;
  FileSize: number;
  FileType: string;
  YearMonth: string;
  Timestamp: string;
  UploadStatus: UploadStatus;
  ErrorCount: number;
  ErroredRowCount: number;
  RowsRead: number;
  RowsProcessed: number;
  RowsTotal: number;
  SignedURL: string;
};

export type CRUDField = {
  Type: "text" | "number" | "select" | "multiselect";
  Name: string;
  Label: string;
  Required: boolean;
  Editable: boolean;
  InitialValue: any;
  Options?: Array<{
    Value: any;
    Label: string;
    Icon?: string;
  }>;
};

export type CRUDEntity = {
  Type:
    | "account"
    | "user"
    | "hospital"
    | "department"
    | "mapping"
    | "doctor"
    | "procedure";
  Fields: CRUDField[];
};
