import React from "react";

import AppBar from "./AppBar";
function Presentation() {
  return (
    <React.Fragment>
      <AppBar />
    </React.Fragment>
  );
}

export default Presentation;
