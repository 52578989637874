import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
} from "react";
import axios from "../utils/axios";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { AuthStatus } from "../types";
import { cognitoConfig } from "../config";

export const UserPool = new CognitoUserPool(cognitoConfig);

export type CognitoContextType = {
  session: CognitoUserSession | null | undefined;
  setSession: Dispatch<SetStateAction<CognitoUserSession | null | undefined>>;
  getSession: () => Promise<unknown>;
  status: AuthStatus;
  setStatus: Dispatch<SetStateAction<AuthStatus>>;
  error: any;
  setError: Dispatch<SetStateAction<any>>;
  resetPasswordParams: any;
  setResetPasswordParams: Dispatch<SetStateAction<any>>;
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  signIn: (email: string, password: string) => Promise<any>;
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    access: string
  ) => Promise<any>;
  signOut: () => Promise<any>;
  confirmRegistration: (code: string, email: string) => Promise<any>;
  sendConfirmationCode: (email: string) => void;
  respondToAuthChallenge: (
    email: string,
    newPassword: string,
    requiredAttributes?: any
  ) => Promise<any>;
};

const initialState = {
  session: null,
  setSession: () => {},
  getSession: () => Promise.resolve(null),
  status: AuthStatus.NOT_AUTHENTICATED,
  setStatus: () => {},
  error: undefined,
  setError: () => {},
  resetPasswordParams: undefined,
  setResetPasswordParams: () => {},
  user: null,
  setUser: () => {},
  signIn: (email: string, password: string) => Promise.resolve(undefined),
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    access: string
  ) => Promise.resolve(),
  signOut: () => Promise.resolve(),
  confirmRegistration: (code: string, email: string) => Promise.resolve(),
  sendConfirmationCode: (email: string) => {},
  respondToAuthChallenge: (
    email: string,
    newPassword: string,
    requiredAttributes?: any
  ) => Promise.resolve(),
};
const AuthContext = createContext<CognitoContextType | null>(initialState);

function AuthProvider({ children }: { children: ReactNode }) {
  const [error, setError] = useState<any>();
  const [resetPasswordParams, setResetPasswordParams] = useState<any>(null);
  const [user, setUser] = useState<CognitoUser | undefined | null>(
    UserPool.getCurrentUser()
  );

  const [session, setSession] = useState<CognitoUserSession | undefined | null>(
    user?.getSignInUserSession()
  );

  const [status, setStatus] = useState<AuthStatus>(
    user && (!session || !session.isValid())
      ? AuthStatus.AUTHENTICATED
      : AuthStatus.NOT_AUTHENTICATED
  );

  const getSession = useCallback(async () => {
    try {
      return new Promise((resolve, reject) => {
        if (user) {
          user.getSession(
            (err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                setError(err);
                reject(err);
              } else {
                setSession(session);
                setStatus(AuthStatus.AUTHENTICATED);
                resolve(session);
              }
            }
          );
        } else {
          resolve(null);
        }
      });
    } catch {}
  }, [user, setSession, setStatus]);

  const sessionValid = session?.isValid();

  useEffect(() => {
    const fetchSession = async () => {
      try {
        await getSession();
      } catch (e) {
        console.error(e);
      }
    };

    if (user && (!session || !sessionValid)) {
      console.log("SESSION FOUND TO BE INVALID, FETCHING NEW CREDENTIALS");
      fetchSession();
    }
  }, [user, session, getSession, sessionValid]);

  const token = session?.getIdToken()?.getJwtToken() || "";

  axios.defaults.headers.common.Authorization = useMemo(() => token, [token]);

  const signIn = async (Username: string, Password: string) => {
    const newUser = new CognitoUser({ Username, Pool: UserPool });
    return new Promise((resolve, reject) => {
      newUser.authenticateUser(
        new AuthenticationDetails({ Username, Password }),
        {
          onSuccess(session, userConfirmationNecessary) {
            newUser.setSignInUserSession(session);
            setUser(newUser);
            setSession(session);
            setError(undefined);
            console.log("FOOO", JSON.stringify(session, null, 2));
            if (userConfirmationNecessary) {
              setStatus(AuthStatus.AUTHENTICATED_NOT_CONFIRMED);
            } else {
              setStatus(AuthStatus.AUTHENTICATED);
            }

            resolve(session);
          },
          onFailure(err) {
            if (err.code === "UserNotConfirmedException") {
              setStatus(AuthStatus.AUTHENTICATED_NOT_CONFIRMED);
              setUser(newUser);
              setError(undefined);
            } else {
              setError(err);
            }
            reject(err);
          },
          newPasswordRequired(userAttributes, requiredAttributes) {
            setError(undefined);

            setUser(newUser);
            setResetPasswordParams({ userAttributes, requiredAttributes });
            resolve({ userAttributes, requiredAttributes });
          },
        }
      );
    });
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    access: string
  ) => {
    return new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: "email", Value: email }),
          new CognitoUserAttribute({
            Name: "given_name",
            Value: firstName,
          }),
          new CognitoUserAttribute({
            Name: "family_name",
            Value: lastName,
          }),
          new CognitoUserAttribute({
            Name: "custom:access",
            Value: access,
          }),
        ],
        [],
        (err, result) => {
          if (err) {
            setError(err);
            reject(err);
          } else {
            const { user, userConfirmed } = result!!;
            setError(undefined);
            setUser(user);
            if (userConfirmed) {
              setStatus(AuthStatus.AUTHENTICATED);
            } else {
              setStatus(AuthStatus.AUTHENTICATED_NOT_CONFIRMED);
            }
            resolve(user);
          }
        }
      );
    });
  };

  const signOut = async () => {
    if (user) {
      return new Promise((resolve, reject) => {
        user.signOut(() => {
          resolve(undefined);
          setStatus(AuthStatus.NOT_AUTHENTICATED);
          setUser(undefined);
          setSession(undefined);
          localStorage.clear();
        });
      });
    } else {
      return Promise.reject();
    }
  };

  const confirmRegistration = async (code: string, email: string) => {
    if (!user) {
      setUser(
        new CognitoUser({
          Username: email,
          Pool: UserPool,
        })
      );
    }

    return new Promise((resolve, reject) => {
      user?.confirmRegistration(code, true, function (err, result) {
        if (err) {
          setError(err);
          return reject(err);
        }

        setSession(undefined);
        setError(undefined);
        setStatus(AuthStatus.NOT_AUTHENTICATED);
        return resolve(result);
      });
    });
  };

  const sendConfirmationCode = async (email: string) => {
    setUser(
      new CognitoUser({
        Username: email,
        Pool: UserPool,
      })
    );

    return new Promise((resolve, reject) => {
      user?.resendConfirmationCode(function (err, result) {
        if (err) {
          return reject(err);
        }

        return resolve(result);
      });
    });
  };

  const respondToAuthChallenge = async (
    email: string,
    newPassword: string,
    requiredAttributes?: any
  ) => {
    return new Promise((resolve, reject) => {
      user?.completeNewPasswordChallenge(newPassword, requiredAttributes, {
        onSuccess(session) {
          user?.setSignInUserSession(session);
          setError(undefined);
          setStatus(AuthStatus.AUTHENTICATED);
          resolve(session);
        },
        onFailure(err) {
          setError(err);
          reject(err);
        },
      });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signUp,
        signOut,
        session,
        setSession,
        getSession,
        error,
        setError,
        resetPasswordParams,
        setResetPasswordParams,
        status,
        setStatus,
        user,
        setUser,
        confirmRegistration,
        sendConfirmationCode,
        respondToAuthChallenge,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
