import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { AuthStatus } from "../../types";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { status } = useAuth();

  console.log("AUTH", status);
  if (status === AuthStatus.AUTHENTICATED_NOT_CONFIRMED) {
    return <Navigate to="/auth/confirm-registration" />;
  } else if (status === AuthStatus.AUTHENTICATED_NEW_PASSWORD_REQUIRED) {
    return <Navigate to="/auth/reset-password" />;
  } else if (status === AuthStatus.NOT_AUTHENTICATED) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
