import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  Paper,
  LinearProgress,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataGridPro, GridColDef, gridClasses } from "@mui/x-data-grid-pro";
import { Cancel } from "@mui/icons-material";
import { useBenefitDxContext } from "../../../../contexts/BenefitDxContext";
import {
  DepartmentClass,
  Department,
  DistributionKey,
} from "../../../../types";
import { useParams, Link } from "react-router-dom";
import { useTableLocaleText } from "../../../../hooks/useTableLocaleText";

export const splitPascalCase = (key: string = "") =>
  key
    .split(/(?=[A-Z])/g)
    .map((k, i) => {
      if (!i) {
        return k;
      }
      return k.toLowerCase();
    })
    .join(" ");

export function DistributionSettings() {
  const { t } = useTranslation();
  const { HospitalId } = useParams();
  const { setSelectedHospital } = useBenefitDxContext();
  const {
    distributionKeys,
    departments,
    hospital,
    hospitals,
    isListDepartmentsByHospitalPending,
    isUpdateDepartmentPending,
    updateDepartment,
  } = useBenefitDxContext();
  const localeText = useTableLocaleText();
  const [expenseCenters, setExpenseCenters] = useState<Department[]>([]);

  useEffect(() => {
    if (hospitals && !hospital) {
      setSelectedHospital(hospitals.find((hs: any) => hs.Id === HospitalId));
    }
  }, [hospital, hospitals, setSelectedHospital, HospitalId]);

  const columns: GridColDef[] = [
    {
      field: "Order",
      headerName: t("Order") as string,
      editable: true,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "Name",
      headerName: t("Name") as string,
      flex: 3,
      editable: true,
      renderCell: (params) => (
        <Link
          to={`/hospitals/${params.row.HospitalId}/departments/${params.row.Id}`}
        >
          {t(params.value)}
        </Link>
      ),
    },
    {
      field: "DistributionKeys",
      headerName: t("Distribution keys") as string,
      flex: 8,
      editable: true,
      renderCell: (params: any) => {
        return (
          <Select
            multiple
            value={params.row.DistributionKeys}
            fullWidth
            size="small"
            onChange={async (event) => {
              console.log("event.target", event.target);
              params.row.DistributionKeys = [...event.target.value];

              console.log("ROW", params.row);

              await updateDepartment(params.row);
            }}
            renderValue={(selected) => (
              <Stack gap={1} direction="row" flexWrap="wrap">
                {selected
                  .map((k: string) =>
                    distributionKeys.find((item) => item.Id === k),
                  )
                  .map((dkey: DistributionKey) => (
                    <Chip
                      variant="outlined"
                      key={dkey.Id}
                      label={t(dkey.Name)}
                      size="small"
                      deleteIcon={
                        <Cancel
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={(e) => {
                        e.stopPropagation();
                        params.row.DistributionKeys =
                          params.row.DistributionKeys.filter(
                            (id: string) => id !== dkey.Id,
                          );

                        setExpenseCenters([...expenseCenters]);
                      }}
                    />
                  ))}
              </Stack>
            )}
          >
            {distributionKeys.map((option) => (
              <MenuItem key={option.Id} value={option.Id}>
                <Checkbox
                  checked={Boolean(
                    params.row.DistributionKeys.find(
                      (id: string) => id === option.Id,
                    ),
                  )}
                />
                <ListItemText primary={t(option.Name)} />
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];

  useEffect(() => {
    setExpenseCenters(
      departments
        .filter(
          (department) =>
            department.Class === DepartmentClass.Expense ||
            department.Class === DepartmentClass.AuxiliaryExpense,
        )
        .map((department) => {
          department.DistributionKeys = department.DistributionKeys || [];
          return department;
        }),
    );
  }, [departments]);

  return (
    <Paper sx={{ width: "100%" }}>
      <Helmet title={t("Departments") as string} />
      <Grid container direction={"column"} width={"100%"}>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <DataGridPro
              loading={
                isListDepartmentsByHospitalPending || isUpdateDepartmentPending
              }
              disableMultipleRowSelection={true}
              slots={{ loadingOverlay: LinearProgress }}
              autoHeight
              getRowId={(row) => row.Id}
              density="compact"
              rowReordering
              onRowOrderChange={(params) => {
                console.log(params);
              }}
              rows={expenseCenters}
              columns={columns}
              sx={{
                width: "100%",
                [`& .${gridClasses.row}`]: { cursor: "pointer" },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: "none",
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
              }}
              hideFooter
              getDetailPanelHeight={() => "auto"}
              localeText={localeText}
            />
          </CardContent>
        </Card>
      </Grid>
    </Paper>
  );
}

export default DistributionSettings;
