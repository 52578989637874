import React from "react";
import styled from "@emotion/styled";

import {
  Badge,
  Unstable_Grid2 as Grid,
  Avatar,
  Typography,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { user, session } = useAuth();

  const displayName = `${session?.getIdToken().payload?.given_name} ${
    session?.getIdToken().payload?.family_name
  }`;

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {!!user && <Avatar alt={""} src={""} />}
          </FooterBadge>
        </Grid>
        <Grid>
          {!!user && <FooterText variant="body2">{displayName}</FooterText>}
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
