import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as Logo } from "../vendor/logo.svg";
import { NavLink } from "react-router-dom";

import { Box, ListItemButton } from "@mui/material";

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.background};
  background-color: ${(props) => props.theme.sidebar.header.color}
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
  transform: rotate(90deg);
`;

export default () => (
  <Brand component={NavLink as any} to="/">
    <BrandIcon />
    <Box sx={{ marginLeft: -3 }}>enefitDx</Box>
  </Brand>
);
