import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import {
  Security,
  Upload as UploadIcon,
  Add,
  Download,
} from "@mui/icons-material";
import {
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  LinearProgress,
  InputLabel,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  gridClasses,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid-pro";
import LoadingButton from "@mui/lab/LoadingButton";
import { Delete } from "@mui/icons-material";
import axios from "axios";

import {
  Upload as UploadType,
  UploadStatus,
  UploadError,
} from "../../../types";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";
import { useTableLocaleText } from "../../../hooks/useTableLocaleText";
import { useUpload } from "../../../hooks/useUpload";

function UploadErrorDetails(uploadError: UploadError) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      width={"100%"}
      justifyContent={"center"}
      sx={{ overflow: "hidden" }}
    >
      <Helmet title={t("Upload details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingY={2.5}
        spacing={5}
        wrap="wrap"
        width={"100%"}
      >
        <Grid width={"100%"}>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              title={t("Upload Details")}
              avatar={<UploadIcon />}
            ></CardHeader>
            <CardContent>
              {Object.entries((uploadError as any).Data).map(([lhs, rhs]) => {
                return (
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="space-between"
                    key={lhs}
                    sx={{ paddingY: 1, borderBottom: "1px solid #eee" }}
                  >
                    <Grid xs={4}>{lhs}</Grid>
                    <Grid xs={8}>{rhs as any}</Grid>
                  </Grid>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function Upload() {
  const { MappingId, UploadId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const localeText = useTableLocaleText();
  const [file, setFile] = useState<File | null>(null);
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState<string>("");

  const { upload, isFetchUploadPending } = useUpload({ UploadId, MappingId });

  const {
    setSelectedUpload,
    uploadErrors,
    deleteUploadError,
    isCreateUploadErrorPending,
    isUpdateUploadErrorPending,
    isDeleteUploadErrorPending,
    isListUploadErrorsByUploadPending,
    generateErrorsDownload,
    downloadErrorsFile,
    downloadErrorsFileData,
  } = useBenefitDxContext();

  useEffect(() => {
    if (upload) {
      setSelectedUpload(upload);
    }
  }, [upload, setSelectedUpload]);

  const columns: GridColDef[] = [
    {
      field: "Row",
      headerName: t("Row") as string,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return params.value + 2;
      },
    },
    {
      field: "Column",
      headerName: t("Column") as string,
      flex: 1,
      editable: false,
    },
    {
      field: "Message",
      headerName: t("Error message") as string,
      flex: 1,
      editable: false,
    },
  ];

  const handleGenerateErrorDownloadClick = () => async () => {
    await generateErrorsDownload(UploadId);
  };

  const handleErrorDownloadClick = useCallback(async () => {
    await downloadErrorsFile(UploadId);
  }, [downloadErrorsFile, UploadId]);

  const handleDeleteClick = (id: GridRowId) => async () => {
    await deleteUploadError(id);
  };

  useEffect(() => {
    console.log("FOOOOOOOOOOOO", upload);

    if (
      upload?.UploadStatus === UploadStatus.AllDone &&
      !downloadErrorsFileData
    ) {
      handleErrorDownloadClick();
    }
  }, [upload, handleErrorDownloadClick, downloadErrorsFileData]);

  function EditToolbar() {
    console.log("FUCCCCCCCCCKKKKKKKKKK", upload);
    console.log("FUCCCCCCCCCKKKKKKKKKK", downloadErrorsFileData);

    return (
      <GridToolbarContainer>
        {upload?.UploadStatus === UploadStatus.Completed && (
          <Button
            color="primary"
            startIcon={<Download />}
            size="large"
            onClick={async () => {}}
          >
            {t("Generate CSV file")}
          </Button>
        )}
        {upload?.UploadStatus === UploadStatus.GeneratingCSV && (
          <Button
            color="primary"
            disabled
            startIcon={<Download />}
            size="large"
            onClick={async () => {}}
          >
            {t("Generating CSV file...")}
          </Button>
        )}
        {Boolean(
          upload?.UploadStatus === UploadStatus.AllDone &&
            downloadErrorsFileData?.DownloadURL?.length,
        ) && (
          <Button
            target="_blank"
            rel="noopener noreferrer"
            href={downloadErrorsFileData?.DownloadURL}
            download
          >
            {t("Download as CSV")}
          </Button>
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <React.Fragment>
      <Helmet title={t("Mapping details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingTop={5}
        spacing={5}
        wrap="wrap"
      >
        <Grid width={"100%"}>
          <Card>
            <CardHeader
              title={t("Errors from {{uploadName}}", {
                uploadName: upload?.FileName,
              })}
              avatar={<UploadIcon />}
            ></CardHeader>
            <CardContent>
              <DataGridPro
                loading={
                  isFetchUploadPending ||
                  isListUploadErrorsByUploadPending ||
                  isCreateUploadErrorPending ||
                  isDeleteUploadErrorPending ||
                  isUpdateUploadErrorPending
                }
                disableMultipleRowSelection={true}
                slots={{ loadingOverlay: LinearProgress, toolbar: EditToolbar }}
                autoHeight
                getRowId={(row) => {
                  return row.Id;
                }}
                rows={uploadErrors || []}
                columns={[
                  ...columns,
                  {
                    field: "actions",
                    type: "actions",
                    width: 100,
                    cellClassName: "actions",
                    getActions: ({ row, id }) => {
                      return [
                        <GridActionsCellItem
                          icon={<Delete />}
                          label="Delete"
                          onClick={handleDeleteClick(id)}
                          color="inherit"
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  width: "100%",
                  [`& .${gridClasses.row}`]: { cursor: "pointer" },
                  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                    {
                      outline: "none",
                    },
                  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                    {
                      outline: "none",
                    },
                }}
                hideFooter
                getDetailPanelContent={({ row }) => (
                  <UploadErrorDetails {...row} />
                )}
                getDetailPanelHeight={() => "auto"}
                detailPanelExpandedRowIds={selected}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  if (selected[0] === newRowSelectionModel[0]) {
                    setSelected([]);
                  } else {
                    setSelected(newRowSelectionModel as string[]);
                  }
                }}
                localeText={localeText}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Upload;
