import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function ConfirmRegistration() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { confirmRegistration, sendConfirmationCode } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        verificationCode: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Must be a valid email") as string)
          .max(255)
          .required(t("Email is required") as string),
        verificationCode: Yup.string()
          .max(6)
          .required(t("Verification code is required") as string),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await confirmRegistration(values.verificationCode, values.email);
          navigate("/account");
        } catch (error: any) {
          const message = error.message || t("Something went wrong");

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label={t("Email address")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="text"
            name="verificationCode"
            label={t("Verification code")}
            value={values.verificationCode}
            error={Boolean(touched.verificationCode && errors.verificationCode)}
            fullWidth
            helperText={touched.verificationCode && errors.verificationCode}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t("Confirm registration")}
          </Button>
          <Button
            sx={{ my: 2 }}
            fullWidth
            color="primary"
            onClick={() => {
              sendConfirmationCode(values.email);
            }}
          >
            {t("Resend verification code")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ConfirmRegistration;
