import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Unstable_Grid2 as Grid,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Papa from "papaparse";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Mapping, MappingType, MappingClass, LedgerItem } from "../../types";
import departmentTypes from "../../config/department-types";
import ledgerItems from "../../config/ledger";

const BDXLoadingButton = styled(LoadingButton)`
  label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

interface Props {
  onSubmit: (mapping: Mapping) => void;
}

// Styled components from DataMapper for file input
const FileInputLabel = styled.label`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const FileInput = styled.input`
  display: none;
`;

function separatePascalCase(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
}

const getFields = (mappingType: MappingType) => {
  switch (mappingType) {
    case MappingType.Procedure:
      return [
        "Name",
        "Type",
        "Sub Type",
        "HMS Code",
        "Public Code",
        "Public Name",
        "Public Category Code",
        "Public Category Name",
        "SUT Code",
        "SUT Name",
        "SUT Category Code",
        "SUT Category Name",
        "HUV Code",
        "HUV Name",
        "HUV Category Code",
        "HUV Category Name",
        "TDB Code",
        "TDB Name",
        "TDB Category Code",
        "TDB Category Name",
        "Duration",
      ];
    case MappingType.Service:
      return [
        "Public Code",
        "SUT Code",
        "HUV Code",
        "TDB Code",
        "Protocol Department Identifier",
        "Operator Department Identifier",
        "Protocol Doctor Identifier",
        "Operator Doctor Identifier",
        "Patient Identifier",
        "Protocol Identifier",
        "Name",
        "Date",
        "Time",
        "Patient Type",
        "Patient Citizenship",
        "Public Coverage",
        "Complementary Coverage",
        "Private Coverage",
        "Self Coverage",
        "Institution With Agreement",
        "Discount Group",
        "Public Coverage Amount",
        "Complementary Coverage Amount",
        "Private Coverage Amount",
        "Self Coverage Amount",
        "Institution With Agreement Amount",
        "Discount Group Amount",
      ].map(separatePascalCase);
    case MappingType.Material:
      break;
    case MappingType.Transaction:
      return [
        "Ledger Code",
        "Department Identifier",
        "Line Explanation",
        "Amount",
        "Date",
      ];
    case MappingType.Department:
      return departmentTypes.map((type) => type.Name);
    case MappingType.Earning:
      break;
    case MappingType.Ledger:
      return Object.values(ledgerItems).map((item) => `${item.LedgerCode}`);
  }

  return [];
};

export const SimpleMapper: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [name, setName] = useState("");
  const [mappingType, setMappingType] = useState<MappingType>(
    MappingType.Transaction,
  );
  const [mappingClass, setMappingClass] = useState<MappingClass>(
    MappingClass.Uploadable,
  );

  const [selectedMappings, setSelectedMappings] = useState<
    Record<string, string>
  >({});

  // Handle CSV upload and extract headers
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const headers = result.data[0] as string[]; // First row is assumed to be headers
          setCsvHeaders(headers);
          setSelectedMappings({});
        },
        skipEmptyLines: true,
      });
    }
  };

  // Handle header selection change
  const handleSelectChange = (
    standardHeader: string,
    selectedHeader: string,
  ) => {
    setSelectedMappings((prev) => ({
      ...prev,
      [standardHeader]: selectedHeader,
    }));
  };

  // Get available headers (those not selected in other dropdowns)
  const getAvailableHeaders = (currentHeader: string): string[] => {
    const usedHeaders = Object.values(selectedMappings).filter(
      (h) => h !== currentHeader,
    );
    return csvHeaders.filter((h) => !usedHeaders.includes(h));
  };

  // Clear selections
  const clearSelections = () => {
    setSelectedMappings({});
    setCsvHeaders([]);
    setName("");
    setMappingType(MappingType.Transaction);
    setMappingClass(MappingClass.Uploadable);
  };
  const createMapping = () => {
    const mapping = {
      Name: name,
      Type: mappingType,
      Class: mappingClass,
      Map: selectedMappings,
    };

    console.log("mapping", mapping);
    onSubmit(mapping as any);
  };

  useEffect(() => {
    switch (mappingType) {
      case MappingType.Procedure:
        setMappingClass(MappingClass.Uploadable);
        break;
      case MappingType.Service:
        setMappingClass(MappingClass.Uploadable);
        break;
      case MappingType.Transaction:
        setMappingClass(MappingClass.Uploadable);
        break;
      case MappingType.Material:
        setMappingClass(MappingClass.Uploadable);
        break;
      case MappingType.Earning:
        setMappingClass(MappingClass.Uploadable);
        break;
      case MappingType.Department:
        setMappingClass(MappingClass.General);
        break;
      case MappingType.Ledger:
        setMappingClass(MappingClass.General);
        break;
    }
  }, [mappingType]);

  return (
    <Grid container width="100%" direction="column">
      <Grid container justifyContent={"space-between"} width="100%" spacing={4}>
        <Grid flex={1}>
          <TextField
            type={"text"}
            name={"Name"}
            label={t("Name")}
            value={name}
            fullWidth
            size="small"
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid flex={1}>
          <FormControl fullWidth>
            <InputLabel>{t("Mapping type")}</InputLabel>
            <Select
              fullWidth
              size="small"
              value={mappingType}
              onChange={(e) => {
                setMappingType(e.target.value as MappingType);
              }}
            >
              {Object.values(MappingType).map((mtype, i) => (
                <MenuItem key={mtype} value={mtype}>
                  {t(separatePascalCase(mtype))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid flex={1}>
          <form>
            <BDXLoadingButton variant="contained" fullWidth loading={false}>
              <FileInputLabel htmlFor="file-upload-input">
                {t("Upload")}
                <FileInput
                  name="file-upload-input"
                  id="file-upload-input"
                  type="file"
                  accept="text/csv"
                  onChange={handleFileUpload}
                />
              </FileInputLabel>
            </BDXLoadingButton>
          </form>
        </Grid>
        <Grid flex={1}>
          <Button variant="outlined" onClick={clearSelections} sx={{ mr: 2 }}>
            {t("Clear")}
          </Button>
        </Grid>
        <Grid
          flex={1}
          container
          justifyContent={"flex-end"}
          sx={{ paddingRight: 0 }}
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={createMapping}
            disabled={!Object.keys(selectedMappings).length}
          >
            {t("Create")}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction={"row"} width="100%">
        <Grid flex={1}>
          <List>
            {getFields(mappingType).map((standardHeader) => (
              <ListItem key={standardHeader}>
                {mappingClass === MappingClass.Uploadable ? (
                  <FormControl fullWidth>
                    <InputLabel>{t(standardHeader)}</InputLabel>
                    <Select
                      value={selectedMappings[standardHeader] || ""}
                      size="small"
                      onChange={(e) =>
                        handleSelectChange(standardHeader, e.target.value)
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">
                        <em></em>
                      </MenuItem>

                      {getAvailableHeaders(
                        selectedMappings[standardHeader],
                      ).map((header) => (
                        <MenuItem key={header} value={header}>
                          {header}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    type={"text"}
                    name={"Name"}
                    label={t(standardHeader)}
                    value={selectedMappings[standardHeader] || ""}
                    fullWidth
                    size="small"
                    onChange={(e) =>
                      handleSelectChange(standardHeader, e.target.value)
                    }
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};
