import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  CardHeader,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useBenefitDxContext } from "../../../../contexts/BenefitDxContext";
import { DepartmentSelector } from "../../../../components/DepartmentSelector";

export function DepartmentSelections() {
  const { HospitalId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    departments,
    hospitals,
    setSelectedHospital,
    selectDepartmentsForHospital,
  } = useBenefitDxContext();

  useEffect(() => {
    setSelectedHospital(hospitals.find((hs) => hs.Id === HospitalId));
  }, [HospitalId, setSelectedHospital, hospitals]);

  return (
    <React.Fragment>
      <Helmet title={t("Department selections") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingTop={5}
        spacing={5}
        wrap="wrap"
      >
        <Grid width={"100%"}>
          <Paper sx={{ width: "100%" }}>
            <DepartmentSelector
              exists={departments}
              onSubmit={async (selections) => {
                console.log("selections", selections);
                const payload = Object.keys(selections).reduce(
                  (acc: any, key: any) => {
                    if (!selections[key]) {
                      return acc;
                    }

                    return [...acc, key];
                  },
                  [],
                );

                await selectDepartmentsForHospital(payload);
                navigate("/hospitals/" + HospitalId + "/departments");
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DepartmentSelections;
