import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

interface GuestGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const navigate = useNavigate();
  const { session } = useAuth();

  useEffect(() => {
    if (session && session.isValid()) {
      navigate("/account");
    }
  }, [session, navigate]);
  return <React.Fragment>{children}</React.Fragment>;
}

export default GuestGuard;
