import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signIn, getSession } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Must be a valid email") as string)
          .max(255)
          .required(t("Email is required") as string),
        password: Yup.string()
          .max(255)
          .required(t("Password is required") as string),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const result = await signIn(values.email, values.password);
          await getSession();
          if (result.userAttributes) {
            navigate("/auth/reset-password");
          } else {
            navigate("/account");
          }
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);

          if (error.code === "UserNotConfirmedException") {
            navigate("/auth/confirm-registration");
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            inputProps={{
              "data-testid": "email-field",
            }}
            type="email"
            name="email"
            label={t("Email address")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            inputProps={{
              "data-testid": "password-field",
            }}
            type="password"
            name="password"
            label={t("Password")}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            role="button"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ marginY: 3 }}
          >
            {t("Sign in")}
          </Button>
          <Button
            sx={{ marginY: 1 }}
            component={Link}
            to="/auth/sign-up"
            fullWidth
            color="primary"
          >
            {t("Sign up")}
          </Button>
          <Button
            sx={{ marginY: 1 }}
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            {t("Forgot password")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
