import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  CardHeader,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  gridClasses,
} from "@mui/x-data-grid-pro";

import { useBenefitDxContext } from "../../../../contexts/BenefitDxContext";
import { Add, BarChart } from "@mui/icons-material";
import { useEffect, useState } from "react";
import BenefitDxForm, { FormProps } from "../../../../components/Form";
import { DepartmentClass, DistributionSetting } from "../../../../types";
import { Link } from "react-router-dom";
import { useTableLocaleText } from "../../../../hooks/useTableLocaleText";

export const splitPascalCase = (key: string = "") =>
  key
    .split(/(?=[A-Z])/g)
    .map((k, i) => {
      if (!i) {
        return k;
      }
      return k.toLowerCase();
    })
    .join(" ");

export function DepartmentDetails({ Id }: any) {
  const { t } = useTranslation();
  const {
    department,
    departments,
    setSelectedDepartment,
    distributionKeys,
    updateDepartment,
  } = useBenefitDxContext();

  const [defaults, setDefaults] = useState(
    department?.DistributionDefaults || {},
  );

  useEffect(() => {
    setSelectedDepartment(departments.find((d) => d.Id === Id));
  }, [Id, departments, setSelectedDepartment]);

  return (
    <Grid
      container
      width={"100%"}
      justifyContent={"center"}
      sx={{ overflow: "hidden" }}
    >
      <Helmet title={t("Department details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingY={2.5}
        spacing={5}
        wrap="wrap"
        width={"100%"}
      >
        <Grid width={"100%"}>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              title={t("Distribution defaults")}
              avatar={<BarChart />}
            ></CardHeader>
            <CardContent>
              <List>
                {distributionKeys.map((dkey) => {
                  return (
                    <ListItem key={dkey.Id}>
                      <Grid
                        container
                        justifyContent="space-between"
                        width={"100%"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                        spacing={5}
                      >
                        <Grid flex={6} alignItems={"center"}>
                          {t(dkey.Name)}
                        </Grid>
                        <Grid container alignItems={"center"} flex={5}>
                          <Grid alignItems={"center"} flex={3}>
                            <FormControl
                              fullWidth
                              key={"CalculationMethod"}
                              margin="normal"
                              sx={{ marginY: 3 }}
                            >
                              <InputLabel id={`CalculationMethod-input-label`}>
                                {t("Calculation method")}
                              </InputLabel>
                              <Select
                                labelId={`CalculationMethod-input-label`}
                                name={"CalculationMethod"}
                                value={
                                  department?.DistributionDefaults?.[dkey.Id]
                                    ?.Setting || DistributionSetting.Constant
                                }
                                label={t("Calculation method")}
                                onChange={async (e) => {
                                  setDefaults({
                                    ...defaults,
                                    [dkey.Id]: {
                                      Setting: e.target
                                        .value as DistributionSetting,
                                      Value: defaults[dkey.Id]?.Value || 0,
                                    },
                                  });

                                  if (department) {
                                    department.DistributionDefaults = {
                                      ...department?.DistributionDefaults,
                                      [dkey.Id]: {
                                        Setting: e.target
                                          .value as DistributionSetting,
                                        Value: defaults[dkey.Id]?.Value || 0,
                                      },
                                    };
                                    await updateDepartment(department);
                                  }
                                }}
                              >
                                {Object.keys(DistributionSetting)
                                  .map((setting) => ({
                                    label: setting,
                                    value: setting,
                                  }))
                                  .map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                      {t(label)}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid flex={2}>
                            {department?.DistributionDefaults?.[dkey.Id]
                              ?.Setting !== DistributionSetting.Previous ? (
                              <TextField
                                type={"number"}
                                name={"DefaultValue"}
                                label={t("Default value")}
                                value={
                                  department?.DistributionDefaults?.[dkey.Id]
                                    ?.Value || 0
                                }
                                fullWidth
                                onChange={async (e) => {
                                  setDefaults({
                                    ...defaults,
                                    [dkey.Id]: {
                                      Setting:
                                        defaults[dkey.Id]?.Setting ||
                                        DistributionSetting.Constant,
                                      Value: e.target.value,
                                    },
                                  });

                                  if (department) {
                                    department.DistributionDefaults = {
                                      ...department?.DistributionDefaults,
                                      [dkey.Id]: {
                                        Setting:
                                          department?.DistributionDefaults?.[
                                            dkey.Id
                                          ]?.Setting ||
                                          DistributionSetting.Constant,
                                        Value: e.target.value,
                                      },
                                    };

                                    await updateDepartment(department);
                                  }
                                }}
                              />
                            ) : (
                              <Typography textAlign={"center"}>---</Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function Departments() {
  const { t } = useTranslation();
  const {
    distributionKeys,
    departments,
    createDepartment,
    isListDepartmentsByHospitalPending,
  } = useBenefitDxContext();
  const localeText = useTableLocaleText();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [departmentClass, setDepartmentClass] = useState<DepartmentClass>(
    DepartmentClass.Expense,
  );

  const columns: GridColDef[] = [
    {
      field: "Name",
      headerName: t("Name") as string,
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <Link
          to={`/hospitals/${params.row.HospitalId}/departments/${params.row.Id}`}
        >
          {t(params.value)}
        </Link>
      ),
    },
    {
      field: "Code",
      headerName: t("Code") as string,
      flex: 1,
      editable: true,
    },
    {
      field: "Order",
      headerName: t("Order") as string,
      flex: 1,
      editable: true,
      type: "number",
      align: "left",

      headerAlign: "left",
    },
    {
      field: "Class",
      headerName: t("Department Class") as string,
      flex: 1,
      type: "singleSelect",
      valueOptions: Object.values(DepartmentClass).map((val) => val),
      getOptionLabel(value) {
        return t(Object.values(DepartmentClass).find((val) => val === value)!!);
      },
      editable: true,
    },
  ];

  const formProps: FormProps = {
    submitText: "Save",
    properties: [
      {
        name: "Name",
        label: "Name",
        required: true,
        type: "text",
        initialValue: "",
      },
      {
        name: "Code",
        label: "Code",
        required: true,
        type: "text",
        initialValue: "",
      },
      {
        name: "Type",
        label: "Type",
        required: true,
        type: "select",
        initialValue: DepartmentClass.Income,
        options: [
          {
            label: "Expense Center",
            value: DepartmentClass.Expense,
          },
          {
            label: "Auxiliary Expense Center",
            value: DepartmentClass.AuxiliaryExpense,
          },
          {
            label: "Other",
            value: DepartmentClass.Other,
          },
        ],
      },
      {
        name: "DistributionKey",
        label: "Distribution Key",
        required: true,
        type: "multiselect",
        multiple: true,
        initialValue: distributionKeys?.[0]?.Id,
        options: distributionKeys.map((dkey) => ({
          label: dkey.Name,
          value: dkey.Id,
        })),
      },
      {
        name: "Order",
        label: "Order",
        required: true,
        type: "number",
        initialValue: "0",
      },
    ],
    onSubmit: async (values: any) => {
      console.log(values);
      const { submit, ...department } = values;
      console.log(department);
      await createDepartment(department);
      setDialogOpen(false);
    },
  };

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<Add />}
          size="large"
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          {t("Add new")}
        </Button>
        <Button component={Link} to={`selections`} size="large">
          {t("Selections")}
        </Button>
        <Button component={Link} to={`distribution-settings`} size="large">
          {t("Distribution settings")}
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <Paper sx={{ width: "100%" }}>
      <Helmet title={t("Departments") as string} />
      <Grid container direction={"column"} width={"100%"}>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <DataGridPro
              loading={isListDepartmentsByHospitalPending}
              disableMultipleRowSelection={true}
              slots={{ loadingOverlay: LinearProgress, toolbar: EditToolbar }}
              autoHeight
              getRowId={(row) => row.Id}
              density="compact"
              rows={departments}
              columns={columns}
              sx={{
                width: "100%",
                [`& .${gridClasses.row}`]: { cursor: "pointer" },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: "none",
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
              }}
              hideFooter
              getDetailPanelContent={({ row }) => (
                <DepartmentDetails Id={row.Id} />
              )}
              getDetailPanelHeight={() => "auto"}
              detailPanelExpandedRowIds={selected}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                if (selected[0] === newRowSelectionModel[0]) {
                  setSelected([]);
                } else {
                  setSelected(newRowSelectionModel as string[]);
                }
              }}
              localeText={localeText}
            />
          </CardContent>
        </Card>
        <Dialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
        >
          <DialogTitle>{t("Create new department")}</DialogTitle>
          <DialogContent>
            <BenefitDxForm {...formProps} />
          </DialogContent>
        </Dialog>
      </Grid>
    </Paper>
  );
}

export default Departments;
