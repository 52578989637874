import { useTranslation } from "react-i18next";

export function useTableLocaleText() {
  const { t } = useTranslation();

  return {
    noRowsLabel: t("No rows") as string,
    noResultsOverlayLabel: t("No results found.") as string,
    toolbarDensity: t("Density") as string,
    toolbarDensityLabel: t("Density") as string,
    toolbarDensityCompact: t("Compact") as string,
    toolbarDensityStandard: t("Standard") as string,
    toolbarDensityComfortable: t("Comfortable") as string,
    toolbarColumns: t("Columns") as string,
    toolbarColumnsLabel: t("Select columns") as string,
    toolbarFilters: t("Filters") as string,
    toolbarFiltersLabel: t("Show filters") as string,
    toolbarFiltersTooltipHide: t("Hide filters") as string,
    toolbarFiltersTooltipShow: t("Show filters") as string,
    toolbarFiltersTooltipActive: (count: any) =>
      count !== 1
        ? (t(`{{count}} active filters`, { count }) as string)
        : (t(`{{count}} active filter`, { count }) as string),
    toolbarQuickFilterPlaceholder: t("Search…") as string,
    toolbarQuickFilterLabel: t("Search") as string,
    toolbarQuickFilterDeleteIconLabel: t("Clear") as string,
    toolbarExport: t("Export") as string,
    toolbarExportLabel: t("Export") as string,
    toolbarExportCSV: t("Download as CSV") as string,
    toolbarExportPrint: t("Print") as string,
    toolbarExportExcel: t("Download as Excel") as string,
    columnsPanelTextFieldLabel: t("Find column") as string,
    columnsPanelTextFieldPlaceholder: t("Column title") as string,
    columnsPanelDragIconLabel: t("Reorder column") as string,
    columnsPanelShowAllButton: t("Show all") as string,
    columnsPanelHideAllButton: t("Hide all") as string,
    filterPanelAddFilter: t("Add filter") as string,
    filterPanelRemoveAll: t("Remove all") as string,
    filterPanelDeleteIconLabel: t("Delete") as string,
    filterPanelLogicOperator: t("Logic operator") as string,
    filterPanelOperator: t("Operator") as string,
    filterPanelOperatorAnd: t("And") as string,
    filterPanelOperatorOr: t("Or") as string,
    filterPanelColumns: t("Columns") as string,
    filterPanelInputLabel: t("Value") as string,
    filterPanelInputPlaceholder: t("Filter value") as string,
    filterOperatorContains: t("contains") as string,
    filterOperatorEquals: t("equals") as string,
    filterOperatorStartsWith: t("starts with") as string,
    filterOperatorEndsWith: t("ends with") as string,
    filterOperatorIs: t("is") as string,
    filterOperatorNot: t("is not") as string,
    filterOperatorAfter: t("is after") as string,
    filterOperatorOnOrAfter: t("is on or after") as string,
    filterOperatorBefore: t("is before") as string,
    filterOperatorOnOrBefore: t("is on or before") as string,
    filterOperatorIsEmpty: t("is empty") as string,
    filterOperatorIsNotEmpty: t("is not empty") as string,
    filterOperatorIsAnyOf: t("is any of") as string,
    "filterOperator=": "=",
    "filterOperator!=": "!=",
    "filterOperator>": ">",
    "filterOperator>=": ">=",
    "filterOperator<": "<",
    "filterOperator<=": "<=",
    headerFilterOperatorContains: t("Contains") as string,
    headerFilterOperatorEquals: t("Equals") as string,
    headerFilterOperatorStartsWith: t("Starts with") as string,
    headerFilterOperatorEndsWith: t("Ends with") as string,
    headerFilterOperatorIs: t("Is") as string,
    headerFilterOperatorNot: t("Is not") as string,
    headerFilterOperatorAfter: t("Is after") as string,
    headerFilterOperatorOnOrAfter: t("Is on or after") as string,
    headerFilterOperatorBefore: t("Is before") as string,
    headerFilterOperatorOnOrBefore: t("Is on or before") as string,
    headerFilterOperatorIsEmpty: t("Is empty") as string,
    headerFilterOperatorIsNotEmpty: t("Is not empty") as string,
    headerFilterOperatorIsAnyOf: t("Is any of") as string,
    "headerFilterOperator=": t("Equals") as string,
    "headerFilterOperator!=": t("Not equals") as string,
    "headerFilterOperator>": t("Greater than") as string,
    "headerFilterOperator>=": t("Greater than or equal to") as string,
    "headerFilterOperator<": t("Less than") as string,
    "headerFilterOperator<=": t("Less than or equal to") as string,
    filterValueAny: t("any") as string,
    filterValueTrue: t("true") as string,
    filterValueFalse: t("false") as string,
    columnMenuLabel: t("Menu") as string,
    columnMenuShowColumns: t("Show columns") as string,
    columnMenuManageColumns: t("Manage columns") as string,
    columnMenuFilter: t("Filter") as string,
    columnMenuHideColumn: t("Hide column") as string,
    columnMenuUnsort: t("Unsort") as string,
    columnMenuSortAsc: t("Sort by ASC") as string,
    columnMenuSortDesc: t("Sort by DESC") as string,
    columnHeaderFiltersTooltipActive: (count: number) =>
      count !== 1
        ? (t(`{{count}} active filters`, { count }) as string)
        : (t(`{{count}} active filter`, { count }) as string),
    columnHeaderFiltersLabel: t("Show filters") as string,
    columnHeaderSortIconLabel: t("Sort") as string,
    footerRowSelected: (count: number) =>
      count !== 1
        ? (t(`{{count}} rows selected`, {
            count: count.toLocaleString() as unknown as any,
          }) as any)
        : (t(`{{count}} row selected`, {
            count: count.toLocaleString() as unknown as any,
          }) as any),

    footerTotalRows: t("Total Rows:") as string,
    footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
      t(`{{visibleCount}} of {{totalCount}}`, {
        visibleCount: visibleCount.toLocaleString(),
        totalCount: totalCount.toLocaleString(),
      }) as string,
    checkboxSelectionHeaderName: t("Checkbox selection") as string,
    checkboxSelectionSelectAllRows: t("Select all rows") as string,
    checkboxSelectionUnselectAllRows: t("Unselect all rows") as string,
    checkboxSelectionSelectRow: t("Select row") as string,
    checkboxSelectionUnselectRow: t("Unselect row") as string,
    booleanCellTrueLabel: t("yes") as string,
    booleanCellFalseLabel: t("no") as string,
    actionsCellMore: t("more") as string,
    pinToLeft: t("Pin to left") as string,
    pinToRight: t("Pin to right") as string,
    unpin: t("Unpin") as string,
    treeDataGroupingHeaderName: t("Group") as string,
    treeDataExpand: t("see children") as string,
    treeDataCollapse: t("hide children") as string,
    groupingColumnHeaderName: t("Group") as string,
    groupColumn: (name: any) => t(`Group by {{name}}`, { name }) as string,
    unGroupColumn: (name: any) =>
      t(`Stop grouping by {{name}}`, { name }) as string,
    detailPanelToggle: t("Detail panel toggle") as string,
    expandDetailPanel: t("Expand") as string,
    collapseDetailPanel: t("Collapse") as string,
    rowReorderingHeaderName: t("Row reordering") as string,
    aggregationMenuItemHeader: t("Aggregation") as string,
    aggregationFunctionLabelSum: t("sum") as string,
    aggregationFunctionLabelAvg: t("avg") as string,
    aggregationFunctionLabelMin: t("min") as string,
    aggregationFunctionLabelMax: t("max") as string,
    aggregationFunctionLabelSize: t("size") as string,
    MuiTablePagination: {
      labelRowsPerPage: t("Rows per page:"),
    },
  };
}
