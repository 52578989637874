import { useLocation, useParams } from "react-router-dom";

import { Link, Breadcrumbs as Crumbs, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { useBenefitDxContext } from "../contexts/BenefitDxContext";
export type BreadcrumbsProps = {};

const CrumbContainer = styled.div`
  margin-bottom: 24px;
`;

export function Breadcrumbs(props: BreadcrumbsProps) {
  const { t } = useTranslation();
  const { hospital, department, user } = useBenefitDxContext();
  const location = useLocation();

  let links: any = [];

  if (location.pathname === "/account") {
    links = [{ to: "/account", children: t("Account") }];
  } else if (location.pathname === `/hospitals`) {
    links = [{ to: "/hospitals", children: t("Hospitals") }];
  } else if (location.pathname === `/users`) {
    links = [{ to: "/users", children: t("Users") }];
  } else if (location.pathname === `/hospitals/${hospital?.Id}`) {
    links = [
      { to: "/account", children: t("Account") },
      { to: location.pathname, children: hospital?.Name },
    ];
  } else if (location.pathname === `/hospitals/${hospital?.Id}/departments`) {
    links = [
      { to: "/hospitals", children: t("Hospitals") },
      { to: `/hospitals/${hospital?.Id}`, children: hospital?.Name },
      { to: location.pathname, children: t("Departments") },
    ];
  } else if (
    location.pathname === `/hospitals/${hospital?.Id}/distribution-keys`
  ) {
    links = [
      { to: "/hospitals", children: t("Hospitals") },
      { to: `/hospitals/${hospital?.Id}`, children: hospital?.Name },
      { to: location.pathname, children: t("Distribution keys") },
    ];
  } else if (location.pathname === `/hospitals/${hospital?.Id}/reports`) {
    links = [
      { to: "/hospitals", children: t("Hospitals") },
      { to: `/hospitals/${hospital?.Id}`, children: hospital?.Name },
      { to: location.pathname, children: t("Reports") },
    ];
  } else if (location.pathname === `/hospitals/${hospital?.Id}/mappings`) {
    links = [
      { to: "/hospitals", children: t("Hospitals") },
      { to: `/hospitals/${hospital?.Id}`, children: hospital?.Name },
      { to: location.pathname, children: t("Data mappings") },
    ];
  } else if (location.pathname === `/hospitals/${hospital?.Id}/import-data`) {
    links = [
      { to: "/hospitals", children: t("Hospitals") },
      { to: `/hospitals/${hospital?.Id}`, children: hospital?.Name },
      { to: location.pathname, children: t("Import data") },
    ];
  } else if (location.pathname === `/users/${user?.Id}`) {
    links = [
      { to: "/users", children: t("Users") },
      {
        to: location.pathname,
        children: user ? (
          `${user?.FirstName} ${user?.LastName}`
        ) : (
          <Skeleton height={12} width={40} animation="wave" />
        ),
      },
    ];
  } else if (
    location.pathname ===
    `/hospitals/${hospital?.Id}/departments/${department?.Id}`
  ) {
    links = [
      { to: "/hospitals", children: t("Hospitals") },
      { to: `/hospitals/${hospital?.Id}`, children: hospital?.Name },
      {
        to: `/hospitals/${hospital?.Id}/departments`,
        children: t("Departments"),
      },
      { to: location.pathname, children: department?.Name },
    ];
  } else if (location.pathname === `/hospitals/${hospital?.Id}/mappings/new`) {
    links = [
      { to: "/hospitals", children: t("Hospitals") },
      { to: `/hospitals/${hospital?.Id}`, children: hospital?.Name },
      { to: location.pathname, children: t("Data mappings") },
      { to: location.pathname, children: t("Create new mapping") },
    ];
  } else if (location.pathname === `/doctors`) {
    links = [{ to: "/doctors", children: t("Doctors") }];
  }

  return (
    <CrumbContainer role="presentation" style={{ marginBottom: "24px" }}>
      <Crumbs aria-label="breadcrumb">
        {links.map((link: any) => (
          <Link
            key={link.to}
            component={NavLink as any}
            underline="hover"
            color="inherit"
            {...link}
          ></Link>
        ))}
      </Crumbs>
    </CrumbContainer>
  );
}

export default Breadcrumbs;
