import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  gridClasses,
} from "@mui/x-data-grid-pro";

import {
  Add,
  Cancel,
  Save,
  Edit,
  Delete,
  ArrowForwardIos,
} from "@mui/icons-material";
import { useState } from "react";
import BenefitDxForm, { FormProps } from "./Form";
import { useTableLocaleText } from "../hooks/useTableLocaleText";
import { CRUDEntity } from "../types";
import { useNavigate, useNavigation } from "react-router-dom";

export type CRUDTableProps<T> = {
  entity: CRUDEntity;
  loading: boolean;
  rows: any[];
  DetailsView: any;
  generateUrl?: (id: GridRowId) => string;
  fetchNextPage?: () => Promise<any>;
  update: (payload: Partial<T>) => Promise<any>;
  delete: (entityId: any) => Promise<any>;
} & (
  | {
      createLink: string;
      create?: (payload: Partial<T>) => Promise<any>;
    }
  | {
      createLink?: string;
      create: (payload: Partial<T>) => Promise<any>;
    }
);

export const splitPascalCase = (key: string = "") =>
  key
    .split(/(?=[A-Z])/g)
    .map((k, i) => {
      if (!i) {
        return k;
      }
      return k.toLowerCase();
    })
    .join(" ");

const ColumnTypeMap = {
  text: "string",
  number: "number",
  select: "singleSelect",
  multiselect: "multiSelect",
};

export function CRUDTable<T>({
  entity,
  loading,
  rows,
  generateUrl,
  createLink,
  create,
  update,
  fetchNextPage,
  delete: deleteEntity,
}: CRUDTableProps<T>) {
  const { t } = useTranslation();
  const localeText = useTableLocaleText();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleOnRowsScrollEnd = async () => {
    if (!fetchNextPage) {
      return;
    }
    await fetchNextPage();
  };

  const columns: GridColDef[] = entity.Fields.map((Field) => {
    return {
      field: Field.Name,
      flex: 1,
      headerName: t(Field.Label) as string,
      required: Field.Required,
      editable: Field.Editable,
      type: ColumnTypeMap[Field.Type],
      valueOptions: Field.Options?.map((option) => option.Value),
      getOptionLabel(value) {
        return t(value);
      },
    };
  });

  const createForm: FormProps = {
    submitText: "Save",
    onSubmit: async (values: any) => {
      const { submit, ...payload } = values;
      if (create) {
        await create(payload);
      }
      setDialogOpen(false);
    },
    properties: entity.Fields.map((Field) => {
      return {
        name: Field.Name,
        label: Field.Label,
        required: Field.Required,
        type: Field.Type,
        initialValue: Field.InitialValue,
        options: Field.Options?.map((option) => ({
          label: option.Label,
          value: option.Value,
          icon: option.Icon,
        })),
      };
    }),
  };

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<Add />}
          size="large"
          onClick={() => {
            if (createLink) {
              navigate(createLink);
            } else {
              setDialogOpen(true);
            }
          }}
        >
          {t("Add new")}
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick =
    (id: GridRowId) =>
    (...params: any[]) => {
      console.log("id", id);
      console.log("params", params);
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteEntity(id);
  };

  const handleVisitClick = (id: GridRowId) => () => {
    if (generateUrl) {
      navigate(generateUrl(id));
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    update(newRow as T);
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <>
      <DataGridPro
        initialState={{ pinnedColumns: { right: ["actions"] } }}
        loading={loading}
        disableMultipleRowSelection={true}
        slots={{ loadingOverlay: LinearProgress, toolbar: EditToolbar }}
        slotProps={{ toolbar: { setRowModesModel } }}
        autoHeight
        getRowId={(row) => row.Id}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        rows={rows}
        columns={[
          ...columns,
          {
            field: "actions",
            type: "actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ row, id }) => {
              const isInEditMode =
                rowModesModel[row?.Id]?.mode === GridRowModes.Edit;

              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    icon={<Save />}
                    label="Save"
                    sx={{
                      color: "primary.main",
                    }}
                    onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                    icon={<Cancel />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />,
                ];
              }

              return [
                <GridActionsCellItem
                  icon={<Edit />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<ArrowForwardIos />}
                  label="Visit page"
                  onClick={handleVisitClick(id)}
                  color="inherit"
                />,
              ];
            },
          },
        ]}
        sx={{
          width: "100%",
          [`& .${gridClasses.row}`]: { cursor: "pointer" },
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: "none",
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: "none",
            },
        }}
        localeText={localeText}
      />
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>{t(`Create new ${entity.Type}`)}</DialogTitle>
        <DialogContent>
          <BenefitDxForm {...createForm} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CRUDTable;
