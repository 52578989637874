// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const DepartmentFields = ["Name", "Type", "Code", "Order"];
export const ServiceFields = [];
export const TransactionFields = [];
