import axios from "../utils/axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { Mapping, Hospital } from "../types";

export function useMappings(hospital: Hospital | undefined) {
  const client = useQueryClient();
  const { session } = useAuth();

  const create = async (payload: Partial<Mapping>) =>
    await axios.post(`/mappings/new`, {
      HospitalId: hospital?.Id,
      ...payload,
    });

  const {
    mutate: createMapping,
    isPending: isCreateMappingPending,
    error: createMappingError,
  } = useMutation({
    mutationFn: create,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["hospitals", hospital?.Id, "mappings"],
      });
    },
  });
  const {
    mutate: deleteMapping,
    isPending: isDeleteMappingPending,
    error: deleteMappingError,
  } = useMutation({
    mutationFn: async (MappingId: string) =>
      await axios.delete(`hospitals/${hospital?.Id}/mappings/${MappingId}`),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["hospitals", hospital?.Id, "mappings"],
      });
    },
  });

  const {
    error: listMappingsByHospitalError,
    data: mappings,
    isPending: isListMappingsByHospitalPending,
  } = useQuery({
    enabled: Boolean(hospital?.Id) && session?.isValid(),
    refetchOnWindowFocus: false,
    queryKey: ["hospitals", hospital?.Id, "mappings"],
    queryFn: async () =>
      (await axios.post(`/hospitals/${hospital?.Id}/mappings`)).data.Items,
  });

  const update = async (payload: Partial<Mapping>) =>
    await axios.put(`/mappings/${payload.Id}`, {
      HospitalId: hospital?.Id,
      ...payload,
    });

  const {
    mutate: updateMapping,
    isPending: isUpdateMappingPending,
    error: updateMappingError,
  } = useMutation({
    mutationFn: update,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["hospitals", hospital?.Id, "mappings"],
      });
    },
  });

  return {
    mappings: mappings || [],
    createMapping,
    isCreateMappingPending,
    createMappingError,
    deleteMapping,
    isDeleteMappingPending,
    deleteMappingError,
    updateMapping,
    isUpdateMappingPending,
    updateMappingError,
    listMappingsByHospitalError,
    isListMappingsByHospitalPending,
  };
}
