import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={512}
    height={512}
    viewBox="0 0 512 512"
    {...props}
  >
    <g fillRule="evenodd" clipRule="evenodd">
      <path fill="#ffd18d" d="M195.18 298.84h121.65v136.23H195.18z" />
      <path
        fill="#e6bc7f"
        d="M316.82 304.76v14.93c-17.98 10.37-38.77 16.28-60.82 16.28-12.53 0-24.66-1.9-36.08-5.48-5.36-1.64-10.72 2.31-10.72 7.91v40.59h-14.03v-74.23c17.48 11.71 38.42 18.54 60.82 18.54 22.47-.01 43.38-6.86 60.83-18.54z"
      />
      <path
        fill="#eceaec"
        d="m344.29 369.59 88.26 30.16c18.42 6.3 30.25 22.84 30.25 42.32v52.24c0 9.75-7.94 17.69-17.69 17.69H66.86c-9.72 0-17.66-7.94-17.66-17.69v-52.24c0-19.47 11.83-36.02 30.25-42.32l88.26-30.16 89.78 8.99z"
      />
      <path
        fill="#1badde"
        d="m255.5 510.54 88.79-140.95-88.29 7.47-88.29-7.47z"
      />
      <path
        fill="#4e3d4d"
        d="M241.71 0h28.55c63.22 0 114.91 51.72 114.91 114.91v49.31c0 36.08-29.52 65.6-65.57 65.6H192.4c-36.08 0-65.6-29.52-65.6-65.6v-49.31C126.8 51.72 178.51 0 241.71 0z"
      />
      <path
        fill="#ffd18d"
        d="M136.67 175.12h9.11c8.61 0 14.67-7.06 15.61-15.58v-24.42c0-6.35 6.12-10.81 12.18-8.84 28.84 9.4 75.9 12.83 115.38-34.09a9.25 9.25 0 0 1 13.65-.64c10.07 9.96 28.05 21.82 41.73 26.5 3.84 1.32 6.3 4.74 6.3 8.79v32.71c0 8.58 7 15.58 15.58 15.58h9.11c12.3 0 22.37 10.07 22.37 22.37s-10.07 22.34-22.37 22.34h-9.78c-3.28 57.46-51.28 103.46-109.52 103.46-57.89 0-106.24-45.65-109.52-103.46h-9.81c-12.3 0-22.34-10.04-22.34-22.34-.03-12.31 10.02-22.38 22.32-22.38z"
      />
      <path
        fill="#736572"
        d="m287.39 377.41-8.93 44.51c-.7 3.57-2.75 6.53-5.56 8.43l8.08 39.74-25.56 40.44-24.57-39.56 8.26-40.7c-2.69-1.93-4.69-4.86-5.59-8.35l-11.63-44.51z"
      />
    </g>
    <path
      fill="#e29a6c"
      d="M220.83 251.17c-3.81-2.49-4.89-7.58-2.43-11.39a8.234 8.234 0 0 1 11.42-2.43c8.96 5.8 17.78 9.14 26.36 9.34 8.4.18 16.93-2.64 25.59-9.05 3.63-2.72 8.81-1.96 11.51 1.7a8.243 8.243 0 0 1-1.7 11.54c-11.8 8.73-23.72 12.56-35.78 12.27-11.84-.3-23.49-4.54-34.97-11.98z"
    />
    <path
      fill="#736572"
      d="M378.88 444.15c7.29 0 13.88 2.96 18.65 7.73 4.74 4.77 7.7 11.36 7.7 18.62 0 7.29-2.96 13.85-7.7 18.62-4.77 4.77-11.36 7.73-18.65 7.73-7.26 0-13.85-2.96-18.62-7.73s-7.7-11.33-7.7-18.62c0-7.26 2.93-13.85 7.7-18.62 4.76-4.77 11.35-7.73 18.62-7.73zm6.97 19.41a9.8 9.8 0 0 0-6.97-2.87c-2.69 0-5.15 1.08-6.94 2.87-1.76 1.79-2.87 4.25-2.87 6.94a9.802 9.802 0 0 0 9.81 9.81 9.8 9.8 0 0 0 9.84-9.81c-.01-2.69-1.12-5.15-2.87-6.94z"
    />
    <path
      fill="#736572"
      d="M387.16 452.44c0 4.57-3.72 8.26-8.29 8.26-4.54 0-8.26-3.69-8.26-8.26v-70.4c0-4.57 3.72-8.29 8.26-8.29 4.57 0 8.29 3.72 8.29 8.29zM110.28 486.9c0 4.57-3.69 8.26-8.26 8.26s-8.26-3.69-8.26-8.26v-27.38c0-8.17 3.34-15.61 8.73-20.97 5.36-5.39 12.8-8.73 20.97-8.73 8.17 0 15.61 3.34 21 8.73 5.36 5.36 8.7 12.8 8.7 20.97v27.38c0 4.57-3.69 8.26-8.26 8.26s-8.26-3.69-8.26-8.26v-27.38c0-3.6-1.49-6.91-3.87-9.28-2.4-2.4-5.68-3.89-9.31-3.89-3.6 0-6.91 1.49-9.28 3.89-2.4 2.37-3.89 5.68-3.89 9.28v27.38z"
    />
    <path
      fill="#736572"
      d="M115.2 384.7c0-4.57 3.69-8.26 8.26-8.26s8.26 3.69 8.26 8.26v53.38c0 4.57-3.69 8.26-8.26 8.26s-8.26-3.69-8.26-8.26z"
    />
    <path
      fill="#4e3d4d"
      d="M315.53 146.62c4.54 0 8.26 3.69 8.26 8.26s-3.72 8.26-8.26 8.26h-20.56c-4.54 0-8.26-3.69-8.26-8.26s3.72-8.26 8.26-8.26zM216.99 146.62c4.57 0 8.26 3.69 8.26 8.26s-3.69 8.26-8.26 8.26h-20.53c-4.57 0-8.26-3.69-8.26-8.26s3.69-8.26 8.26-8.26zM198.46 181.77c0-4.57 3.72-8.26 8.29-8.26s8.26 3.69 8.26 8.26v11.16c0 4.57-3.69 8.26-8.26 8.26s-8.29-3.69-8.29-8.26zM297 181.77c0-4.57 3.69-8.26 8.26-8.26s8.26 3.69 8.26 8.26v11.16c0 4.57-3.69 8.26-8.26 8.26s-8.26-3.69-8.26-8.26z"
    />
    <path
      fill="#dbd8db"
      fillRule="evenodd"
      d="m167.71 369.59 26.41 42.4c3.05 4.89 10.57 5.74 15.11 2.14L256 377.06l-61.41-33.15c-5.71-3.08-12.83-1-15.78 4.63zM344.29 369.59 317.88 412c-3.05 4.89-10.6 5.74-15.11 2.14L256 377.06l61.41-33.15c5.71-3.08 12.83-1 15.78 4.63z"
      clipRule="evenodd"
    />
    <path
      fill="#e6bc7f"
      fillRule="evenodd"
      d="M179.28 228.5V128c-1.96-.53-3.89-1.11-5.71-1.73-6.06-1.96-12.18 2.49-12.18 8.84v24.42c-.94 8.52-7 15.58-15.61 15.58h-9.11c-3.05 0-5.97.64-8.64 1.76-8.02 3.4-13.7 11.36-13.7 20.62 0 12.3 10.04 22.34 22.34 22.34h9.81c2.02 35.46 20.97 66.33 48.7 84.92 17.48 11.71 38.42 18.54 60.82 18.54 1.2 0 2.43-.03 3.63-.06-21.67-.82-41.29-9.99-55.7-24.39-15.2-15.14-24.66-36.05-24.66-59.07V228.5z"
      clipRule="evenodd"
    />
    <path
      fill="#463745"
      fillRule="evenodd"
      d="M161.38 159.54v-24.42c0-6.35 6.12-10.81 12.18-8.84 1.82.61 3.75 1.2 5.71 1.73V18.57c-31.51 20.56-52.48 56.11-52.48 96.34v49.31c0 4.33.44 8.55 1.23 12.65 2.66-1.11 5.59-1.76 8.64-1.76h9.11c8.61.01 14.68-7.05 15.61-15.57z"
      clipRule="evenodd"
    />
    <path
      fill="#d4d3d4"
      fillRule="evenodd"
      d="m96.78 406.58 18.42-6.3v-12.74l-35.76 12.21c-18.42 6.3-30.25 22.84-30.25 42.32v52.24c0 9.75 7.94 17.69 17.66 17.69h3.43c-2.34-3.02-3.75-6.76-3.75-10.86V448.9c0-19.48 11.83-36.03 30.25-42.32zM131.72 384.7v9.93l42.52-14.52-6.53-10.51-36.43 12.45c.29.84.44 1.72.44 2.65z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
