function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#eff3f5"
        d="M422.504 512H89.496V10.302C89.496 4.612 94.108 0 99.798 0h312.404c5.69 0 10.302 4.612 10.302 10.302z"
      ></path>
      <path
        fill="#c9e2e7"
        d="M403.772 0v512h18.732V10.302c0-5.69-4.612-10.302-10.302-10.302z"
      ></path>
      <g fill="#62b4fd">
        <path d="M230.962 439.154a6.18 6.18 0 00-6.181 6.181V512h62.439v-66.664a6.18 6.18 0 00-6.181-6.181h-50.077zM137.429 206.049h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.182 6.182 0 01-6.181 6.181zM200.388 206.049h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM263.347 206.049h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM326.307 206.049h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.182 6.182 0 01-6.181 6.181zM389.266 206.049h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181z"></path>
        <g>
          <path d="M137.429 274.732h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.182 6.182 0 01-6.181 6.181zM200.388 274.732h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM263.347 274.732h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM326.307 274.732h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.182 6.182 0 01-6.181 6.181zM389.266 274.732h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181z"></path>
        </g>
        <g>
          <path d="M137.429 343.415h-14.695a6.18 6.18 0 01-6.181-6.181V307.97a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.182 6.182 0 01-6.181 6.181zM200.388 343.415h-14.695a6.18 6.18 0 01-6.181-6.181V307.97a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM263.347 343.415h-14.695a6.18 6.18 0 01-6.181-6.181V307.97a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM326.307 343.415h-14.695a6.18 6.18 0 01-6.181-6.181V307.97a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.182 6.182 0 01-6.181 6.181zM389.266 343.415h-14.695a6.18 6.18 0 01-6.181-6.181V307.97a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181z"></path>
        </g>
        <g>
          <path d="M137.429 412.098h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.182 6.182 0 01-6.181 6.181zM200.388 412.098h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM263.347 412.098h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM326.307 412.098h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.182 6.182 0 01-6.181 6.181zM389.266 412.098h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181z"></path>
        </g>
      </g>
      <path
        fill="#c9e2e7"
        d="M501.698 512h-79.194V64.52h79.194c5.69 0 10.302 4.612 10.302 10.302v426.876c0 5.69-4.612 10.302-10.302 10.302z"
      ></path>
      <path
        fill="#62b4fd"
        d="M474.599 206.049h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM474.599 274.732h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM474.599 137.366h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM474.599 343.415h-14.695a6.18 6.18 0 01-6.181-6.181V307.97a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181zM474.599 412.098h-14.695a6.18 6.18 0 01-6.181-6.181v-29.264a6.18 6.18 0 016.181-6.181h14.695a6.18 6.18 0 016.181 6.181v29.264a6.181 6.181 0 01-6.181 6.181z"
      ></path>
      <path fill="#a8d0d5" d="M422.504 64.52h18.732V512h-18.732z"></path>
      <path
        fill="#c9e2e7"
        d="M10.302 512h79.194V64.52H10.302C4.612 64.52 0 69.133 0 74.822v426.876C0 507.388 4.612 512 10.302 512z"
      ></path>
      <path
        fill="#62b4fd"
        d="M37.401 206.049h14.695a6.18 6.18 0 006.181-6.181v-29.264a6.18 6.18 0 00-6.181-6.181H37.401a6.18 6.18 0 00-6.181 6.181v29.264a6.181 6.181 0 006.181 6.181zM37.401 274.732h14.695a6.18 6.18 0 006.181-6.181v-29.264a6.18 6.18 0 00-6.181-6.181H37.401a6.18 6.18 0 00-6.181 6.181v29.264a6.181 6.181 0 006.181 6.181zM37.401 137.366h14.695a6.18 6.18 0 006.181-6.181v-29.264a6.18 6.18 0 00-6.181-6.181H37.401a6.18 6.18 0 00-6.181 6.181v29.264a6.181 6.181 0 006.181 6.181zM37.401 343.415h14.695a6.18 6.18 0 006.181-6.181V307.97a6.18 6.18 0 00-6.181-6.181H37.401a6.18 6.18 0 00-6.181 6.181v29.264a6.181 6.181 0 006.181 6.181zM37.401 412.098h14.695a6.18 6.18 0 006.181-6.181v-29.264a6.18 6.18 0 00-6.181-6.181H37.401a6.18 6.18 0 00-6.181 6.181v29.264a6.181 6.181 0 006.181 6.181z"
      ></path>
      <path fill="#a8d0d5" d="M70.764 64.52h18.732V512H70.764z"></path>
      <path
        fill="#fb636f"
        d="M187.317 127.064V0h137.366v127.064c0 5.69-4.612 10.302-10.302 10.302H197.619c-5.69 0-10.302-4.612-10.302-10.302z"
      ></path>
      <path
        fill="#eff3f5"
        d="M293.505 53.073H275.73a4.121 4.121 0 01-4.121-4.121V31.178a4.121 4.121 0 00-4.121-4.121H244.51a4.121 4.121 0 00-4.121 4.121v17.775a4.121 4.121 0 01-4.121 4.121h-17.775a4.121 4.121 0 00-4.121 4.121v22.978a4.121 4.121 0 004.121 4.121h17.775a4.121 4.121 0 014.121 4.121v17.775a4.121 4.121 0 004.121 4.121h22.978a4.121 4.121 0 004.121-4.121V88.413a4.121 4.121 0 014.121-4.121h17.775a4.121 4.121 0 004.121-4.121V57.194a4.121 4.121 0 00-4.121-4.121z"
      ></path>
      <path fill="#27a2fb" d="M248.274 439.154h15.453V512h-15.453z"></path>
    </svg>
  );
}

export default Icon;
