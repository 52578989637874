import axios from "../utils/axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { Hospital } from "../types";
import { Account } from "../types";

export function useHospitals(account: Account | undefined) {
  const client = useQueryClient();
  const { session } = useAuth();

  const {
    error: listHospitalsError,
    data: hospitals,
    isFetching: isListHospitalsFetching,
  } = useQuery({
    enabled: Boolean(account?.Id) && session?.isValid(),
    refetchOnWindowFocus: false,
    queryKey: ["hospitals"],
    queryFn: async () =>
      (await axios.post(`/accounts/${account?.Id}/hospitals`)).data.Items,
  });

  const {
    mutate: createHospital,
    isPending: isCreateHospitalPending,
    error: createHospitalError,
  } = useMutation({
    mutationFn: async (payload: Partial<Hospital>) =>
      await axios.post(`/hospitals/new`, {
        AccountId: account?.Id,
        ...payload,
      }),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["hospitals"],
      });
    },
  });

  const {
    mutate: updateHospital,
    isPending: isUpdateHospitalPending,
    error: updateHospitalError,
  } = useMutation({
    mutationFn: async (updatedHospital: Hospital) =>
      await axios.put(`/hospitals/${updatedHospital?.Id}`, updatedHospital),
    onSuccess: (_data, variables, _context) => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["hospitals", variables?.Id],
      });
    },
  });

  const {
    mutate: deleteHospital,
    isPending: isDeleteHospitalPending,
    error: deleteHospitalError,
  } = useMutation({
    mutationFn: async (Id: string) => await axios.delete(`/hospitals/${Id}`),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["accounts", account?.Id, "hospitals"],
      });
    },
  });

  return {
    listHospitalsError,
    createHospitalError,
    updateHospitalError,
    deleteHospitalError,
    isListHospitalsPending: isListHospitalsFetching,
    isCreateHospitalPending,
    isUpdateHospitalPending,
    isDeleteHospitalPending,
    hospitals: hospitals || [],
    createHospital,
    updateHospital,
    deleteHospital,
  };
}
