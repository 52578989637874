import axios from "../utils/axios";
import { Account } from "../types";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";

export function useAccount(AccountId: string) {
  const client = useQueryClient();
  const { session } = useAuth();

  const {
    error: fetchError,
    data: account,
    isPending: isFetchPending,
  } = useQuery({
    enabled: Boolean(AccountId) && session?.isValid(),
    refetchOnWindowFocus: false,
    queryKey: ["accounts", AccountId],
    queryFn: async () => (await axios.get(`/accounts/${AccountId}`)).data,
  });

  const { mutate, isPending, error } = useMutation({
    mutationFn: async (acct: Account) =>
      await axios.put(`/accounts/${acct?.Id}`, acct),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({ queryKey: ["accounts", AccountId] });
    },
  });

  return {
    error: fetchError || error,
    loading: isPending || isFetchPending,
    account,
    updateAccount: mutate,
  };
}
