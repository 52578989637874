// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ConfirmRegistration from "./pages/auth/ConfirmRegistration";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Landing
import Landing from "./pages/presentation/Landing";

// Dashboard components
import DataImport from "./pages/dashboards/Hospital/DataImport";
import CreateMapping from "./pages/dashboards/Hospital/Mappings/Create";
import CreateHospital from "./pages/dashboards/Hospitals/Create";
import HospitalDetails from "./pages/dashboards/Hospital";
import User from "./pages/dashboards/User";
import Account from "./pages/dashboards/Account";
import Departments from "./pages/dashboards/Hospital/Departments";
import Reports from "./pages/dashboards/Hospital/Reports";
import Hospital from "./pages/dashboards/Hospital/Details";
import CreateUser from "./pages/dashboards/Users/Create";
import Mappings from "./pages/dashboards/Hospital/Mappings";
import Hospitals from "./pages/dashboards/Hospitals";
import Users from "./pages/dashboards/Users";
import Department from "./pages/dashboards/Department";
import Mapping from "./pages/dashboards/Mapping";
import Upload from "./pages/dashboards/Upload";

// Doctor components
import Doctors from "./pages/dashboards/Doctors";

// Procedure components
import Procedures from "./pages/dashboards/Procedures";
import DepartmentSelections from "./pages/dashboards/Hospital/Departments/Selections";
import DistributionSettings from "./pages/dashboards/Hospital/Departments/DistributionSettings";

const routes = [
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: (
          <GuestGuard>
            <Landing />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "account",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Account />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "doctors",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Doctors />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "procedures",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Procedures />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "hospitals",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Hospitals />
          </AuthGuard>
        ),
      },
      {
        path: "new",
        element: (
          <AuthGuard>
            <CreateHospital />
          </AuthGuard>
        ),
      },
      {
        path: ":HospitalId",
        element: (
          <AuthGuard>
            <HospitalDetails />
          </AuthGuard>
        ),
        children: [
          {
            path: "",
            element: (
              <AuthGuard>
                <Hospital />
              </AuthGuard>
            ),
          },
          {
            path: "import-data",
            element: (
              <AuthGuard>
                <DataImport />
              </AuthGuard>
            ),
          },
          {
            path: "departments",
            element: (
              <AuthGuard>
                <Departments />
              </AuthGuard>
            ),
          },
          {
            path: "mappings",
            element: (
              <AuthGuard>
                <Mappings />
              </AuthGuard>
            ),
          },
          {
            path: "reports",
            element: (
              <AuthGuard>
                <Reports />
              </AuthGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "hospitals/:HospitalId/mappings/new",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <CreateMapping />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "hospitals/:HospitalId/mappings/:MappingId",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Mapping />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "hospitals/:HospitalId/departments/distribution-settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <DistributionSettings />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "hospitals/:HospitalId/departments/selections",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <DepartmentSelections />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "hospitals/:HospitalId/departments/:DepartmentId",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Department />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "mappings/:MappingId/uploads/:UploadId",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Upload />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Users />
          </AuthGuard>
        ),
      },
      {
        path: "new",
        element: (
          <AuthGuard>
            <CreateUser />
          </AuthGuard>
        ),
      },
      {
        path: ":Id",
        element: (
          <AuthGuard>
            <User />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: (
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        ),
      },
      {
        path: "sign-up",
        element: (
          <GuestGuard>
            <SignUp />
          </GuestGuard>
        ),
      },
      {
        path: "confirm-registration",
        element: (
          <GuestGuard>
            <ConfirmRegistration />
          </GuestGuard>
        ),
      },
      {
        path: "reset-password",
        element: (
          <GuestGuard>
            <ResetPassword />
          </GuestGuard>
        ),
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
