import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function Reports() {
  const { t } = useTranslation();
  return (
    <Paper>
      <Helmet title={t("Reports") as string} />
      <Grid container direction={"column"}>
        <Card>
          <CardContent>{t("Reports")}</CardContent>
        </Card>
      </Grid>
    </Paper>
  );
}

export default Reports;
