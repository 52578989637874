import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import {
  Security,
  Upload as UploadIcon,
  Add,
  Download,
  FileOpen,
} from "@mui/icons-material";
import {
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  LinearProgress,
  InputLabel,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  gridClasses,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid-pro";
import LoadingButton from "@mui/lab/LoadingButton";
import { Delete } from "@mui/icons-material";
import axios from "axios";

import { Upload, UploadStatus } from "../../../types";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";
import { useTableLocaleText } from "../../../hooks/useTableLocaleText";

const FileInputLabel = styled.label`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const FileInput = styled.input`
  display: none;
`;

const Center = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
});

const Element = styled.div(({ theme }) => ({
  border: `1px solid ${(theme.vars || theme).palette.divider}`,
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: 26,
  borderRadius: 2,
}));

const Value = styled.div({
  position: "absolute",
  lineHeight: "24px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  color: "white",
  fontWeight: "bold",
  WebkitTextStrokeColor: "#000",
  WebkitTextStrokeWidth: "0.5px",
});

const Bar = styled.div({
  height: "100%",
  "&.low": {
    backgroundColor: "#f44336",
  },
  "&.medium": {
    backgroundColor: "#efbb5aa3",
  },
  "&.high": {
    backgroundColor: "#088208a3",
  },
});

function UploadDetails(upload: Upload) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      width={"100%"}
      justifyContent={"center"}
      sx={{ overflow: "hidden" }}
    >
      <Helmet title={t("Upload details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingY={2.5}
        spacing={5}
        wrap="wrap"
        width={"100%"}
      >
        <Grid width={"100%"}>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              title={t("Upload Details")}
              avatar={<UploadIcon />}
            ></CardHeader>
            <CardContent></CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function Mapping() {
  const { HospitalId, MappingId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const localeText = useTableLocaleText();
  const [file, setFile] = useState<File | null>(null);
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    mapping,
    uploads,
    mappings,
    hospitals,
    setSelectedMapping,
    setSelectedHospital,
    isListUploadsByMappingPending,
    isCreateUploadPending,
    isUpdateUploadPending,
    isDeleteUploadPending,
    createUpload,
    updateUpload,
    setError,
    createdUpload,
    resetCreateUpload,
    deleteUpload,
  } = useBenefitDxContext();

  const processUpload = useCallback(
    async (upload: Upload) => {
      resetCreateUpload();
      if (upload?.SignedURL) {
        await updateUpload({
          ...upload,
          UploadStatus: UploadStatus.Uploading,
        });
        try {
          setLoading(true);
          await axios.put(upload?.SignedURL, file);
        } catch (err) {
          console.log(err);
          setError?.(err as any);
        } finally {
          setLoading(false);
        }
      }
    },
    [file],
  );

  useEffect(() => {
    setSelectedHospital(hospitals.find((hs) => hs.Id === HospitalId));
  }, [HospitalId, setSelectedHospital, hospitals]);

  useEffect(() => {
    setSelectedMapping(mappings.find((dep) => dep.Id === MappingId));
  }, [MappingId, setSelectedMapping, mappings]);

  useEffect(() => {
    if (createdUpload) {
      processUpload(createdUpload);
    }
  }, [createdUpload, processUpload]);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setFile(event.target.files[0]);

      await createUpload({
        FileName: event.target.files[0].name,
        FileSize: event.target.files[0].size,
        FileType: event.target.files[0].type,
        MappingId,
        HospitalId,
        UploadStatus: UploadStatus.Created,
        YearMonth: new Date().toISOString().substring(0, 7),
      });
    }
  };

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <form>
          <LoadingButton
            variant="contained"
            fullWidth
            loading={
              isListUploadsByMappingPending ||
              isCreateUploadPending ||
              isUpdateUploadPending ||
              isDeleteUploadPending
            }
            sx={{ m: 2 }}
          >
            <FileInputLabel htmlFor="file-upload-input">
              {file ? file.name : t("Upload")}
              <FileInput
                name="file-upload-input"
                id="file-upload-input"
                type="file"
                accept="text/csv"
                onChange={onChange}
              />
            </FileInputLabel>
          </LoadingButton>
        </form>
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "FileName",
      headerName: t("File Name") as string,
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <Link to={`/mappings/${params.row.MappingId}/uploads/${params.row.Id}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "UploadProgress",
      headerName: t("Upload Progress") as string,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        let { value, row } = params;

        if (!value) {
          value = 0;
        } else if (isNaN(value)) {
          value = 0;
        }

        let label = "";
        switch (row.UploadStatus) {
          case UploadStatus.Created:
            label = t("Created");
            break;
          case UploadStatus.Uploading:
            label = t("Uploading...");
            break;
          case UploadStatus.Processing:
            value = (row.RowsProcessed / row.RowsRead) * 100;
            label = t("Processing...");
            break;
          case UploadStatus.Completed:
            label = t("Completed");
            break;
          case UploadStatus.GeneratingCSV:
            label = t("Completed");
            break;
          case UploadStatus.AllDone:
            label = t("Completed");
            break;
        }

        return (
          <Center>
            <Element>
              <Value>{label}</Value>
              <Bar
                className={clsx({
                  low: value < 30,
                  medium: value >= 30 && value <= 70,
                  high: value > 70,
                })}
                style={{ maxWidth: `${value}%` }}
              />
            </Element>
          </Center>
        );
      },
    },
    {
      field: "RowsRead",
      flex: 1,
      headerName: t("Rows Read") as string,
      editable: false,
    },
    {
      field: "RowsProcessed",
      flex: 1,
      headerName: t("Rows Processed") as string,
      editable: false,
    },
    {
      field: "ErroredRowCount",
      flex: 1,
      headerName: t("Errored Rows Count") as string,
      editable: false,
    },
  ];

  const handleDeleteClick = (id: GridRowId) => async () => {
    await deleteUpload(id);
  };

  return (
    <React.Fragment>
      <Helmet title={t("Mapping details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingTop={5}
        spacing={5}
        wrap="wrap"
      >
        <Grid width={"100%"}>
          <Card>
            <CardHeader
              title={t("Uploads for {{mappingName}}", {
                mappingName: mapping?.Name,
              })}
              avatar={<UploadIcon />}
            ></CardHeader>
            <CardContent>
              <DataGridPro
                loading={
                  isListUploadsByMappingPending ||
                  isCreateUploadPending ||
                  isDeleteUploadPending ||
                  isUpdateUploadPending
                }
                disableMultipleRowSelection={true}
                slots={{ loadingOverlay: LinearProgress, toolbar: EditToolbar }}
                autoHeight
                getRowId={(row) => row.Id}
                rows={uploads || []}
                columns={[
                  ...columns,
                  {
                    field: "actions",
                    type: "actions",
                    width: 100,
                    cellClassName: "actions",
                    getActions: ({ row, id }) => {
                      return [
                        <GridActionsCellItem
                          icon={<Delete />}
                          label="Delete"
                          onClick={handleDeleteClick(id)}
                          color="inherit"
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  width: "100%",
                  [`& .${gridClasses.row}`]: { cursor: "pointer" },
                  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                    {
                      outline: "none",
                    },
                  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                    {
                      outline: "none",
                    },
                }}
                hideFooter
                getDetailPanelContent={({ row }) => <UploadDetails {...row} />}
                getDetailPanelHeight={() => "auto"}
                detailPanelExpandedRowIds={selected}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  if (selected[0] === newRowSelectionModel[0]) {
                    setSelected([]);
                  } else {
                    setSelected(newRowSelectionModel as string[]);
                  }
                }}
                localeText={localeText}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Mapping;
