import { useState } from "react";
import axios from "../utils/axios";
import {
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from "@tanstack/react-query";
import useAuth from "./useAuth";
import { UploadError, Upload } from "../types";

export function useUploadErrors(upload: Upload | undefined) {
  const client = useQueryClient();
  const { session } = useAuth();

  const create = async (payload: Partial<UploadError>) =>
    await axios.post(`/upload-errors/new`, {
      UploadId: upload?.Id,
      ...payload,
    });

  const {
    mutate: createUploadError,
    isPending: isCreateUploadErrorPending,
    error: createUploadErrorError,
    reset: resetCreateUploadError,
  } = useMutation({
    mutationFn: create,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["uploads", upload?.Id, "upload-errors"],
      });
    },
  });

  const {
    mutate: deleteUploadError,
    isPending: isDeleteUploadErrorPending,
    error: deleteUploadErrorError,
  } = useMutation({
    mutationFn: async (UploadErrorId: string) =>
      await axios.delete(`/upload-errors/${UploadErrorId}`),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["uploads", upload?.Id, "upload-errors"],
      });
    },
  });

  const {
    error: listUploadErrorsByUploadError,
    data: uploadErrors,
    fetchNextPage,
    isPending: isListUploadErrorsByUploadPending,
  } = useInfiniteQuery({
    initialPageParam: undefined,
    getNextPageParam: (lastPage: any) => {
      console.log("lastPage", lastPage);
      return lastPage.NextToken;
    },
    enabled: Boolean(upload?.Id) && session?.isValid(),
    refetchOnWindowFocus: false,
    queryKey: ["uploads", upload?.Id, "upload-errors"],
    queryFn: async ({ pageParam }) =>
      (
        await axios.post(`/uploads/${upload?.Id}/upload-errors`, {
          NextToken: pageParam,
          Limit: 10,
        })
      ).data,
  });

  const update = async (payload: Partial<UploadError>) =>
    await axios.put(`/upload-errors/${payload.Id}`, {
      UploadId: upload?.Id,
      ...payload,
    });

  const {
    mutate: updateUploadError,
    isPending: isUpdateUploadErrorPending,
    error: updateUploadErrorError,
  } = useMutation({
    mutationFn: update,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["uploads", upload?.Id, "upload-errors"],
      });
    },
  });

  return {
    uploadErrors: uploadErrors?.pages.flatMap((page) => page.Items) || [],
    fetchNextPageOfUploadErrors: fetchNextPage,
    createUploadError,
    isCreateUploadErrorPending,
    createUploadErrorError,
    deleteUploadError,
    isDeleteUploadErrorPending,
    deleteUploadErrorError,
    updateUploadError,
    isUpdateUploadErrorPending,
    updateUploadErrorError,
    listUploadErrorsByUploadError,
    isListUploadErrorsByUploadPending,
    resetCreateUploadError,
  };
}
