import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Security } from "@mui/icons-material";
import {
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";

export function Department() {
  const { HospitalId, DepartmentId } = useParams();
  const { t } = useTranslation();
  const { departments, hospitals, setSelectedDepartment, setSelectedHospital } =
    useBenefitDxContext();

  useEffect(() => {
    setSelectedHospital(hospitals.find((hs) => hs.Id === HospitalId));
  }, [HospitalId, setSelectedHospital, hospitals]);

  useEffect(() => {
    setSelectedDepartment(departments.find((dep) => dep.Id === DepartmentId));
  }, [DepartmentId, setSelectedDepartment, departments]);

  return (
    <React.Fragment>
      <Helmet title={t("Department details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingTop={5}
        spacing={5}
        wrap="wrap"
      >
        <Grid width={"100%"}>
          <Card>
            <CardHeader
              title={t("Distribution values")}
              avatar={<Security />}
            ></CardHeader>
            <CardContent></CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Department;
