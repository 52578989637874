import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  Paper,
  CardActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { KeyValuePair } from "../../../components/KeyValuePair";
import {
  AccountingSoftware,
  City,
  HospitalType,
  ManagementSoftware,
} from "../../../types";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";

export function HospitalDetails() {
  const { t } = useTranslation();

  const { hospital, isListHospitalsPending, updateHospital } =
    useBenefitDxContext();

  return (
    <Paper sx={{ width: "100%" }}>
      <Helmet title={t("Hospital") as string} />
      <Grid container direction={"column"}>
        <Card>
          <CardContent>
            <Grid container flexDirection={"row"}>
              <Grid container flexDirection={"column"} flex={1}>
                <KeyValuePair
                  Key={"Name"}
                  Value={hospital?.Name}
                  Editable={true}
                  Loading={isListHospitalsPending}
                  OnSubmit={async (Name) => {
                    await updateHospital({ ...hospital!!, Name });
                  }}
                />
                <KeyValuePair
                  Key={"Branch"}
                  Value={hospital?.Branch}
                  Editable={true}
                  Loading={isListHospitalsPending}
                  OnSubmit={async (Branch) => {
                    await updateHospital({ ...hospital!!, Branch });
                  }}
                />
                <KeyValuePair
                  Key={"Type"}
                  Value={hospital?.Type || "---"}
                  Editable={true}
                  Loading={isListHospitalsPending}
                  OnSubmit={async (Type) => {
                    await updateHospital({
                      ...hospital!!,
                      Type: Type as HospitalType,
                    });
                  }}
                />
                <KeyValuePair
                  Key={"City"}
                  Value={hospital?.City || "---"}
                  Editable={true}
                  Loading={isListHospitalsPending}
                  OnSubmit={async (City) => {
                    await updateHospital({ ...hospital!!, City: City as City });
                  }}
                />
                <KeyValuePair
                  Key={"AccountingSoftware"}
                  Value={hospital?.AccountingSoftware || "---"}
                  Editable={true}
                  Loading={isListHospitalsPending}
                  OnSubmit={async (AccountingSoftware) => {
                    await updateHospital({
                      ...hospital!!,
                      AccountingSoftware:
                        AccountingSoftware as AccountingSoftware,
                    });
                  }}
                />
                <KeyValuePair
                  Key={"ManagementSoftware"}
                  Value={hospital?.ManagementSoftware || "---"}
                  Editable={true}
                  Loading={isListHospitalsPending}
                  OnSubmit={async (ManagementSoftware) => {
                    await updateHospital({
                      ...hospital!!,
                      ManagementSoftware:
                        ManagementSoftware as ManagementSoftware,
                    });
                  }}
                />
              </Grid>
              <Grid flex={3}></Grid>
            </Grid>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </Grid>
    </Paper>
  );
}

export default HospitalDetails;
