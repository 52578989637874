import React from "react";
import { useLocation } from "react-router-dom";

import { SidebarItemsType } from "../../types/sidebar";
import reduceChildRoutes from "./reduceChildRoutes";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import "../../vendor/perfect-scrollbar.css";

const baseScrollbar = (props: any) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
};

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  const { t } = useTranslation();

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth, t }),
    [] as JSX.Element[]
  );

  return <Scrollbar>{childRoutes}</Scrollbar>;
};

export default SidebarNavList;
