import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  CardHeader,
  CardActions,
  Skeleton,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Security, Mail, Person } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  gridClasses,
} from "@mui/x-data-grid-pro";

import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";
import { Add, Delete } from "@mui/icons-material";
import { useEffect, useState, useMemo } from "react";
import BenefitDxForm, { FormProps } from "../../../components/Form";
import { AccessLevel } from "../../../types";
import { KeyValuePair } from "../../../components/KeyValuePair";
import { Container } from "@mui/system";
import { useTableLocaleText } from "../../../hooks/useTableLocaleText";

export function UserDetails({ Id }: any) {
  const { t } = useTranslation();
  const {
    user,
    users,
    deleteUser,
    updateUser,
    isListUsersPending,
    isFetchUserPending,
    inviteUser,
    setSelectedUser,
  } = useBenefitDxContext();

  useEffect(() => {
    setSelectedUser(users.find((user) => user.Id === Id));
  }, [Id, setSelectedUser, users]);

  const Status = useMemo(() => {
    if (!user?.Cognito) {
      return <Alert severity="error">{t("Created")}</Alert>;
    }

    if (user.Cognito.UserStatus === "CONFIRMED") {
      return <Alert severity="success">{t("Active")}</Alert>;
    }

    return <Alert severity="warning">{t("Invited")}</Alert>;
  }, [user, t]);

  return (
    <Container sx={{ overflow: "hidden" }}>
      <Helmet title={t("User details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        spacing={5}
        paddingX={2.5}
        paddingY={5}
        wrap="wrap"
      >
        <Grid xs={12} sm={4} md={4}>
          <Card>
            <CardHeader
              title={
                isListUsersPending ? (
                  <Skeleton animation="wave" height={10} width="80%" />
                ) : (
                  t("Identity")
                )
              }
              avatar={
                isListUsersPending ? (
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                ) : (
                  <Person />
                )
              }
            ></CardHeader>
            <CardContent>
              <Grid container justifyContent="flex-start" direction={"column"}>
                <KeyValuePair
                  Key={"Email"}
                  Value={user?.Email}
                  Editable={true}
                  Loading={isListUsersPending || isFetchUserPending}
                  OnSubmit={async (Email) => {
                    await updateUser({ ...user!!, Email });
                  }}
                />
                <KeyValuePair
                  Key={"FirstName"}
                  Value={user?.FirstName}
                  Editable={true}
                  Loading={isListUsersPending || isFetchUserPending}
                  OnSubmit={async (FirstName) => {
                    await updateUser({ ...user!!, FirstName });
                  }}
                />
                <KeyValuePair
                  Key={"LastName"}
                  Value={user?.LastName}
                  Editable={true}
                  Loading={isListUsersPending || isFetchUserPending}
                  OnSubmit={async (LastName) => {
                    await updateUser({ ...user!!, LastName });
                  }}
                />
                <KeyValuePair
                  Key={"Status"}
                  Value={Status}
                  Editable={false}
                  Loading={isListUsersPending || isFetchUserPending}
                />
              </Grid>
            </CardContent>
            <CardActions>
              {!user?.Cognito && (
                <LoadingButton
                  loading={isListUsersPending || isFetchUserPending}
                  loadingPosition="start"
                  onClick={async () => {
                    await inviteUser(user);
                  }}
                  variant="contained"
                  fullWidth
                  startIcon={<Mail />}
                >
                  {t("Send invitation")}
                </LoadingButton>
              )}
            </CardActions>
            <CardActions>
              <LoadingButton
                loading={isListUsersPending || isFetchUserPending}
                loadingPosition="start"
                onClick={() => deleteUser(user)}
                color="error"
                variant="outlined"
                fullWidth
                startIcon={<Delete />}
              >
                {t("Delete user")}
              </LoadingButton>
            </CardActions>
          </Card>
        </Grid>
        <Grid xs={12} sm={8} md={8}>
          <Card>
            <CardHeader
              title={t("Security settings")}
              avatar={<Security />}
            ></CardHeader>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"column"}
              ></Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export function Users() {
  const { t } = useTranslation();
  const { users, createUser, isListUsersPending } = useBenefitDxContext();
  const localeText = useTableLocaleText();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);

  const columns: GridColDef[] = [
    {
      field: "Email",
      headerName: t("Email") as string,
      flex: 1,
    },
    {
      field: "FirstName",
      headerName: t("First name") as string,
      flex: 1,
    },
    {
      field: "LastName",
      headerName: t("Last name") as string,
      flex: 1,
    },
  ];

  const formProps: FormProps = {
    submitText: "Save",
    properties: [
      {
        name: "Email",
        label: "Email",
        required: true,
        type: "text",
        initialValue: "",
      },
      {
        name: "FirstName",
        label: "FirstName",
        required: true,
        type: "text",
        initialValue: "",
      },
      {
        name: "LastName",
        label: "LastName",
        required: true,
        type: "text",
        initialValue: "",
      },
      {
        name: "AccessLevel",
        label: "Access Level",
        required: true,
        type: "select",
        initialValue: AccessLevel.Department,
        options: [
          {
            label: "Department",
            value: AccessLevel.Department,
          },
          {
            label: "Hospital",
            value: AccessLevel.Hospital,
          },
          {
            label: "Account",
            value: AccessLevel.Account,
          },
          {
            label: "Advisor",
            value: AccessLevel.Advisor,
          },
          {
            label: "Admin",
            value: AccessLevel.Admin,
          },
          {
            label: "Developer",
            value: AccessLevel.Developer,
          },
          {
            label: "God",
            value: AccessLevel.God,
          },
        ].filter((option) => option.value < 3),
      },
    ],
    onSubmit: async (values: any) => {
      const { submit, AccessLevel: Level, ...userData } = values;

      userData.Access = {
        Level,
      };
      await createUser(userData);
      setDialogOpen(false);
    },
  };

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<Add />}
          size="large"
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          {t("Add new")}
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <Paper sx={{ width: "100%", marginTop: 5 }}>
      <Helmet title={t("Users") as string} />
      <Grid container direction={"column"}>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <DataGridPro
              loading={isListUsersPending}
              disableMultipleRowSelection={true}
              slots={{ loadingOverlay: LinearProgress, toolbar: EditToolbar }}
              autoHeight
              getRowId={(row) => row.Id}
              rows={users}
              columns={columns}
              sx={{
                width: "100%",
                [`& .${gridClasses.row}`]: { cursor: "pointer" },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: "none",
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
              }}
              hideFooter
              getDetailPanelContent={({ row }) => <UserDetails Id={row.Id} />}
              getDetailPanelHeight={() => "auto"}
              detailPanelExpandedRowIds={selected}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                if (selected[0] === newRowSelectionModel[0]) {
                  setSelected([]);
                } else {
                  setSelected(newRowSelectionModel as string[]);
                }
              }}
              localeText={localeText}
            />
          </CardContent>
        </Card>
        <Dialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
        >
          <DialogTitle>{t("New user")}</DialogTitle>
          <DialogContent>
            <BenefitDxForm {...formProps} />
          </DialogContent>
        </Dialog>
      </Grid>
    </Paper>
  );
}

export default Users;
