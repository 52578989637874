import axios, { AxiosResponse } from "axios";

export class HttpError extends Error {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    this.name = "HttpError";
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export const axiosInstance = axios.create({
  baseURL: "https://api.benefitdx.com",
});

function httpErrorHandler(error: any) {
  console.log("AXIOS_ERROR", error);
  if (error === null) throw new Error("Unrecoverable error!! Error is null!");
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response;
    const request = error?.request;
    const config = error?.config; //here we have access the config used to make the api call (we can make a retry using this conf)

    if (error.code === "ERR_NETWORK") {
      console.log("connection problems..");
    } else if (error.code === "ERR_CANCELED") {
      console.log("connection canceled..");
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status;
      if (statusCode === 404) {
        console.log(
          "The requested resource does not exist or has been deleted",
        );
      } else if (statusCode === 401) {
        console.log("Please login to access this resource");
        //redirect user to login
        window.location.href = "/login";
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
    }
  }
  //Something happened in setting up the request and triggered an Error
  console.log(error.message);
}

// this interceptor is used to handle all success ajax request
// we use this to check if status code is 200 (success), if not, we throw an HttpError
// to our error handler take place.
function responseHandler(response: AxiosResponse<any>) {
  const config = response?.config;
  if (response.status == 200) {
    return response;
  }
  throw new HttpError("API Error! Invalid status code!");
}

function responseErrorHandler(response: AxiosResponse<any>) {
  const config = response?.config;
  // the code of this function was written in above section.
  return httpErrorHandler(response);
}

axiosInstance.interceptors.response.use(responseHandler, responseErrorHandler);

export default axiosInstance;
