import axios from "../utils/axios";
import {
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from "@tanstack/react-query";
import useAuth from "./useAuth";
import { Procedure } from "../types";

export function useProcedures() {
  const client = useQueryClient();
  const { session } = useAuth();

  const {
    error: listProceduresError,
    data: procedures,
    isFetching: isListProceduresFetching,
    fetchNextPage: listProceduresNextPage,
  } = useInfiniteQuery({
    initialPageParam: undefined,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage: any) => {
      console.log("lastPage", lastPage);
      return lastPage.NextToken;
    },
    enabled: session?.isValid(),
    queryKey: ["procedures"],
    queryFn: async ({ pageParam }) =>
      (
        await axios.post(`/procedures`, {
          NextToken: pageParam,
          Limit: 10,
        })
      ).data,
  });

  const {
    mutate: createProcedure,
    isPending: isCreateProcedurePending,
    error: createProcedureError,
  } = useMutation({
    mutationFn: async (payload: Partial<Procedure>) =>
      await axios.post(`/procedures/new`, {
        ...payload,
      }),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["procedures"],
      });
    },
  });

  const {
    mutate: updateProcedure,
    isPending: isUpdateProcedurePending,
    error: updateProcedureError,
  } = useMutation({
    mutationFn: async (updatedProcedure: Procedure) =>
      await axios.put(`/procedures/${updatedProcedure?.Id}`, updatedProcedure),
    onSuccess: (_data, variables, _context) => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["procedures", variables?.Id],
      });
    },
  });

  const {
    mutate: deleteProcedure,
    isPending: isDeleteProcedurePending,
    error: deleteProcedureError,
  } = useMutation({
    mutationFn: async (Id: string) => await axios.delete(`/procedures/${Id}`),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["procedures"],
      });
    },
  });

  return {
    listProceduresError,
    createProcedureError,
    updateProcedureError,
    deleteProcedureError,
    listProceduresNextPage,
    isListProceduresPending: isListProceduresFetching,
    isCreateProcedurePending,
    isUpdateProcedurePending,
    isDeleteProcedurePending,
    procedures: procedures?.pages.flatMap((page) => page.Items) || [],
    createProcedure,
    updateProcedure,
    deleteProcedure,
  };
}
