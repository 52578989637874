import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";
import departmentTypes from "../../../config/department-types";

import {
  AccountingSoftware,
  ManagementSoftware,
  DepartmentClass,
  HospitalType,
  City,
} from "../../../types";

import CRUDTable from "../../../components/Table";

export function Hospitals() {
  const { t } = useTranslation();
  const {
    hospitals,
    createHospital,
    updateHospital,
    deleteHospital,
    isListHospitalsPending,
    isCreateHospitalPending,
    isUpdateHospitalPending,
    isDeleteHospitalPending,
  } = useBenefitDxContext();

  return (
    <Paper sx={{ width: "100%", marginTop: 5 }}>
      <Helmet title={t("Hospitals") as string} />
      <Grid container direction={"column"}>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <CRUDTable
              entity={{
                Type: "hospital",
                Fields: [
                  {
                    Name: "Name",
                    Label: "Name",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                  {
                    Name: "Branch",
                    Label: "Branch",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                  {
                    Name: "Type",
                    Label: "Hospital Type",
                    Required: true,
                    Editable: true,
                    Type: "select",
                    InitialValue: HospitalType.Private,
                    Options: Object.keys(HospitalType).map((item) => ({
                      Label: t(item).charAt(0) + t(item).slice(1).toLowerCase(),
                      Value: item,
                    })),
                  },
                  {
                    Name: "City",
                    Label: "City",
                    Required: true,
                    Editable: true,
                    Type: "select",
                    InitialValue: Object.values(City)[0],
                    Options: Object.keys(City).map((item) => ({
                      Label: item,
                      Value: item,
                    })),
                  },
                  {
                    Name: "ManagementSoftware",
                    Label: "Management Software",
                    Required: true,
                    Editable: true,
                    Type: "select",
                    InitialValue: ManagementSoftware.Pusula,
                    Options: Object.keys(ManagementSoftware).map(
                      (software) => ({
                        Label: software,
                        Value: software,
                      }),
                    ),
                  },
                  {
                    Name: "AccountingSoftware",
                    Label: "Accounting Software",
                    Required: true,
                    Editable: true,
                    Type: "select",
                    InitialValue: AccountingSoftware.Logo,
                    Options: Object.keys(AccountingSoftware).map(
                      (software) => ({
                        Label: software,
                        Value: software,
                      }),
                    ),
                  },
                  {
                    Name: "Departments",
                    Label: "Income and Auxiliary Income Departments",
                    Type: "multiselect",
                    Required: false,
                    Editable: false,
                    InitialValue: [],
                    Options: departmentTypes
                      .filter(
                        (departmentType) =>
                          departmentType.Class === DepartmentClass.Income ||
                          departmentType.Class ===
                            DepartmentClass.AuxiliaryIncome,
                      )
                      .map((departmentType, i) => ({
                        Value: departmentType.Code,
                        Label: departmentType.Name,
                        Icon: departmentType.Icon,
                      })),
                  },
                ],
              }}
              loading={
                isListHospitalsPending ||
                isUpdateHospitalPending ||
                isDeleteHospitalPending ||
                isCreateHospitalPending
              }
              rows={hospitals}
              generateUrl={(id: GridRowId) => {
                return `/hospitals/${id}`;
              }}
              DetailsView={null}
              create={createHospital}
              update={updateHospital}
              delete={deleteHospital}
            />
          </CardContent>
        </Card>
      </Grid>
    </Paper>
  );
}

export default Hospitals;
