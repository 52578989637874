import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  OutlinedInput,
  Stack,
  Chip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { OutlinedFlag, Save, Cancel, Check } from "@mui/icons-material";
import { HTMLInputTypeAttribute } from "react";
import departmentTypes from "../config/department-types";

export type FormProps = {
  properties: FormValue[];
  direction?: "horizontal" | "vertical";
  submitText?: string;
  onSubmit: (values: any) => Promise<any>;
};

export type Option = {
  label: string;
  value: string | number;
  icon?: string;
};

export type FormValue = {
  name: string;
  label: string;
  testId?: string;
  required: boolean;
  type: HTMLInputTypeAttribute;
  multiple?: boolean;
  initialValue: any;
  options?: Option[];
};

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export function BenefitDxForm(props: FormProps) {
  const { t } = useTranslation();
  const submitText = props.submitText || "Submit";
  const initialValues = props.properties.reduce(
    (prev, next) => ({ ...prev, [next.name]: next.initialValue || "" }),
    {},
  );

  const validationSchema = props.properties.reduce((prev, next) => {
    const current = next;
    let validation = Yup.string();
    validation = current.required
      ? validation.required(t(`${current.label} is required`) as string)
      : validation;
    return { ...prev, [next.name]: validation };
  }, {});

  return (
    <Formik
      initialValues={{ ...initialValues, submit: false }}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        submitForm,
        isSubmitting,
        touched,
        values,
      }: any) => {
        const getFieldComponent = (val: FormValue) => {
          switch (val.type) {
            case "select":
              return (
                <FormControl
                  fullWidth
                  key={val.name}
                  margin="normal"
                  sx={{ marginY: 3 }}
                >
                  <InputLabel id={`${val.name}-input-label`}>
                    {t(val.label)}
                  </InputLabel>
                  <Select
                    labelId={`${val.name}-input-label`}
                    inputProps={{ "data-testid": val.testId }}
                    name={val.name}
                    value={values[val.name]}
                    label={val.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {val?.options?.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            case "checkbox":
              return (
                <FormControl key={val.name} margin="normal" sx={{ marginY: 3 }}>
                  <FormControlLabel
                    key={val.name}
                    label={t(val.label)}
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": val.label }}
                        name={val.name}
                        value={values[val.name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                  />
                </FormControl>
              );
            case "multiselect":
              return (
                <FormControl
                  fullWidth
                  key={val.name}
                  margin="normal"
                  sx={{ marginY: 3 }}
                >
                  <InputLabel id={`${val.name}-input-label`}>
                    {t(val.label)}
                  </InputLabel>
                  <Select
                    multiple
                    labelId={`${val.name}-input-label`}
                    input={<OutlinedInput label={val.label} />}
                    name={val.name}
                    value={values[val.name]}
                    label={val.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map((value: any) => (
                          <Chip
                            key={value}
                            icon={
                              <img
                                alt="icon"
                                style={{ width: 16, height: 16 }}
                                src={`/static/img/flaticon/${
                                  departmentTypes.find((v) => v.Code === value)
                                    ?.Icon
                                }.svg`}
                              />
                            }
                            label={t(
                              departmentTypes.find((v) => v.Code === value)
                                ?.Name || "",
                            )}
                            onDelete={() =>
                              handleChange(
                                values[val.name].filter(
                                  (item: any) => item !== value,
                                ),
                              )
                            }
                            deleteIcon={
                              <Cancel
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  >
                    {val?.options?.map(({ value, label, icon }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        sx={{ justifyContent: "space-between" }}
                      >
                        {icon ? (
                          <img
                            style={{ width: 32, height: 32 }}
                            src={`/static/img/flaticon/${icon}.svg`}
                            alt={t(label) as string}
                          />
                        ) : (
                          <div style={{ width: 32, height: 32 }}></div>
                        )}
                        {t(label)}
                        {values[val.name].includes(value) ? (
                          <Check color="info" />
                        ) : (
                          <div style={{ width: 32, height: 32 }}></div>
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );

            default:
              return (
                <TextField
                  type={val.type}
                  key={val.name}
                  name={val.name}
                  label={t(val.label)}
                  value={values[val.name]}
                  inputProps={{ "data-testid": val.testId }}
                  error={Boolean(touched[val.name] && errors[val.name])}
                  fullWidth
                  helperText={touched[val.name] && errors[val.name]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              );
          }
        };

        return (
          <Form>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {props.properties.map((val) => getFieldComponent(val))}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              sx={{ marginY: 3 }}
              startIcon={<Save />}
            >
              {t(submitText)}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

export default BenefitDxForm;
