import { useState } from "react";
import axios from "../utils/axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { Upload, Mapping } from "../types";

export function useUploads(mapping: Mapping | undefined) {
  const client = useQueryClient();
  const { session } = useAuth();

  const create = async (payload: Partial<Upload>) =>
    await axios.post(`/uploads/new`, {
      HospitalId: mapping?.HospitalId,
      MappingId: mapping?.Id,
      ...payload,
    });

  const {
    mutate: createUpload,
    isPending: isCreateUploadPending,
    error: createUploadError,
    data: created,
    reset: resetCreateUpload,
  } = useMutation({
    mutationFn: create,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["mappings", mapping?.Id, "uploads"],
      });
    },
  });
  const {
    mutate: deleteUpload,
    isPending: isDeleteUploadPending,
    error: deleteUploadError,
  } = useMutation({
    mutationFn: async (UploadId: string) =>
      await axios.delete(`/uploads/${UploadId}`),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["mappings", mapping?.Id, "uploads"],
      });
    },
  });

  const {
    error: listUploadsByMappingError,
    data: uploads,
    isPending: isListUploadsByMappingPending,
  } = useQuery({
    enabled: Boolean(mapping?.Id) && session?.isValid(),
    // refetchInterval: 10000,
    queryKey: ["mappings", mapping?.Id, "uploads"],
    queryFn: async () =>
      (await axios.post(`/mappings/${mapping?.Id}/uploads`)).data.Items,
  });

  const update = async (payload: Partial<Upload>) =>
    await axios.put(`/uploads/${payload.Id}`, {
      MappingId: mapping?.Id,
      ...payload,
    });

  const {
    mutate: updateUpload,
    isPending: isUpdateUploadPending,
    error: updateUploadError,
  } = useMutation({
    mutationFn: update,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["mappings", mapping?.Id, "uploads"],
      });
    },
  });

  const generateDownload = async (UploadId: string) =>
    await axios.post(`/uploads/${UploadId}/generate-errors-download`, {});

  const {
    mutate: generateErrorsDownload,
    isPending: isGenerateErrorsDownloadPending,
    error: generateErrorsDownloadError,
  } = useMutation({
    mutationFn: generateDownload,
  });

  const downloadErrorsFileLink = async (UploadId: string) => {
    const result = await axios.post(
      `/uploads/${UploadId}/download-errors-file`,
      {},
    );

    console.log("YOYOYOYOYOYOYOYOYOYOYOYOYOYOYOYO", result.data);

    return result.data;
  };

  const {
    data: downloadErrorsFileData,
    mutate: downloadErrorsFile,
    isPending: isDownloadErrorsFilePending,
    error: downloadErrorsFileError,
  } = useMutation({
    mutationFn: downloadErrorsFileLink,
  });

  return {
    uploads: uploads || [],
    createUpload,
    isCreateUploadPending,
    createUploadError,
    deleteUpload,
    isDeleteUploadPending,
    deleteUploadError,
    updateUpload,
    isUpdateUploadPending,
    updateUploadError,
    listUploadsByMappingError,
    isListUploadsByMappingPending,
    resetCreateUpload,
    createdUpload: created?.data,
    downloadErrorsFile,
    isDownloadErrorsFilePending,
    downloadErrorsFileError,
    downloadErrorsFileData,
    generateErrorsDownload,
    isGenerateErrorsDownloadPending,
    generateErrorsDownloadError,
  };
}
