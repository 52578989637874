export const ledger = {
  "600.01.001": {
    CategoryCode: "600",
    CategoryName: "Domestic Sales",
    SubCategoryCode: "600.01",
    SubCategroyName: "Operating Income",
    LedgerCode: "600.01.001",
    LedgerName: "Health Service Revenues",
  },
  "600.01.002": {
    CategoryCode: "600",
    CategoryName: "Domestic Sales",
    SubCategoryCode: "600.01",
    SubCategroyName: "Operating Income",
    LedgerCode: "600.01.002",
    LedgerName: "Health Service Revenues Export",
  },
  "600.01.003": {
    CategoryCode: "600",
    CategoryName: "Domestic Sales",
    SubCategoryCode: "600.01",
    SubCategroyName: "Operating Income",
    LedgerCode: "600.01.003",
    LedgerName: "Foreign Exchange Differences",
  },
  "600.01.004": {
    CategoryCode: "600",
    CategoryName: "Domestic Sales",
    SubCategoryCode: "600.01",
    SubCategroyName: "Operating Income",
    LedgerCode: "600.01.004",
    LedgerName: "TPN Service Revenue",
  },
  "600.01.005": {
    CategoryCode: "600",
    CategoryName: "Domestic Sales",
    SubCategoryCode: "600.01",
    SubCategroyName: "Operating Income",
    LedgerCode: "600.01.005",
    LedgerName: "Other Service Revenues",
  },
  "600.02.001": {
    CategoryCode: "600",
    CategoryName: "Domestic Sales",
    SubCategoryCode: "600.02",
    SubCategroyName: "Other Operating Income",
    LedgerCode: "600.02.001",
    LedgerName: "Valet Revenues",
  },
  "600.02.002": {
    CategoryCode: "600",
    CategoryName: "Domestic Sales",
    SubCategoryCode: "600.02",
    SubCategroyName: "Other Operating Income",
    LedgerCode: "600.02.002",
    LedgerName: "Rental Income",
  },
  "600.02.003": {
    CategoryCode: "600",
    CategoryName: "Domestic Sales",
    SubCategoryCode: "600.02",
    SubCategroyName: "Other Operating Income",
    LedgerCode: "600.02.003",
    LedgerName: "Other Service Revenues",
  },
  "601.01.001": {
    CategoryCode: "601",
    CategoryName: "Export Sales",
    SubCategoryCode: "601.01",
    SubCategroyName: "Export Sales",
    LedgerCode: "601.01.001",
    LedgerName: "Export Sales",
  },
  "602.01.001": {
    CategoryCode: "602",
    CategoryName: "Other Sales",
    SubCategoryCode: "602.01",
    SubCategroyName: "Other Sales",
    LedgerCode: "602.01.001",
    LedgerName: "Price Difference Income",
  },
  "602.01.002": {
    CategoryCode: "602",
    CategoryName: "Other Sales",
    SubCategoryCode: "602.01",
    SubCategroyName: "Other Sales",
    LedgerCode: "602.01.002",
    LedgerName: "Other Sales",
  },
  "602.02.001": {
    CategoryCode: "602",
    CategoryName: "Other Sales",
    SubCategoryCode: "602.02",
    SubCategroyName: "Incentive Revenues",
    LedgerCode: "602.02.001",
    LedgerName: "Social Security Incentive Income",
  },
  "602.02.002": {
    CategoryCode: "602",
    CategoryName: "Other Sales",
    SubCategoryCode: "602.02",
    SubCategroyName: "Incentive Revenues",
    LedgerCode: "602.02.002",
    LedgerName: "Income Tax Incentive Income",
  },
  "602.02.003": {
    CategoryCode: "602",
    CategoryName: "Other Sales",
    SubCategoryCode: "602.02",
    SubCategroyName: "Incentive Revenues",
    LedgerCode: "602.02.003",
    LedgerName: "Foreign Incentive Income",
  },
  "602.02.004": {
    CategoryCode: "602",
    CategoryName: "Other Sales",
    SubCategoryCode: "602.02",
    SubCategroyName: "Incentive Revenues",
    LedgerCode: "602.02.004",
    LedgerName: "Other Incentive Revenues",
  },
  "610.01.001": {
    CategoryCode: "610",
    CategoryName: "Sales Returns (-)",
    SubCategoryCode: "610.01",
    SubCategroyName: "Sales Returns",
    LedgerCode: "610.01.001",
    LedgerName: "Paid Patient Refunds",
  },
  "610.01.002": {
    CategoryCode: "610",
    CategoryName: "Sales Returns (-)",
    SubCategoryCode: "610.01",
    SubCategroyName: "Sales Returns",
    LedgerCode: "610.01.002",
    LedgerName: "Social Security Institution Refunds",
  },
  "610.01.003": {
    CategoryCode: "610",
    CategoryName: "Sales Returns (-)",
    SubCategoryCode: "610.01",
    SubCategroyName: "Sales Returns",
    LedgerCode: "610.01.003",
    LedgerName: "Private Health Insurance Refunds",
  },
  "610.01.004": {
    CategoryCode: "610",
    CategoryName: "Sales Returns (-)",
    SubCategoryCode: "610.01",
    SubCategroyName: "Sales Returns",
    LedgerCode: "610.01.004",
    LedgerName: "Complementary Health Insurance Refunds",
  },
  "610.01.005": {
    CategoryCode: "610",
    CategoryName: "Sales Returns (-)",
    SubCategoryCode: "610.01",
    SubCategroyName: "Sales Returns",
    LedgerCode: "610.01.005",
    LedgerName: "Contracted Institution Refunds",
  },
  "610.01.006": {
    CategoryCode: "610",
    CategoryName: "Sales Returns (-)",
    SubCategoryCode: "610.01",
    SubCategroyName: "Sales Returns",
    LedgerCode: "610.01.006",
    LedgerName: "TPN Refunds",
  },
  "610.01.007": {
    CategoryCode: "610",
    CategoryName: "Sales Returns (-)",
    SubCategoryCode: "610.01",
    SubCategroyName: "Sales Returns",
    LedgerCode: "610.01.007",
    LedgerName: "Foreign Paid Patient Refunds",
  },
  "611.01.001": {
    CategoryCode: "611",
    CategoryName: "Sales Discounts (-)",
    SubCategoryCode: "611.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "611.01.001",
    LedgerName: "Paid Patient Sales Discounts",
  },
  "611.01.002": {
    CategoryCode: "611",
    CategoryName: "Sales Discounts (-)",
    SubCategoryCode: "611.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "611.01.002",
    LedgerName: "Social Security Institution Patients Sales Discounts",
  },
  "611.01.003": {
    CategoryCode: "611",
    CategoryName: "Sales Discounts (-)",
    SubCategoryCode: "611.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "611.01.003",
    LedgerName: "Private Health Insurance Patients Sales Discounts",
  },
  "611.01.004": {
    CategoryCode: "611",
    CategoryName: "Sales Discounts (-)",
    SubCategoryCode: "611.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "611.01.004",
    LedgerName: "Complementary Health Insurance Patients Sales Discounts",
  },
  "611.01.005": {
    CategoryCode: "611",
    CategoryName: "Sales Discounts (-)",
    SubCategoryCode: "611.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "611.01.005",
    LedgerName: "Sales Discounts for Contracted Institution Patients",
  },
  "611.01.006": {
    CategoryCode: "611",
    CategoryName: "Sales Discounts (-)",
    SubCategoryCode: "611.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "611.01.006",
    LedgerName: "TPN Patients Sales Discounts",
  },
  "611.01.007": {
    CategoryCode: "611",
    CategoryName: "Sales Discounts (-)",
    SubCategoryCode: "611.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "611.01.007",
    LedgerName: "Sales Discounts for Foreign Chargeable Patients",
  },
  "611.01.008": {
    CategoryCode: "611",
    CategoryName: "Sales Discounts (-)",
    SubCategoryCode: "611.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "611.01.008",
    LedgerName: "Other Sales Discounts",
  },
  "612.01.001": {
    CategoryCode: "612",
    CategoryName: "Other Discounts (-)",
    SubCategoryCode: "612.01",
    SubCategroyName: "Sales Discounts",
    LedgerCode: "612.01.001",
    LedgerName: "Other Discounts",
  },
  "620.01.001": {
    CategoryCode: "620",
    CategoryName: "Cost Of Goods Sold (-)",
    SubCategoryCode: "620.01",
    SubCategroyName: "Cost Of Goods Sold",
    LedgerCode: "620.01.001",
    LedgerName: "Cost Of Goods Sold",
  },
  "621.01.001": {
    CategoryCode: "621",
    CategoryName: "Cost Of Trade Goods Sold (-)",
    SubCategoryCode: "621.01",
    SubCategroyName: "Cost Of Trade Goods Sold",
    LedgerCode: "621.01.001",
    LedgerName: "Cost Of Trade Goods Sold",
  },
  "622.01.001": {
    CategoryCode: "622",
    CategoryName: "Cost Of Services Sold (-)",
    SubCategoryCode: "622.01",
    SubCategroyName: "Cost Of Services Sold",
    LedgerCode: "622.01.001",
    LedgerName: "Cost Of Services Sold",
  },
  "623.01.001": {
    CategoryCode: "623",
    CategoryName: "Cost Of Other Sales (-)",
    SubCategoryCode: "623.01",
    SubCategroyName: "Cost Of Other Sales",
    LedgerCode: "623.01.001",
    LedgerName: "Cost Of Other Sales",
  },
  "630.01.001": {
    CategoryCode: "630",
    CategoryName: "Research And Development Expenses (-)",
    SubCategoryCode: "630.01",
    SubCategroyName: "Research And Development Expenses",
    LedgerCode: "630.01.001",
    LedgerName: "Research And Development Expenses",
  },
  "631.01.001": {
    CategoryCode: "631",
    CategoryName: "Marketıng, Selling And Distribution Expenses",
    SubCategoryCode: "631.01",
    SubCategroyName: "Marketıng, Selling And Distribution Expenses",
    LedgerCode: "631.01.001",
    LedgerName: "Marketıng, Selling And Distribution Expenses",
  },
  "632.01.001": {
    CategoryCode: "632",
    CategoryName: "General Administrative Expenses",
    SubCategoryCode: "632.01",
    SubCategroyName: "General Administrative Expenses",
    LedgerCode: "632.01.001",
    LedgerName: "General Administrative Expenses",
  },
  "640.01.001": {
    CategoryCode: "640",
    CategoryName: "Dividend Income From Participations",
    SubCategoryCode: "640.01",
    SubCategroyName: "Dividend Income From Participations",
    LedgerCode: "640.01.001",
    LedgerName: "Dividend Income From Participations",
  },
  "641.01.001": {
    CategoryCode: "641",
    CategoryName: "Dividend Income From Subsidiaries",
    SubCategoryCode: "641.01",
    SubCategroyName: "Dividend Income From Subsidiaries",
    LedgerCode: "641.01.001",
    LedgerName: "Dividend Income From Subsidiaries",
  },
  "642.01.001": {
    CategoryCode: "642",
    CategoryName: "Interest Income",
    SubCategoryCode: "642.01",
    SubCategroyName: "Interest Income",
    LedgerCode: "642.01.001",
    LedgerName: "Interest Income",
  },
  "643.01.001": {
    CategoryCode: "643",
    CategoryName: "Commission Income",
    SubCategoryCode: "643.01",
    SubCategroyName: "Commission Income",
    LedgerCode: "643.01.001",
    LedgerName: "Commission Income",
  },
  "644.01.001": {
    CategoryCode: "644",
    CategoryName: "Provisions No Longer Required",
    SubCategoryCode: "644.01",
    SubCategroyName: "Provisions No Longer Required",
    LedgerCode: "644.01.001",
    LedgerName: "Provision Income for Doubtful Trade Receivables",
  },
  "645.01.001": {
    CategoryCode: "645",
    CategoryName: "Gains On Marketable Securities' Sales",
    SubCategoryCode: "645.01",
    SubCategroyName: "Gains On Marketable Securities' Sales",
    LedgerCode: "645.01.001",
    LedgerName: "Gains On Marketable Securities' Sales",
  },
  "646.01.001": {
    CategoryCode: "646",
    CategoryName: "Foreign Exchange Gain",
    SubCategoryCode: "646.01",
    SubCategroyName: "Foreign Exchange Gain",
    LedgerCode: "646.01.001",
    LedgerName: "Foreign Exchange Gain",
  },
  "647.01.001": {
    CategoryCode: "647",
    CategoryName: "Rediscount On Interest Gain",
    SubCategoryCode: "647.01",
    SubCategroyName: "Rediscount On Interest Gain",
    LedgerCode: "647.01.001",
    LedgerName: "Rediscount On Interest Gain",
  },
  "648.01.001": {
    CategoryCode: "648",
    CategoryName: "Gains from Inflation Adjustments",
    SubCategoryCode: "648.01",
    SubCategroyName: "Gains from Inflation Adjustments",
    LedgerCode: "648.01.001",
    LedgerName: "Gains from Inflation Adjustments",
  },
  "649.01.001": {
    CategoryCode: "649",
    CategoryName: "Other Income And Profit From Operations",
    SubCategoryCode: "649.01",
    SubCategroyName: "Gain on Sale of Fixed Assets",
    LedgerCode: "649.01.001",
    LedgerName: "Gain on Sale of Fixed Assets",
  },
  "649.02.001": {
    CategoryCode: "649",
    CategoryName: "Other Income And Profit From Operations",
    SubCategoryCode: "649.02",
    SubCategroyName: "Other Operating Income and Profit",
    LedgerCode: "649.02.001",
    LedgerName: "Other Operating Income and Profit",
  },
  "649.03.001": {
    CategoryCode: "649",
    CategoryName: "Other Income And Profit From Operations",
    SubCategoryCode: "649.03",
    SubCategroyName: "Other Non-Operating Income and Profits",
    LedgerCode: "649.03.001",
    LedgerName: "Other Non-Operating Income and Profits",
  },
  "653.01.001": {
    CategoryCode: "653",
    CategoryName: "Commission Expens (-)",
    SubCategoryCode: "653.01",
    SubCategroyName: "Commission Expens",
    LedgerCode: "653.01.001",
    LedgerName: "Commission Expens",
  },
  "654.01.001": {
    CategoryCode: "654",
    CategoryName: "Provision Expens (-)",
    SubCategoryCode: "654.01",
    SubCategroyName: "Provision Expens",
    LedgerCode: "654.01.001",
    LedgerName: "Provision for Doubtful Trade Receivables",
  },
  "654.02.001": {
    CategoryCode: "654",
    CategoryName: "Provision Expens (-)",
    SubCategoryCode: "654.02",
    SubCategroyName: "Other Operating Expenses",
    LedgerCode: "654.02.001",
    LedgerName: "Other Operating Expenses",
  },
  "655.01.001": {
    CategoryCode: "655",
    CategoryName: "Losses On Marketable Securities' Sales (-)",
    SubCategoryCode: "655.01",
    SubCategroyName: "Losses On Marketable Securities' Sales",
    LedgerCode: "655.01.001",
    LedgerName: "Losses On Marketable Securities' Sales",
  },
  "656.01.002": {
    CategoryCode: "656",
    CategoryName: "Foreign Exchange Losses (-)",
    SubCategoryCode: "656.01",
    SubCategroyName: "Foreign Exchange Losses",
    LedgerCode: "656.01.002",
    LedgerName: "Foreign Exchange Losses",
  },
  "657.01.001": {
    CategoryCode: "657",
    CategoryName: "Interest Expenses On Discounted Notes (-)",
    SubCategoryCode: "657.01",
    SubCategroyName: "Interest Expenses On Discounted Notes",
    LedgerCode: "657.01.001",
    LedgerName: "Interest Expenses On Discounted Notes",
  },
  "658.01.001": {
    CategoryCode: "658",
    CategoryName: "Loss From Inflation Adjustments (-)",
    SubCategoryCode: "658.01",
    SubCategroyName: "Loss From Inflation Adjustments",
    LedgerCode: "658.01.001",
    LedgerName: "Loss From Inflation Adjustments",
  },
  "659.01.001": {
    CategoryCode: "659",
    CategoryName: "Other Expenses And Losses (-)",
    SubCategoryCode: "659.01",
    SubCategroyName: "Losses on Sale of Fixed Assets",
    LedgerCode: "659.01.001",
    LedgerName: "Losses on Sale of Fixed Assets",
  },
  "659.02.001": {
    CategoryCode: "659",
    CategoryName: "Other Expenses And Losses (-)",
    SubCategoryCode: "659.02",
    SubCategroyName: "Other Operating Income and Losses",
    LedgerCode: "659.02.001",
    LedgerName: "Other Operating Income and Losses",
  },
  "659.03.001": {
    CategoryCode: "659",
    CategoryName: "Other Expenses And Losses (-)",
    SubCategoryCode: "659.03",
    SubCategroyName: "Other Non-Operating Income and Losses",
    LedgerCode: "659.03.001",
    LedgerName: "Other Non-Operating Income and Losses",
  },
  "660.01.001": {
    CategoryCode: "660",
    CategoryName: "Short Term Borrowing Expenses (-)",
    SubCategoryCode: "660.01",
    SubCategroyName: "Short Term Borrowing Expense",
    LedgerCode: "660.01.001",
    LedgerName: "Short Term Borrowing Expense",
  },
  "661.01.001": {
    CategoryCode: "661",
    CategoryName: "Long Term Borrowing Expenses (-)",
    SubCategoryCode: "661.01",
    SubCategroyName: "Long Term Borrowing Expenses",
    LedgerCode: "661.01.001",
    LedgerName: "Long Term Borrowing Expenses",
  },
  "671.01.001": {
    CategoryCode: "671",
    CategoryName: "Income And Profit Relating to Previous Periods",
    SubCategoryCode: "671.01",
    SubCategroyName: "Income And Profit Relating to Previous Periods",
    LedgerCode: "671.01.001",
    LedgerName: "Income And Profit Relating to Previous Periods",
  },
  "679.01.001": {
    CategoryCode: "679",
    CategoryName: "Other Extraordinary Expenses and Losses (-)",
    SubCategoryCode: "679.01",
    SubCategroyName: "Other Extraordinary Expenses and Losses",
    LedgerCode: "679.01.001",
    LedgerName: "Other Extraordinary Expenses and Losses",
  },
  "679.01.002": {
    CategoryCode: "679",
    CategoryName: "Other Extraordinary Expenses and Losses (-)",
    SubCategoryCode: "679.01",
    SubCategroyName: "Other Extraordinary Expenses and Losses",
    LedgerCode: "679.01.002",
    LedgerName: "Rounding Income",
  },
  "680.01.001": {
    CategoryCode: "680",
    CategoryName: "Idle Capacity Expenses and Losses (-)",
    SubCategoryCode: "680.01",
    SubCategroyName: "Idle Capacity Expenses and Losses",
    LedgerCode: "680.01.001",
    LedgerName: "Idle Capacity Expenses and Losses",
  },
  "681.01.001": {
    CategoryCode: "681",
    CategoryName: "Previous Period Expenses and Losses (-)",
    SubCategoryCode: "681.01",
    SubCategroyName: "Prior Period Sales Cancellations",
    LedgerCode: "681.01.001",
    LedgerName: "Prior Period Sales Cancellations",
  },
  "681.02.001": {
    CategoryCode: "681",
    CategoryName: "Previous Period Expenses and Losses (-)",
    SubCategoryCode: "681.02",
    SubCategroyName: "Previous Period Expenses and Losses",
    LedgerCode: "681.02.001",
    LedgerName: "Previous Period Expenses and Losses",
  },
  "681.02.002": {
    CategoryCode: "681",
    CategoryName: "Previous Period Expenses and Losses (-)",
    SubCategoryCode: "681.02",
    SubCategroyName: "Previous Period Expenses and Losses",
    LedgerCode: "681.02.002",
    LedgerName: "Previous Period Expenses and Losses (KKEG)",
  },
  "689.01.001": {
    CategoryCode: "689",
    CategoryName: "Other Extraordinary Expense and Losses (-)",
    SubCategoryCode: "689.01",
    SubCategroyName: "Other Extraordinary Expense and Losses",
    LedgerCode: "689.01.001",
    LedgerName: "Other Extraordinary Expense and Losses",
  },
  "689.01.002": {
    CategoryCode: "689",
    CategoryName: "Other Extraordinary Expense and Losses (-)",
    SubCategoryCode: "689.01",
    SubCategroyName: "Other Extraordinary Expense and Losses",
    LedgerCode: "689.01.002",
    LedgerName: "Rounding Expenses",
  },
  "690.01.001": {
    CategoryCode: "690",
    CategoryName: "Profit or Loss For the Period",
    SubCategoryCode: "690.01",
    SubCategroyName: "Profit or Loss For the Period",
    LedgerCode: "690.01.001",
    LedgerName: "Profit or Loss For the Period",
  },
  "691.01.001": {
    CategoryCode: "691",
    CategoryName:
      "Provision for Period Profit, Taxes and Other Legal Liabilities",
    SubCategoryCode: "691.01",
    SubCategroyName:
      "Provision for Period Profit, Taxes and Other Legal Liabilities",
    LedgerCode: "691.01.001",
    LedgerName:
      "Provision for Period Profit, Taxes and Other Legal Liabilities",
  },
  "692.01.001": {
    CategoryCode: "692",
    CategoryName: "Net Period Profit or Loss",
    SubCategoryCode: "692.01",
    SubCategroyName: "Net Period Profit or Loss",
    LedgerCode: "692.01.001",
    LedgerName: "Net Period Profit or Loss",
  },
  "740.01.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.01",
    SubCategroyName: "Service Production Material Expenses",
    LedgerCode: "740.01.001",
    LedgerName: "Medical Consumable Expenses",
  },
  "740.01.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.01",
    SubCategroyName: "Service Production Material Expenses",
    LedgerCode: "740.01.002",
    LedgerName: "Pharmaceutical Consumable Expenses",
  },
  "740.01.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.01",
    SubCategroyName: "Service Production Material Expenses",
    LedgerCode: "740.01.003",
    LedgerName: "Special Consumable Expenses",
  },
  "740.01.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.01",
    SubCategroyName: "Service Production Material Expenses",
    LedgerCode: "740.01.004",
    LedgerName: "Dental Consumable Expenses",
  },
  "740.01.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.01",
    SubCategroyName: "Service Production Material Expenses",
    LedgerCode: "740.01.005",
    LedgerName: "Laboratory Consumable Expenses",
  },
  "740.01.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.01",
    SubCategroyName: "Service Production Material Expenses",
    LedgerCode: "740.01.006",
    LedgerName: "Imaging Consumable Expenses",
  },
  "740.01.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.01",
    SubCategroyName: "Service Production Material Expenses",
    LedgerCode: "740.01.007",
    LedgerName: "Blood Consumable Expenses",
  },
  "740.01.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.01",
    SubCategroyName: "Service Production Material Expenses",
    LedgerCode: "740.01.008",
    LedgerName: "Other Consumable Expenses",
  },
  "740.02.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.001",
    LedgerName: "Doctor Fee Expenses",
  },
  "740.02.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.002",
    LedgerName: "Doctor's Progress Expenses",
  },
  "740.02.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.003",
    LedgerName: "Home Health Doctor Expenses",
  },
  "740.02.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.004",
    LedgerName: "On-Duty Doctor Payments",
  },
  "740.02.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.005",
    LedgerName: "External Doctor Payments",
  },
  "740.02.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.006",
    LedgerName: "Doctor Working Expenses",
  },
  "740.02.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.007",
    LedgerName: "Doctor Premium and Bonus Expenses",
  },
  "740.02.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.008",
    LedgerName: "Doctor Severance Pay",
  },
  "740.02.009": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.009",
    LedgerName: "Doctor's Notice Compensation",
  },
  "740.02.010": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.010",
    LedgerName: "Severance Pay Provision",
  },
  "740.02.011": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.011",
    LedgerName: "Doctor SSK Employer Share",
  },
  "740.02.012": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.012",
    LedgerName: "Doctor SSK Unemployment Insurance Employer Share",
  },
  "740.02.013": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.013",
    LedgerName: "Doctor SSK Social Security Support Share",
  },
  "740.02.014": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.02",
    SubCategroyName: "Doctor Expenses",
    LedgerCode: "740.02.014",
    LedgerName: "Other Doctor Expense",
  },
  "740.03.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.03",
    SubCategroyName: "Doctor Other Expenses",
    LedgerCode: "740.03.001",
    LedgerName: "Doctor Transportation Expenses",
  },
  "740.03.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.03",
    SubCategroyName: "Doctor Other Expenses",
    LedgerCode: "740.03.002",
    LedgerName: "Doctor's Meal Expenses",
  },
  "740.03.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.03",
    SubCategroyName: "Doctor Other Expenses",
    LedgerCode: "740.03.003",
    LedgerName: "Doctor Clothing Expenses",
  },
  "740.03.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.03",
    SubCategroyName: "Doctor Other Expenses",
    LedgerCode: "740.03.004",
    LedgerName: "Doctor Education Expenses",
  },
  "740.03.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.03",
    SubCategroyName: "Doctor Other Expenses",
    LedgerCode: "740.03.005",
    LedgerName: "Doctor Professional Liability Insurance Expenses",
  },
  "740.04.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.001",
    LedgerName: "Staff Salaries",
  },
  "740.04.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.002",
    LedgerName: "Staff Overtime Expenses",
  },
  "740.04.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.003",
    LedgerName: "Personnel Premium and Bonus Expenses",
  },
  "740.04.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.004",
    LedgerName: "Outsourced Nursing Service",
  },
  "740.04.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.005",
    LedgerName: "Home Health Nursing Service",
  },
  "740.04.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.006",
    LedgerName: "Translator Fee Expense",
  },
  "740.04.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.007",
    LedgerName: "Cleaning Services Personnel Expenses",
  },
  "740.04.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.008",
    LedgerName: "Outsourced Cleaning Service Personnel Expenses",
  },
  "740.04.009": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.009",
    LedgerName: "Security Services Personnel Expenses",
  },
  "740.04.010": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.010",
    LedgerName: "External Security Service Personnel Expenses",
  },
  "740.04.011": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.011",
    LedgerName: "Severance Pay",
  },
  "740.04.012": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.012",
    LedgerName: "Notice Compensation",
  },
  "740.04.013": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.013",
    LedgerName: "Severance Pay Provision",
  },
  "740.04.014": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.014",
    LedgerName: "SSK Insurance Employer Share",
  },
  "740.04.015": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.015",
    LedgerName: "SSK Unemployment Insurance Employer Share",
  },
  "740.04.016": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.016",
    LedgerName: "Ssk Social Security Support Premium",
  },
  "740.04.017": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "740.04.017",
    LedgerName: "Other Personnel Expenses",
  },
  "740.05.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "740.05.001",
    LedgerName: "Personnel Transportation Expenses",
  },
  "740.05.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "740.05.002",
    LedgerName: "Staff Meal Expenses",
  },
  "740.05.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "740.05.003",
    LedgerName: "Staff Clothing Expenses",
  },
  "740.05.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "740.05.004",
    LedgerName: "Personnel Training Expenses",
  },
  "740.05.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "740.05.005",
    LedgerName: "In-Kind Aids Given to Personnel",
  },
  "740.05.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "740.05.006",
    LedgerName: "Personnel Motivation Expenses",
  },
  "740.06.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.06",
    SubCategroyName: "Service Non Production Material Expenses",
    LedgerCode: "740.06.001",
    LedgerName: "Office Supplies Expenses",
  },
  "740.06.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.06",
    SubCategroyName: "Service Non Production Material Expenses",
    LedgerCode: "740.06.002",
    LedgerName: "Printed Document Expenses",
  },
  "740.06.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.06",
    SubCategroyName: "Service Non Production Material Expenses",
    LedgerCode: "740.06.003",
    LedgerName: "IT Material Expenses",
  },
  "740.06.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.06",
    SubCategroyName: "Service Non Production Material Expenses",
    LedgerCode: "740.06.004",
    LedgerName: "Cleaning Material Expenses",
  },
  "740.06.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.06",
    SubCategroyName: "Service Non Production Material Expenses",
    LedgerCode: "740.06.005",
    LedgerName: "Technical Service Material Expenses",
  },
  "740.07.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.07",
    SubCategroyName: "Building Campus Rental Expenses",
    LedgerCode: "740.07.001",
    LedgerName: "Head Office Rent Expense",
  },
  "740.07.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.07",
    SubCategroyName: "Building Campus Rental Expenses",
    LedgerCode: "740.07.002",
    LedgerName: "Branch Rent Expenses",
  },
  "740.08.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel Expense",
    LedgerCode: "740.08.001",
    LedgerName: "Electricity Expenses",
  },
  "740.08.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel Expense",
    LedgerCode: "740.08.002",
    LedgerName: "Water Expenses",
  },
  "740.08.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel Expense",
    LedgerCode: "740.08.003",
    LedgerName: "Natural Gas Expenses",
  },
  "740.08.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel Expense",
    LedgerCode: "740.08.004",
    LedgerName: "Building Fuel Expenses",
  },
  "740.09.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.09",
    SubCategroyName: "Medical Device Rental Expenses",
    LedgerCode: "740.09.001",
    LedgerName: "Medical Machinery and Device Rental Expenses (Normal)",
  },
  "740.09.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.09",
    SubCategroyName: "Medical Device Rental Expenses",
    LedgerCode: "740.09.002",
    LedgerName: "Medical Device License Expenses",
  },
  "740.10.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "740.10.001",
    LedgerName: "Vehicle Rental Expenses",
  },
  "740.10.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "740.10.002",
    LedgerName: "Vehicle Fuel Expenses",
  },
  "740.10.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "740.10.003",
    LedgerName: "Vehicle Maintenance and Repair Expenses",
  },
  "740.10.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "740.10.004",
    LedgerName: "Vehicle Bridge Highway Expenses",
  },
  "740.11.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.11",
    SubCategroyName: "Communication Expenses",
    LedgerCode: "740.11.001",
    LedgerName: "Telephone Expenses Fixed",
  },
  "740.11.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.11",
    SubCategroyName: "Communication Expenses",
    LedgerCode: "740.11.002",
    LedgerName: "Phone Expenses Mobile Phone",
  },
  "740.11.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.11",
    SubCategroyName: "Communication Expenses",
    LedgerCode: "740.11.003",
    LedgerName: "Network Connection Expenses",
  },
  "740.11.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.11",
    SubCategroyName: "Communication Expenses",
    LedgerCode: "740.11.004",
    LedgerName: "Other Communication Expenses",
  },
  "740.12.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "740.12.001",
    LedgerName: "Building Maintenance and Repair Expenses",
  },
  "740.12.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "740.12.002",
    LedgerName: "IT Maintenance and Repair Expenses",
  },
  "740.12.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "740.12.003",
    LedgerName: "IT Hardware Expenses",
  },
  "740.12.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "740.12.004",
    LedgerName: "IT License Expenses",
  },
  "740.12.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "740.12.005",
    LedgerName: "Biomedical Maintenance and Repair Expenses",
  },
  "740.12.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "740.12.006",
    LedgerName: "Facility Machinery Device Maintenance and Repair Expenses",
  },
  "740.12.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "740.12.007",
    LedgerName: "Fixed Asset Maintenance and Repair Expenses",
  },
  "740.12.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "740.12.008",
    LedgerName: "Other Maintenance and Repair Expenses",
  },
  "740.13.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.13",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.13.001",
    LedgerName: "Outsourced Laboratory Service",
  },
  "740.13.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.13",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.13.002",
    LedgerName: "Outsourced Imaging Service",
  },
  "740.13.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.13",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.13.003",
    LedgerName: "Outsourced TPN Service",
  },
  "740.13.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.13",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.13.004",
    LedgerName: "Outsourced Sterilization Service",
  },
  "740.13.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.13",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.13.005",
    LedgerName: "Outsourced Treatment Service",
  },
  "740.14.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.001",
    LedgerName: "Domestic Patient Meal Expense",
  },
  "740.14.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.002",
    LedgerName: "Overseas Patient Meal Expense",
  },
  "740.14.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.003",
    LedgerName: "Domestic Patient Transfer Expense",
  },
  "740.14.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.004",
    LedgerName: "International Patient Transfer Expense",
  },
  "740.14.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.005",
    LedgerName: "International Funeral Transportation Expense",
  },
  "740.14.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.006",
    LedgerName: "Patient Accommodation Expenses",
  },
  "740.14.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.007",
    LedgerName: "Outsourced Cleaning Service-Material",
  },
  "740.14.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.008",
    LedgerName: "Outsourced Cleaning Service-Personnel",
  },
  "740.14.009": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.009",
    LedgerName: "Outsourced Cleaning Service-Service",
  },
  "740.14.010": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.010",
    LedgerName: "Outsourced Security Service",
  },
  "740.14.011": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.011",
    LedgerName: "Laundry Services",
  },
  "740.14.012": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.012",
    LedgerName: "Ambulance and Other Health Services Expenses",
  },
  "740.14.013": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.013",
    LedgerName: "Textile Material Expenses",
  },
  "740.14.014": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.14",
    SubCategroyName: "Outsourced Medical Service Expenses",
    LedgerCode: "740.14.014",
    LedgerName: "Occupational Security Expertise",
  },
  "740.15.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "740.15.001",
    LedgerName: "Information Technology Consultancy Expense",
  },
  "740.15.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "740.15.004",
    LedgerName: "Human Resources Consultancy Expense",
  },
  "740.15.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "740.15.005",
    LedgerName: "Biomedical Consultancy Expense",
  },
  "740.15.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "740.15.006",
    LedgerName: "Technical Consultancy Expense",
  },
  "740.15.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "740.15.007",
    LedgerName: "Quality-ISO Consultancy Expense",
  },
  "740.15.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "740.15.008",
    LedgerName: "Legal Consultancy Expenses",
  },
  "740.15.010": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "740.15.010",
    LedgerName: "Overseas Patient Commissions",
  },
  "740.15.011": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "740.15.011",
    LedgerName: "Other Consultancy Expense",
  },
  "740.16.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "740.16.001",
    LedgerName: "Device Insurance Expenses",
  },
  "740.16.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "740.16.002",
    LedgerName: "Liability Insurance Expenses",
  },
  "740.16.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "740.16.003",
    LedgerName: "Building Insurance Expenses",
  },
  "740.16.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "740.16.004",
    LedgerName: "Vehicle Insurance Expenses",
  },
  "740.16.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "740.16.005",
    LedgerName: "Fire Insurance Expenses",
  },
  "740.16.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "740.16.006",
    LedgerName: "Earthquake Insurance Expenses",
  },
  "740.16.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "740.16.007",
    LedgerName: "Other Insurance Expenses",
  },
  "740.17.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.001",
    LedgerName: "Building-Land Tax Expenses",
  },
  "740.17.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.002",
    LedgerName: "Property tax",
  },
  "740.17.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.003",
    LedgerName: "Advertisement Advertisement Tax",
  },
  "740.17.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.004",
    LedgerName: "Environmental cleaning tax",
  },
  "740.17.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.005",
    LedgerName: "Medical Waste Tax",
  },
  "740.17.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.006",
    LedgerName: "Solid Waste Tax",
  },
  "740.17.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.007",
    LedgerName: "Stamp Duty Expense",
  },
  "740.17.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.008",
    LedgerName: "Declaration Stamp Duty",
  },
  "740.17.009": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.009",
    LedgerName: "Contract stamp duty",
  },
  "740.17.010": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.010",
    LedgerName: "Municipality Duties and Fees",
  },
  "740.17.011": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.011",
    LedgerName: "Motor Vehicle Tax",
  },
  "740.17.012": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.012",
    LedgerName: "Judicial Fees",
  },
  "740.17.013": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "740.17.013",
    LedgerName: "Other Taxes, Duties and Fees",
  },
  "740.18.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "740.18.001",
    LedgerName: "Urban Travel Expenses",
  },
  "740.18.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "740.18.002",
    LedgerName: "Out of City Travel Expenses",
  },
  "740.18.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "740.18.003",
    LedgerName: "International Travel Expenses",
  },
  "740.18.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "740.18.004",
    LedgerName: "Local Accommodation Expenses",
  },
  "740.18.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "740.18.005",
    LedgerName: "Out of City Accommodation Expenses",
  },
  "740.18.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "740.18.006",
    LedgerName: "Abroad Accommodation Expenses",
  },
  "740.19.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "740.19.001",
    LedgerName: "TV and Radio Programs",
  },
  "740.19.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "740.19.002",
    LedgerName: "Printed Advertising & News Studies",
  },
  "740.19.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "740.19.003",
    LedgerName: "Outdoor Billboard Etc. Space Rentals",
  },
  "740.19.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "740.19.004",
    LedgerName: "Internet & Digital & Social Media Studies",
  },
  "740.19.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "740.19.005",
    LedgerName: "Congress-Fair-Seminar Etc. Organizations",
  },
  "740.19.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "740.19.006",
    LedgerName: "Social responsibility projects",
  },
  "740.19.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "740.19.007",
    LedgerName: "Sponsorships",
  },
  "740.19.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "740.19.008",
    LedgerName: "Promotional and Promotional Products",
  },
  "740.20.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "740.20.001",
    LedgerName: "International TV and Radio Programs",
  },
  "740.20.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "740.20.002",
    LedgerName: "Foreign Printed Advertising & News Studies",
  },
  "740.20.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "740.20.003",
    LedgerName: "Overseas Open Air Billboard Etc. Space Rentals",
  },
  "740.20.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "740.20.004",
    LedgerName: "International Internet & Digital & Social Media Studies",
  },
  "740.20.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "740.20.005",
    LedgerName: "Overseas Congress-Fair-Seminar Etc. Organizations",
  },
  "740.20.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "740.20.006",
    LedgerName: "International Social Responsibility Projects",
  },
  "740.20.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "740.20.007",
    LedgerName: "International Sponsorships",
  },
  "740.20.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "740.20.008",
    LedgerName: "Overseas Promotional and Promotional Products",
  },
  "740.21.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.001",
    LedgerName: "Facility, Machinery and Equipment Depreciations",
  },
  "740.21.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.002",
    LedgerName: "Vehicle Depreciations",
  },
  "740.21.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.003",
    LedgerName: "Fixed Asset Depreciations",
  },
  "740.21.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.004",
    LedgerName: "Special Cost Depreciations",
  },
  "740.21.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.005",
    LedgerName: "Rights Amortization",
  },
  "740.21.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.006",
    LedgerName: "Leasing Depreciation",
  },
  "740.21.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.007",
    LedgerName: "Building Depreciations",
  },
  "740.21.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.008",
    LedgerName: "Other Intangible Asset Depreciations",
  },
  "740.21.009": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.009",
    LedgerName: "Other Tangible Fixed Asset Depreciations",
  },
  "740.21.010": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "740.21.010",
    LedgerName: "Establishment and Organization Depreciations",
  },
  "740.22.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "740.22.001",
    LedgerName: "Expense Not Accepted by Law",
  },
  "740.22.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "740.22.002",
    LedgerName: "Special communication tax",
  },
  "740.22.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "740.22.003",
    LedgerName: "Donations Not Accepted by Law",
  },
  "740.22.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "740.22.004",
    LedgerName: "MTV Not Accepted as Expense",
  },
  "740.22.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "740.22.005",
    LedgerName: "Expense VAT Not Accepted by Law",
  },
  "740.22.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "740.22.006",
    LedgerName: "Vehicle Depreciation (KKEG)",
  },
  "740.23.001": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.001",
    LedgerName: "Fixed Assets Written as Expenses",
  },
  "740.23.002": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.002",
    LedgerName: "Shipping Expenses",
  },
  "740.23.003": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.003",
    LedgerName: "Shipping and Postal Expenses",
  },
  "740.23.004": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.004",
    LedgerName: "Translation Expenses",
  },
  "740.23.005": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.005",
    LedgerName: "Notary Registration Expenses",
  },
  "740.23.006": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.006",
    LedgerName: "Valet Expense",
  },
  "740.23.007": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.007",
    LedgerName: "Representation, Hospitality and Catering Expenses",
  },
  "740.23.008": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.008",
    LedgerName: "Newspaper and Magazine Expense",
  },
  "740.23.009": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.009",
    LedgerName: "Photocopy Expense",
  },
  "740.23.010": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.010",
    LedgerName: "Donation and Aid (Accepted by Law)",
  },
  "740.23.011": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.011",
    LedgerName: "Dues and Membership Expenses",
  },
  "740.23.012": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.012",
    LedgerName: "Contract Termination Fee",
  },
  "740.23.013": {
    CategoryCode: "740",
    CategoryName: "Service Production Cost",
    SubCategoryCode: "740.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "740.23.013",
    LedgerName: "Other Miscellaneous Expenses",
  },
  "760.04.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.001",
    LedgerName: "Staff Salaries",
  },
  "760.04.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.002",
    LedgerName: "Staff Overtime Expenses",
  },
  "760.04.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.003",
    LedgerName: "Personnel Premium and Bonus Expenses",
  },
  "760.04.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.007",
    LedgerName: "Cleaning Services Personnel Expenses",
  },
  "760.04.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.008",
    LedgerName: "Outsourced Cleaning Service Personnel Expenses",
  },
  "760.04.009": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.009",
    LedgerName: "Security Services Personnel Expenses",
  },
  "760.04.010": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.010",
    LedgerName: "External Security Service Personnel Expenses",
  },
  "760.04.011": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.011",
    LedgerName: "Severance Pay",
  },
  "760.04.012": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.012",
    LedgerName: "Notice Compensation",
  },
  "760.04.013": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.013",
    LedgerName: "Severance Pay Provision",
  },
  "760.04.014": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.014",
    LedgerName: "SSK Insurance Employer Share",
  },
  "760.04.015": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.015",
    LedgerName: "SSK Unemployment Insurance Employer Share",
  },
  "760.04.016": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.016",
    LedgerName: "Ssk Social Security Support Premium",
  },
  "760.04.017": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "760.04.017",
    LedgerName: "Other Personnel Expenses",
  },
  "760.05.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "760.05.001",
    LedgerName: "Personnel Transportation Expenses",
  },
  "760.05.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "760.05.002",
    LedgerName: "Staff Meal Expenses",
  },
  "760.05.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "760.05.003",
    LedgerName: "Staff Clothing Expenses",
  },
  "760.05.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "760.05.004",
    LedgerName: "Personnel Training Expenses",
  },
  "760.05.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "760.05.005",
    LedgerName: "In-Kind Aids Given to Personnel",
  },
  "760.05.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "760.05.006",
    LedgerName: "Personnel Motivation Expenses",
  },
  "760.06.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.06",
    SubCategroyName: "Service Non-Production Material Expenses",
    LedgerCode: "760.06.001",
    LedgerName: "Office Supplies Expenses",
  },
  "760.06.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.06",
    SubCategroyName: "Service Non-Production Material Expenses",
    LedgerCode: "760.06.002",
    LedgerName: "Printed Document Expenses",
  },
  "760.06.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.06",
    SubCategroyName: "Service Non-Production Material Expenses",
    LedgerCode: "760.06.003",
    LedgerName: "IT Material Expenses",
  },
  "760.06.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.06",
    SubCategroyName: "Service Non-Production Material Expenses",
    LedgerCode: "760.06.004",
    LedgerName: "Cleaning Material Expenses",
  },
  "760.06.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.06",
    SubCategroyName: "Service Non-Production Material Expenses",
    LedgerCode: "760.06.005",
    LedgerName: "Technical Service Material Expenses",
  },
  "760.07.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.07",
    SubCategroyName: "Building Campus Rental Expenses",
    LedgerCode: "760.07.001",
    LedgerName: "Head Office Rent Expense",
  },
  "760.07.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.07",
    SubCategroyName: "Building Campus Rental Expenses",
    LedgerCode: "760.07.002",
    LedgerName: "Branch Rent Expenses",
  },
  "760.08.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel Expenses",
    LedgerCode: "760.08.001",
    LedgerName: "Electricity Expenses",
  },
  "760.08.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel Expenses",
    LedgerCode: "760.08.002",
    LedgerName: "Water Expenses",
  },
  "760.08.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel Expenses",
    LedgerCode: "760.08.003",
    LedgerName: "Natural Gas Expenses",
  },
  "760.08.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel Expenses",
    LedgerCode: "760.08.004",
    LedgerName: "Building Fuel Expenses",
  },
  "760.10.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "760.10.001",
    LedgerName: "Vehicle Rental Expenses",
  },
  "760.10.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "760.10.002",
    LedgerName: "Vehicle Fuel Expenses",
  },
  "760.10.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "760.10.003",
    LedgerName: "Vehicle Maintenance and Repair Expenses",
  },
  "760.10.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "760.10.004",
    LedgerName: "Vehicle Bridge Highway Expenses",
  },
  "760.11.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.11",
    SubCategroyName: "Communication Expenses",
    LedgerCode: "760.11.001",
    LedgerName: "Telephone Expenses Fixed",
  },
  "760.11.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.11",
    SubCategroyName: "Communication Expenses",
    LedgerCode: "760.11.002",
    LedgerName: "Phone Expenses Mobile Phone",
  },
  "760.11.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.11",
    SubCategroyName: "Communication Expenses",
    LedgerCode: "760.11.003",
    LedgerName: "Network Connection Expenses",
  },
  "760.11.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.11",
    SubCategroyName: "Communication Expenses",
    LedgerCode: "760.11.004",
    LedgerName: "Other Communication Expenses",
  },
  "760.12.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "760.12.001",
    LedgerName: "Building Maintenance and Repair Expenses",
  },
  "760.12.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "760.12.002",
    LedgerName: "IT Maintenance and Repair Expenses",
  },
  "760.12.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "760.12.003",
    LedgerName: "IT Hardware Expenses",
  },
  "760.12.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "760.12.004",
    LedgerName: "IT License Expenses",
  },
  "760.12.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "760.12.005",
    LedgerName: "Biomedical Maintenance and Repair Expenses",
  },
  "760.12.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "760.12.006",
    LedgerName: "Facility Machinery Device Maintenance and Repair Expenses",
  },
  "760.12.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "760.12.007",
    LedgerName: "Fixed Asset Maintenance and Repair Expenses",
  },
  "760.12.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "760.12.008",
    LedgerName: "Other Maintenance and Repair Expenses",
  },
  "760.14.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "760.14.004",
    LedgerName: "International Patient Transfer Expense",
  },
  "760.14.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "760.14.006",
    LedgerName: "Patient Accommodation Expenses",
  },
  "760.14.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "760.14.007",
    LedgerName: "Outsourced Cleaning Service-Material",
  },
  "760.14.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "760.14.008",
    LedgerName: "Outsourced Cleaning Service-Personnel",
  },
  "760.14.009": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "760.14.009",
    LedgerName: "Outsourced Cleaning Service-Service",
  },
  "760.14.010": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "760.14.010",
    LedgerName: "Outsourced Security Service",
  },
  "760.14.014": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "760.14.014",
    LedgerName: "Occupational Security Expertise",
  },
  "760.15.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "760.15.001",
    LedgerName: "Information Technology Consultancy Expense",
  },
  "760.15.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "760.15.004",
    LedgerName: "Human Resources Consultancy Expense",
  },
  "760.15.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "760.15.006",
    LedgerName: "Technical Consultancy Expense",
  },
  "760.15.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "760.15.007",
    LedgerName: "Quality-ISO Consultancy Expense",
  },
  "760.15.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "760.15.008",
    LedgerName: "Legal Consultancy Expenses",
  },
  "760.15.010": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "760.15.010",
    LedgerName: "Overseas Patient Commissions",
  },
  "760.15.011": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "760.15.011",
    LedgerName: "Other Consultancy Expense",
  },
  "760.16.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "760.16.001",
    LedgerName: "Device Insurance Expenses",
  },
  "760.16.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "760.16.002",
    LedgerName: "Liability Insurance Expenses",
  },
  "760.16.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "760.16.003",
    LedgerName: "Building Insurance Expenses",
  },
  "760.16.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "760.16.004",
    LedgerName: "Vehicle Insurance Expenses",
  },
  "760.16.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "760.16.005",
    LedgerName: "Fire Insurance Expenses",
  },
  "760.16.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "760.16.006",
    LedgerName: "Earthquake Insurance Expenses",
  },
  "760.16.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "760.16.007",
    LedgerName: "Other Insurance Expenses",
  },
  "760.17.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.001",
    LedgerName: "Building-Land Tax Expenses",
  },
  "760.17.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.002",
    LedgerName: "Property tax",
  },
  "760.17.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.003",
    LedgerName: "Advertisement Advertisement Tax",
  },
  "760.17.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.004",
    LedgerName: "Environmental cleaning tax",
  },
  "760.17.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.005",
    LedgerName: "Medical Waste Tax",
  },
  "760.17.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.006",
    LedgerName: "Solid Waste Tax",
  },
  "760.17.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.007",
    LedgerName: "Stamp Duty Expense",
  },
  "760.17.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.008",
    LedgerName: "Declaration Stamp Duty Expense",
  },
  "760.17.009": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.009",
    LedgerName: "Contract stamp duty",
  },
  "760.17.010": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.010",
    LedgerName: "Municipality Duties and Fees",
  },
  "760.17.011": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.011",
    LedgerName: "Motor Vehicle Tax",
  },
  "760.17.012": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.012",
    LedgerName: "Judicial Fees",
  },
  "760.17.013": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "760.17.013",
    LedgerName: "Other Taxes, Duties and Fees",
  },
  "760.18.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "760.18.001",
    LedgerName: "Urban Travel Expenses",
  },
  "760.18.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "760.18.002",
    LedgerName: "Out of City Travel Expenses",
  },
  "760.18.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "760.18.003",
    LedgerName: "International Travel Expenses",
  },
  "760.18.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "760.18.004",
    LedgerName: "Local Accommodation Expenses",
  },
  "760.18.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "760.18.005",
    LedgerName: "Out of City Accommodation Expenses",
  },
  "760.18.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "760.18.006",
    LedgerName: "Abroad Accommodation Expenses",
  },
  "760.19.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "760.19.001",
    LedgerName: "TV and Radio Programs",
  },
  "760.19.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "760.19.002",
    LedgerName: "Printed Advertising & News Studies",
  },
  "760.19.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "760.19.003",
    LedgerName: "Outdoor Billboard Etc. Space Rentals",
  },
  "760.19.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "760.19.004",
    LedgerName: "InternetDigitalSocialMediaExpense",
  },
  "760.19.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "760.19.005",
    LedgerName: "CongressExpoWorkshopEventExpense",
  },
  "760.19.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "760.19.006",
    LedgerName: "SocialResponsibilityProjectExpense",
  },
  "760.19.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "760.19.007",
    LedgerName: "Sponsorships",
  },
  "760.19.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "760.19.008",
    LedgerName: "PromotionalAndPromotionalProducts",
  },
  "760.20.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "760.20.001",
    LedgerName: "InternationalTVAndRadioPrograms",
  },
  "760.20.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "760.20.002",
    LedgerName: "ForeignPrintedAdvertisingAndNewsStudies",
  },
  "760.20.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "760.20.003",
    LedgerName: "OverseasOpenAirBillboardSpace Rentals",
  },
  "760.20.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "760.20.004",
    LedgerName: "InternationalInternetAndDigitalSocialMediaStudies",
  },
  "760.20.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "760.20.005",
    LedgerName: "OverseasCongressFairSeminarOrganizations",
  },
  "760.20.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "760.20.006",
    LedgerName: "International Social Responsibility Projects",
  },
  "760.20.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "760.20.007",
    LedgerName: "International Sponsorships",
  },
  "760.20.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "760.20.008",
    LedgerName: "Overseas Promotional and Promotional Products",
  },
  "760.21.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.001",
    LedgerName: "Facility, Machinery and Equipment Depreciations",
  },
  "760.21.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.002",
    LedgerName: "Vehicle Depreciations",
  },
  "760.21.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.003",
    LedgerName: "Fixed Asset Depreciations",
  },
  "760.21.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.004",
    LedgerName: "Special Cost Depreciations",
  },
  "760.21.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.005",
    LedgerName: "Rights Amortization",
  },
  "760.21.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.006",
    LedgerName: "Leasing Depreciation",
  },
  "760.21.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.007",
    LedgerName: "Building Depreciations",
  },
  "760.21.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.008",
    LedgerName: "Other Intangible Asset Depreciations",
  },
  "760.21.009": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.009",
    LedgerName: "Other Tangible Fixed Asset Depreciations",
  },
  "760.21.010": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "760.21.010",
    LedgerName: "Establishment and Organization Depreciations",
  },
  "760.22.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "760.22.001",
    LedgerName: "Expense Not Accepted by Law",
  },
  "760.22.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "760.22.002",
    LedgerName: "Special communication tax",
  },
  "760.22.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "760.22.003",
    LedgerName: "Donations Not Accepted by Law",
  },
  "760.22.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "760.22.004",
    LedgerName: "MTV Not Accepted as Expense",
  },
  "760.22.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "760.22.005",
    LedgerName: "Expense VAT Not Accepted by Law",
  },
  "760.22.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "760.22.006",
    LedgerName: "Vehicle Depreciation (KKEG)",
  },
  "760.23.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.001",
    LedgerName: "Fixed Assets Written as Expenses",
  },
  "760.23.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.002",
    LedgerName: "Shipping Expenses",
  },
  "760.23.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.003",
    LedgerName: "Shipping and Postal Expenses",
  },
  "760.23.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.004",
    LedgerName: "Translation Expenses",
  },
  "760.23.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.005",
    LedgerName: "Notary Registration Expenses",
  },
  "760.23.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.006",
    LedgerName: "Valet Expense",
  },
  "760.23.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.007",
    LedgerName: "Representation, Hospitality and Catering Expenses",
  },
  "760.23.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.008",
    LedgerName: "Newspaper and Magazine Expense",
  },
  "760.23.009": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.009",
    LedgerName: "Photocopy Expense",
  },
  "760.23.010": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.010",
    LedgerName: "Donation and Aid (Accepted by Law)",
  },
  "760.23.011": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.011",
    LedgerName: "Dues and Membership Expenses",
  },
  "760.23.012": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.012",
    LedgerName: "Contract Termination Fee",
  },
  "760.23.013": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "760.23.013",
    LedgerName: "Other Miscellaneous Expenses",
  },
  "760.24.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.24",
    SubCategroyName: "International Marketing Expenses (IPC)",
    LedgerCode: "760.24.001",
    LedgerName: "International Visa Service Fee",
  },
  "760.24.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.24",
    SubCategroyName: "International Marketing Expenses (IPC)",
    LedgerCode: "760.24.002",
    LedgerName: "Foreign Representation, Hospitality and Catering Expenses",
  },
  "760.24.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.24",
    SubCategroyName: "International Marketing Expenses (IPC)",
    LedgerCode: "760.24.003",
    LedgerName: "International Travel Expenses",
  },
  "760.24.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.24",
    SubCategroyName: "International Marketing Expenses (IPC)",
    LedgerCode: "760.24.004",
    LedgerName: "Abroad Accommodation Expenses",
  },
  "760.24.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.24",
    SubCategroyName: "International Marketing Expenses (IPC)",
    LedgerCode: "760.24.005",
    LedgerName: "Foreign Gift Expenses",
  },
  "760.24.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.24",
    SubCategroyName: "International Marketing Expenses (IPC)",
    LedgerCode: "760.24.006",
    LedgerName: "Foreign Studies",
  },
  "760.24.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.24",
    SubCategroyName: "International Marketing Expenses (IPC)",
    LedgerCode: "760.24.007",
    LedgerName: "Translation Expense",
  },
  "760.25.001": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.001",
    LedgerName: "Printed Documents and Stationery Expenses",
  },
  "760.25.002": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.002",
    LedgerName: "Office Supplies Expense",
  },
  "760.25.003": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.003",
    LedgerName: "IT Material Expense",
  },
  "760.25.004": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.004",
    LedgerName: "Building Rental Expenses",
  },
  "760.25.005": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.005",
    LedgerName: "Telephone Expenses",
  },
  "760.25.006": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.006",
    LedgerName: "Special communication tax",
  },
  "760.25.007": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.007",
    LedgerName: "Electricity Expenses",
  },
  "760.25.008": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.008",
    LedgerName: "Fuel Expenses",
  },
  "760.25.009": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.009",
    LedgerName: "Water Expenses",
  },
  "760.25.010": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.010",
    LedgerName: "Cleaning Expenses",
  },
  "760.25.011": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.011",
    LedgerName: "Staff Salaries",
  },
  "760.25.012": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.012",
    LedgerName: "Staff Overtime Expenses",
  },
  "760.25.013": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.013",
    LedgerName: "Severance and Notice Expenses",
  },
  "760.25.014": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.014",
    LedgerName: "SSK Insurance Employer Share",
  },
  "760.25.015": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.015",
    LedgerName: "SSK Unemployment Insurance Employer Share",
  },
  "760.25.016": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.016",
    LedgerName: "Personnel Premium and Bonus Expenses",
  },
  "760.25.017": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.017",
    LedgerName: "Staff Meal Expenses",
  },
  "760.25.018": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.018",
    LedgerName: "Personnel Travel Expense",
  },
  "760.25.019": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.019",
    LedgerName: "Staff Clothing Expenses",
  },
  "760.25.020": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.020",
    LedgerName: "Other Personnel Expenses",
  },
  "760.25.021": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.021",
    LedgerName: "Vehicle Rental Expenses",
  },
  "760.25.022": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.022",
    LedgerName: "Vehicle Fuel Expenses",
  },
  "760.25.023": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.023",
    LedgerName: "Vehicle Maintenance and Repair Expenses",
  },
  "760.25.024": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.024",
    LedgerName: "Domestic Accommodation Expenses",
  },
  "760.25.025": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.025",
    LedgerName: "Valet Expense",
  },
  "760.25.026": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.026",
    LedgerName: "Abroad Accommodation Expenses",
  },
  "760.25.027": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.027",
    LedgerName: "International Travel Expenses",
  },
  "760.25.028": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.028",
    LedgerName: "Other Taxes and Fees, Passport Expenses",
  },
  "760.25.029": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.029",
    LedgerName: "Foreign Patient Consultancy Expense",
  },
  "760.25.030": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.030",
    LedgerName: "Foreign Representation, Hospitality and Catering Expenses",
  },
  "760.25.031": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.031",
    LedgerName: "Fixed Asset Depreciations",
  },
  "760.25.032": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.032",
    LedgerName: "Stamp and Other Tax Expenses",
  },
  "760.25.033": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.033",
    LedgerName: "Shipping and Postal Expenses",
  },
  "760.25.034": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.034",
    LedgerName: "Mobile Communication Promotion Studies",
  },
  "760.25.035": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.035",
    LedgerName: "Foreign Gift Expenses",
  },
  "760.25.036": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.036",
    LedgerName: "TV & Radio Channels Advertising and Promotion Studies",
  },
  "760.25.037": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.037",
    LedgerName: "Display Advertising Expenses",
  },
  "760.25.038": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.038",
    LedgerName: "Printed Advertising & News Studies",
  },
  "760.25.039": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.039",
    LedgerName: "Outdoor Billboard Etc. Space Rentals",
  },
  "760.25.040": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.040",
    LedgerName: "Internet & Digital Media Studies",
  },
  "760.25.041": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.041",
    LedgerName: "Congress-Fair-Seminar Etc. Organizations",
  },
  "760.25.042": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.042",
    LedgerName: "Printed Promotional Materials",
  },
  "760.25.043": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.043",
    LedgerName: "Agreements with Agencies and Business Partners",
  },
  "760.25.044": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.044",
    LedgerName: "Representation, Hospitality and Catering Expenses",
  },
  "760.25.045": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.045",
    LedgerName: "Foreign Studies",
  },
  "760.25.046": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.046",
    LedgerName: "Translation Expense",
  },
  "760.25.047": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.047",
    LedgerName: "Certificate Expenses",
  },
  "760.25.048": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.048",
    LedgerName: "Expense Not Accepted by Law",
  },
  "760.25.049": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.049",
    LedgerName: "Dues and membership expenses",
  },
  "760.25.050": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.050",
    LedgerName: "Other Miscellaneous Expenses",
  },
  "760.25.051": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.051",
    LedgerName: "Accounting Services",
  },
  "760.25.052": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.052",
    LedgerName: "Bank Expenses",
  },
  "760.25.053": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.053",
    LedgerName: "Fixed Assets Written as Expenses",
  },
  "760.25.054": {
    CategoryCode: "760",
    CategoryName: "Marketing Sales and Distribution Expenses",
    SubCategoryCode: "760.25",
    SubCategroyName: "Branch Representation",
    LedgerCode: "760.25.054",
    LedgerName: "Bank Commission Expenses",
  },
  "770.04.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.001",
    LedgerName: "Staff Salaries",
  },
  "770.04.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.002",
    LedgerName: "Staff Overtime Expenses",
  },
  "770.04.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.003",
    LedgerName: "Personnel Premium and Bonus Expenses",
  },
  "770.04.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.007",
    LedgerName: "Cleaning Services Personnel Expenses",
  },
  "770.04.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.008",
    LedgerName: "Outsourced Cleaning Service Personnel Expenses",
  },
  "770.04.009": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.009",
    LedgerName: "Security Services Personnel Expenses",
  },
  "770.04.010": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.010",
    LedgerName: "External Security Service Personnel Expenses",
  },
  "770.04.011": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.011",
    LedgerName: "Severance Pay",
  },
  "770.04.012": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.012",
    LedgerName: "Notice Compensation",
  },
  "770.04.013": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.013",
    LedgerName: "Severance Pay Provision",
  },
  "770.04.014": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.014",
    LedgerName: "SSK Insurance Employer Share",
  },
  "770.04.015": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.015",
    LedgerName: "SSK Unemployment Insurance Employer Share",
  },
  "770.04.016": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.016",
    LedgerName: "Ssk Social Security Support Premium",
  },
  "770.04.017": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.04",
    SubCategroyName: "Staff Expenses",
    LedgerCode: "770.04.017",
    LedgerName: "Other Personnel Expenses",
  },
  "770.05.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "770.05.001",
    LedgerName: "Personnel Transportation Expenses",
  },
  "770.05.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "770.05.002",
    LedgerName: "Staff Meal Expenses",
  },
  "770.05.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "770.05.003",
    LedgerName: "Staff Clothing Expenses",
  },
  "770.05.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "770.05.004",
    LedgerName: "Personnel Training Expenses",
  },
  "770.05.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "770.05.005",
    LedgerName: "In-Kind Aids Given to Personnel",
  },
  "770.05.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.05",
    SubCategroyName: "Staff Other Expenses",
    LedgerCode: "770.05.006",
    LedgerName: "Personnel Motivation Expenses",
  },
  "770.06.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.06",
    SubCategroyName: "Service Non-Production Material Expense",
    LedgerCode: "770.06.001",
    LedgerName: "Office Supplies Expenses",
  },
  "770.06.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.06",
    SubCategroyName: "Service Non-Production Material Expense",
    LedgerCode: "770.06.002",
    LedgerName: "Printed Document Expenses",
  },
  "770.06.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.06",
    SubCategroyName: "Service Non-Production Material Expense",
    LedgerCode: "770.06.003",
    LedgerName: "IT Material Expenses",
  },
  "770.06.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.06",
    SubCategroyName: "Service Non-Production Material Expense",
    LedgerCode: "770.06.004",
    LedgerName: "Cleaning Material Expenses",
  },
  "770.06.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.06",
    SubCategroyName: "Service Non-Production Material Expense",
    LedgerCode: "770.06.005",
    LedgerName: "Technical Service Material Expenses",
  },
  "770.07.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.07",
    SubCategroyName: "Building Campus Rental Expenses",
    LedgerCode: "770.07.001",
    LedgerName: "Head Office Rent Expense",
  },
  "770.07.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.07",
    SubCategroyName: "Building Campus Rental Expenses",
    LedgerCode: "770.07.002",
    LedgerName: "Branch Rent Expenses",
  },
  "770.08.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel-Expenses",
    LedgerCode: "770.08.001",
    LedgerName: "Electricity Expenses",
  },
  "770.08.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel-Expenses",
    LedgerCode: "770.08.002",
    LedgerName: "Water Expenses",
  },
  "770.08.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel-Expenses",
    LedgerCode: "770.08.003",
    LedgerName: "Natural Gas Expenses",
  },
  "770.08.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.08",
    SubCategroyName: "Building Campus Electricity-Water-Gas-Fuel-Expenses",
    LedgerCode: "770.08.004",
    LedgerName: "Building Fuel Expenses",
  },
  "770.10.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "770.10.001",
    LedgerName: "Vehicle Rental Expenses",
  },
  "770.10.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "770.10.002",
    LedgerName: "Vehicle Fuel Expenses",
  },
  "770.10.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "770.10.003",
    LedgerName: "Vehicle Maintenance and Repair Expenses",
  },
  "770.10.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.10",
    SubCategroyName: "Vehicle Expenses",
    LedgerCode: "770.10.004",
    LedgerName: "Vehicle Bridge Highway Expenses",
  },
  "770.11.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.11",
    SubCategroyName: "Communication Expense",
    LedgerCode: "770.11.001",
    LedgerName: "Telephone Expenses Fixed",
  },
  "770.11.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.11",
    SubCategroyName: "Communication Expense",
    LedgerCode: "770.11.002",
    LedgerName: "Phone Expenses Mobile Phone",
  },
  "770.11.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.11",
    SubCategroyName: "Communication Expense",
    LedgerCode: "770.11.003",
    LedgerName: "Network Connection Expenses",
  },
  "770.11.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.11",
    SubCategroyName: "Communication Expense",
    LedgerCode: "770.11.004",
    LedgerName: "Other Communication Expenses",
  },
  "770.12.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "770.12.001",
    LedgerName: "Building Maintenance and Repair Expenses",
  },
  "770.12.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "770.12.002",
    LedgerName: "IT Maintenance and Repair Expenses",
  },
  "770.12.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "770.12.003",
    LedgerName: "IT Hardware Expenses",
  },
  "770.12.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "770.12.004",
    LedgerName: "IT License Expenses",
  },
  "770.12.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "770.12.005",
    LedgerName: "Biomedical Maintenance and Repair Expenses",
  },
  "770.12.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "770.12.006",
    LedgerName: "Facility Machinery Device Maintenance and Repair Expenses",
  },
  "770.12.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "770.12.007",
    LedgerName: "Fixed Asset Maintenance and Repair Expenses",
  },
  "770.12.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.12",
    SubCategroyName: "Maintenance and Repair Expenses",
    LedgerCode: "770.12.008",
    LedgerName: "Other Maintenance and Repair Expenses",
  },
  "770.14.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "770.14.007",
    LedgerName: "Outsourced Cleaning Service-Material",
  },
  "770.14.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "770.14.008",
    LedgerName: "Outsourced Cleaning Service-Personnel",
  },
  "770.14.009": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "770.14.009",
    LedgerName: "Outsourced Cleaning Service-Service",
  },
  "770.14.010": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "770.14.010",
    LedgerName: "Outsourced Security Service",
  },
  "770.14.014": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.14",
    SubCategroyName: "Outsourced Support Services",
    LedgerCode: "770.14.014",
    LedgerName: "Occupational Security Expertise",
  },
  "770.15.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.001",
    LedgerName: "Information Technology Consultancy Expense",
  },
  "770.15.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.002",
    LedgerName: "Financial Consultancy Expense",
  },
  "770.15.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.003",
    LedgerName: "CMB Audit Expense",
  },
  "770.15.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.004",
    LedgerName: "Human Resources Consultancy Expense",
  },
  "770.15.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.006",
    LedgerName: "Technical Consultancy Expense",
  },
  "770.15.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.007",
    LedgerName: "Quality-ISO Consultancy Expense",
  },
  "770.15.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.008",
    LedgerName: "Legal Consultancy Expenses",
  },
  "770.15.009": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.009",
    LedgerName: "Management Consultancy Expense",
  },
  "770.15.011": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.15",
    SubCategroyName: "Counseling Service",
    LedgerCode: "770.15.011",
    LedgerName: "Other Consultancy Expense",
  },
  "770.16.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "770.16.001",
    LedgerName: "Device Insurance Expenses",
  },
  "770.16.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "770.16.002",
    LedgerName: "Liability Insurance Expenses",
  },
  "770.16.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "770.16.003",
    LedgerName: "Building Insurance Expenses",
  },
  "770.16.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "770.16.004",
    LedgerName: "Vehicle Insurance Expenses",
  },
  "770.16.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "770.16.005",
    LedgerName: "Fire Insurance Expenses",
  },
  "770.16.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "770.16.006",
    LedgerName: "Earthquake Insurance Expenses",
  },
  "770.16.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.16",
    SubCategroyName: "Insurance Expenses",
    LedgerCode: "770.16.007",
    LedgerName: "Other Insurance Expenses",
  },
  "770.17.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.001",
    LedgerName: "Building-Land Tax Expenses",
  },
  "770.17.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.002",
    LedgerName: "Property tax",
  },
  "770.17.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.003",
    LedgerName: "Advertisement Advertisement Tax",
  },
  "770.17.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.004",
    LedgerName: "Environmental cleaning tax",
  },
  "770.17.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.005",
    LedgerName: "Medical Waste Tax",
  },
  "770.17.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.006",
    LedgerName: "Solid Waste Tax",
  },
  "770.17.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.007",
    LedgerName: "Stamp Duty Expense",
  },
  "770.17.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.008",
    LedgerName: "Declaration Stamp Duty Expense",
  },
  "770.17.009": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.009",
    LedgerName: "Contract stamp duty",
  },
  "770.17.010": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.010",
    LedgerName: "Municipality Duties and Fees",
  },
  "770.17.011": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.011",
    LedgerName: "Motor Vehicle Tax",
  },
  "770.17.012": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.012",
    LedgerName: "Judicial Fees",
  },
  "770.17.013": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.17",
    SubCategroyName: "Taxes and Fees",
    LedgerCode: "770.17.013",
    LedgerName: "Other Taxes, Duties and Fees",
  },
  "770.18.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "770.18.001",
    LedgerName: "Urban Travel Expenses",
  },
  "770.18.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "770.18.002",
    LedgerName: "Out of City Travel Expenses",
  },
  "770.18.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "770.18.003",
    LedgerName: "International Travel Expenses",
  },
  "770.18.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "770.18.004",
    LedgerName: "Local Accommodation Expenses",
  },
  "770.18.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "770.18.005",
    LedgerName: "Out of City Accommodation Expenses",
  },
  "770.18.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.18",
    SubCategroyName: "Transportation and Accommodation Expenses",
    LedgerCode: "770.18.006",
    LedgerName: "Abroad Accommodation Expenses",
  },
  "770.19.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "770.19.001",
    LedgerName: "TV and Radio Programs",
  },
  "770.19.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "770.19.002",
    LedgerName: "Printed Advertising & News Studies",
  },
  "770.19.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "770.19.003",
    LedgerName: "Outdoor Billboard Etc. Space Rentals",
  },
  "770.19.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "770.19.004",
    LedgerName: "Internet & Digital & Social Media Studies",
  },
  "770.19.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "770.19.005",
    LedgerName: "Congress-Fair-Seminar Etc. Organizations",
  },
  "770.19.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "770.19.006",
    LedgerName: "Social responsibility projects",
  },
  "770.19.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "770.19.007",
    LedgerName: "Sponsorships",
  },
  "770.19.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.19",
    SubCategroyName: "Marketing Advertising Expenses Domestic",
    LedgerCode: "770.19.008",
    LedgerName: "Promotional and Promotional Products",
  },
  "770.20.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "770.20.001",
    LedgerName: "International TV and Radio Programs",
  },
  "770.20.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "770.20.002",
    LedgerName: "Foreign Printed Advertising & News Studies",
  },
  "770.20.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "770.20.003",
    LedgerName: "Overseas Open Air Billboard Etc. Space Rentals",
  },
  "770.20.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "770.20.004",
    LedgerName: "International Internet & Digital & Social Media Studies",
  },
  "770.20.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "770.20.005",
    LedgerName: "Overseas Congress-Fair-Seminar Etc. Organizations",
  },
  "770.20.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "770.20.006",
    LedgerName: "International Social Responsibility Projects",
  },
  "770.20.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "770.20.007",
    LedgerName: "International Sponsorships",
  },
  "770.20.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.20",
    SubCategroyName: "Marketing Advertising Expenses Abroad",
    LedgerCode: "770.20.008",
    LedgerName: "Overseas Promotional and Promotional Products",
  },
  "770.21.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.001",
    LedgerName: "Facility, Machinery and Equipment Depreciations",
  },
  "770.21.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.002",
    LedgerName: "Vehicle Depreciations",
  },
  "770.21.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.003",
    LedgerName: "Fixed Asset Depreciations",
  },
  "770.21.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.004",
    LedgerName: "Special Cost Depreciations",
  },
  "770.21.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.005",
    LedgerName: "Rights Amortization",
  },
  "770.21.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.006",
    LedgerName: "Leasing Depreciation",
  },
  "770.21.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.007",
    LedgerName: "Building Depreciations",
  },
  "770.21.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.008",
    LedgerName: "Other Intangible Asset Depreciations",
  },
  "770.21.009": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.009",
    LedgerName: "Other Tangible Fixed Asset Depreciations",
  },
  "770.21.010": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.21",
    SubCategroyName: "Depreciation and Consumption Shares",
    LedgerCode: "770.21.010",
    LedgerName: "Establishment and Organization Depreciations",
  },
  "770.22.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "770.22.001",
    LedgerName: "Expense Not Accepted by Law",
  },
  "770.22.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "770.22.002",
    LedgerName: "Special communication tax",
  },
  "770.22.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "770.22.003",
    LedgerName: "Donations Not Accepted by Law",
  },
  "770.22.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "770.22.004",
    LedgerName: "MTV Not Accepted as Expense",
  },
  "770.22.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "770.22.005",
    LedgerName: "Expense VAT Not Accepted by Law",
  },
  "770.22.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.22",
    SubCategroyName: "Expense Not Accepted by Law",
    LedgerCode: "770.22.006",
    LedgerName: "Vehicle Depreciation (KKEG)",
  },
  "770.23.001": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.001",
    LedgerName: "Fixed Assets Written as Expenses",
  },
  "770.23.002": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.002",
    LedgerName: "Shipping Expenses",
  },
  "770.23.003": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.003",
    LedgerName: "Shipping and Postal Expenses",
  },
  "770.23.004": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.004",
    LedgerName: "Translation Expenses",
  },
  "770.23.005": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.005",
    LedgerName: "Notary Registration Expenses",
  },
  "770.23.006": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.006",
    LedgerName: "Valet Expense",
  },
  "770.23.007": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.007",
    LedgerName: "Representation, Hospitality and Catering Expenses",
  },
  "770.23.008": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.008",
    LedgerName: "Newspaper and Magazine Expense",
  },
  "770.23.009": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.009",
    LedgerName: "Photocopy Expense",
  },
  "770.23.010": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.010",
    LedgerName: "Donation and Aid (Accepted by Law)",
  },
  "770.23.011": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.011",
    LedgerName: "Dues and Membership Expenses",
  },
  "770.23.012": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.012",
    LedgerName: "Contract Termination Fee",
  },
  "770.23.013": {
    CategoryCode: "770",
    CategoryName: "General Management Expense",
    SubCategoryCode: "770.23",
    SubCategroyName: "Other Miscellaneous Expenses",
    LedgerCode: "770.23.013",
    LedgerName: "Other Miscellaneous Expenses",
  },
};

export default ledger;
