import {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Workbook } from "../types";
import { v4 as uuid } from "uuid";

export type Workbooks = {
  [key: string]: Workbook;
};
export type DataImportState = {
  workbooks: Workbooks;
  setWorkbooks: Dispatch<SetStateAction<Workbooks>>;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  sheet: any;
  setSheet: Dispatch<any>;
  sheets: any[];
  setSheets: Dispatch<any[]>;
  rows: any[];
  columns: any[];
  setRows: any;
  setColumns: any;
  error: any;
  setError: Dispatch<any[]>;
  load: () => void;
  save: () => void;
  worker: any;
  loading: boolean;
  setLoading: any;
};

export type WorkerMessage = {
  type: "ReadWorkbook" | "ReadSheet" | "WorkbookRead" | "SheetRead" | "Error";
  contents: any;
};
const worker_code = `
import * as XLSX from "https://cdn.sheetjs.com/xlsx-0.20.0/package/xlsx.mjs";
self.WORKBOOK = null;

function get_header_row(sheet) {
  var headers = [];
  var range = XLSX.utils.decode_range(sheet["!ref"]);
  var C,
    R = range.s.r; /* start in the first row */
  /* walk every column in the range */
  for (C = range.s.c; C <= range.e.c; ++C) {
    var cell =
      sheet[
        XLSX.utils.encode_cell({ c: C, r: R })
      ]; /* find the cell in the first row */

    var hdr = "UNKNOWN " + C; // <-- replace with your desired default
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

    headers.push(hdr);
  }
  return headers;
}

const handlers = {
  ReadWorkbook: (message) => {
    const buffer = new FileReaderSync().readAsArrayBuffer(message.file);

    self.WORKBOOK = XLSX.read(buffer);
    const { SheetNames } = WORKBOOK;

    return SheetNames;
  },
  ReadSheet: (message) => {
    const { sheetName } = message;

    const sheet = self.WORKBOOK.Sheets[sheetName];

    const rows = XLSX.utils.sheet_to_json(sheet);
    const columns = get_header_row(sheet);

    return { rows, columns };
  },
};

self.addEventListener(
  "message",
  async (e) => {
    try {
      const { type, contents } = e.data;

      postMessage({ type, contents: handlers[type].call(null, contents) });
    } catch (e) {
      postMessage({ type: 'Error', contents: { error: String(e.message || e) } });
    }
  },
  false
);
`;

const worker = new Worker(
  URL.createObjectURL(
    new Blob(
      [worker_code],
      { type: "text/javascript" }, // second argument to the Blob constructor
    ),
  ),
  { type: "module" }, // second argument to Worker constructor
);

const initialState: DataImportState = {
  workbooks: {},
  setWorkbooks: () => {},
  file: null,
  setFile: () => {},
  sheet: null,
  setSheet: () => {},
  sheets: [],
  setSheets: () => {},
  rows: [],
  columns: [],
  setRows: null,
  setColumns: null,
  error: null,
  setError: () => {},
  load: () => {},
  save: () => {},
  worker,
  loading: false,
  setLoading: null,
};

const DataImportContext = createContext<DataImportState>(initialState);

function DataImportProvider({ children }: { children: ReactNode }) {
  const [file, setFile] = useState<File | null>(null);
  const [sheet, setSheet] = useState<any>("");
  const [sheets, setSheets] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [workbooks, setWorkbooks] = useState<Workbooks>(
    Object.keys(localStorage)
      .filter((key) => key.startsWith("workbooks."))
      .reduce((prev: any, next: any) => {
        return {
          ...prev,
          [next.substring(next.indexOf("."))]: JSON.parse(
            localStorage.getItem(next) as string,
          ),
        };
      }, {}),
  );

  const load = () => {
    const wbks = Object.keys(localStorage)
      .filter((key) => key.startsWith("workbooks."))
      .reduce((prev: any, next: any) => {
        return {
          ...prev,
          [next.substring(next.indexOf("."))]: JSON.parse(
            localStorage.getItem(next) as string,
          ),
        };
      }, {});

    setWorkbooks(wbks || {});
  };

  const save = () => {
    // Object.keys(workbooks).forEach((filename: string) => {
    //   Object.keys(workbooks).forEach((wb) => {
    //     workbooks[wb].
    //   })
    //   localStorage.setItem(
    //     `workbooks.${filename}`,
    //     JSON.stringify(workbooks[filename])
    //   );
    // });
  };

  const state: DataImportState = {
    workbooks,
    setWorkbooks,
    load,
    save,
    file,
    setFile,
    sheet,
    setSheet,
    sheets,
    setSheets,
    rows,
    setRows,
    columns,
    setColumns,
    error,
    setError,
    worker,
    loading,
    setLoading,
  };

  worker.onmessage = (e) => {
    const { type, contents } = e.data as WorkerMessage;
    switch (type) {
      case "ReadWorkbook":
        setSheets(contents);
        if (file) {
          workbooks[file.name] = contents.reduce(
            (prev: any, next: string) => ({ ...prev, [next]: {} }),
            {},
          );
          setWorkbooks({ ...workbooks });
        }

        break;
      case "ReadSheet":
        if (file) {
          workbooks[file.name][sheet] = contents;
          setWorkbooks({ ...workbooks });
          setRows(contents.rows.map((row: any) => ({ ...row, Id: uuid() })));
          setColumns(contents.columns);
        }
        break;
      case "Error":
        setError(contents.error);
        break;
    }
    setLoading(false);
  };

  return (
    <DataImportContext.Provider value={state}>
      {children}
    </DataImportContext.Provider>
  );
}

function useDataImportContext() {
  return useContext(DataImportContext);
}

export { DataImportContext, DataImportProvider, useDataImportContext };
