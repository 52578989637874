import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card as MuiCard,
} from "@mui/material";
import { spacing } from "@mui/system";
import ExcelLoader from "../../../components/excel/ExcelLoader";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);

function DataImport() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t("Import data") as string} />
      <Grid container>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <ExcelLoader />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default DataImport;
