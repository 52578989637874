import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation } from "react-router-dom";

import {
  Box,
  CssBaseline,
  Paper as MuiPaper,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Breadcrumbs from "../components/Breadcrumbs";
import HospitalIcon from "../svg/Hospital";
import UsersIcon from "../svg/Users";
import DoctorsIcon from "../svg/Doctors";
import ProceduresIcon from "../svg/Procedures";
import { useBenefitDxContext } from "../contexts/BenefitDxContext";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 258px);
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  max-height: calc(100vh - 126px);
  overflow: auto;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

interface DashboardType {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardType> = ({ children }) => {
  const router = useLocation();
  const { t } = useTranslation();
  const { error } = useBenefitDxContext();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  const sidebarItems: {
    title?: string;
    pages: any[];
  }[] = [
    {
      pages: [
        {
          href: "/procedures",
          title: t("Procedures") as string,
          icon: ProceduresIcon,
        },
        {
          href: "/doctors",
          title: t("Doctors") as string,
          icon: DoctorsIcon,
        },
        {
          href: "/hospitals",
          title: t("Hospitals") as string,
          icon: HospitalIcon,
        },
        {
          href: "/users",
          title: t("Users") as string,
          icon: UsersIcon,
        },
      ],
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  useEffect(() => {
    if (error) {
      setShowSnackbar(true);
    }
  }, [error]);

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={sidebarItems}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={sidebarItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 12 : 5}>
          <Breadcrumbs />
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={10000}
        onClose={() => {
          setShowSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSnackbar(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error?.message || error || "Generic Error"}
        </Alert>
      </Snackbar>
    </Root>
  );
};

export default Dashboard;
