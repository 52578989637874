import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  AccountingSoftware,
  DepartmentClass,
  HospitalType,
  ManagementSoftware,
  City,
} from "../../../types";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";
import { FormProps, BenefitDxForm, FormValue } from "../../../components/Form";
import { MultiPageForm } from "../../../components/MultiPageForm";
import departmentTypes from "../../../config/department-types";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

function CreateHospital() {
  const { createHospital } = useBenefitDxContext();
  const { t } = useTranslation();

  const fields = [
    {
      name: "Name",
      label: "Name",
      testId: "hospital-name-field",
      required: true,
      type: "text",
      initialValue: "",
    },
    {
      name: "Branch",
      label: "Branch",
      testId: "hospital-branch-field",
      required: true,
      type: "text",
      initialValue: "",
    },
    {
      name: "Type",
      label: "Hospital Type",
      testId: "hospital-type-field",
      required: true,
      type: "select",
      initialValue: HospitalType.Private,
      options: Object.keys(HospitalType).map((Type) => ({
        label: t(Type).charAt(0) + t(Type).slice(1).toLowerCase(),
        value: Type,
      })),
    },
    {
      name: "City",
      label: "City",
      required: true,
      type: "select",
      initialValue: Object.values(City)[0],
      options: Object.keys(City).map((item) => ({
        Label: item,
        Value: item,
      })),
    },
    {
      name: "ManagementSoftware",
      label: "Management Software",
      testId: "hospital-management-software-field",
      required: false,
      type: "select",
      initialValue: null,
      options: Object.keys(ManagementSoftware).map((software) => ({
        label: software,
        value: software,
      })),
    },
    {
      name: "AccountingSoftware",
      label: "Accounting Software",
      testId: "hospital-accounting-software-field",
      required: false,
      type: "select",
      initialValue: null,
      options: Object.keys(AccountingSoftware).map((software) => ({
        label: software,
        value: software,
      })),
    },
    {
      name: "Departments",
      label: "Income and Auxiliary Income Departments",
      testId: "hospital-departments-field",
      type: "multiselect",
      initialValue: [],
      options: departmentTypes
        .filter(
          (departmentType) =>
            departmentType.Class === DepartmentClass.Income ||
            departmentType.Class === DepartmentClass.AuxiliaryIncome,
        )
        .map((departmentType, i) => ({
          value: departmentType.Code,
          label: departmentType.Name,
          icon: departmentType.Icon,
        })),
    },
  ];

  const onSubmit = async (values: any) => {
    console.log("VALUES", values);
    const { submit, ...hospital } = values;
    await createHospital(hospital);
  };

  return (
    <React.Fragment>
      <Helmet title="Add new hospital" />
      <Grid
        justifyContent="space-between"
        container
        spacing={6}
        direction={"column"}
      >
        <Grid>
          <Typography variant="h3" gutterBottom>
            {t("New hospital")}
          </Typography>
        </Grid>
        <Divider my={6} />
        <Grid>
          <BenefitDxForm
            properties={fields as FormValue[]}
            onSubmit={onSubmit}
            submitText={t("Create") || ""}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateHospital;
