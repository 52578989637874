import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useParams } from "react-router-dom";

import {
  Account,
  DistributionKey,
  Hospital,
  User,
  Department,
  Mapping,
  Upload,
  UploadError,
} from "../types";
import { useAccount } from "../hooks/useAccount";
import { useHospitals } from "../hooks/useHospitals";
import { useUsers } from "../hooks/useUsers";
import useAuth from "../hooks/useAuth";
import { useDistributionKeys } from "../hooks/useDistributionKeys";
import { useDepartments } from "../hooks/useDepartments";
import { useMappings } from "../hooks/useMappings";
import { useUploads } from "../hooks/useUploads";
import { useUploadErrors } from "../hooks/useUploadErrors";
import { useMapping } from "../hooks/useMapping";
import { useUpload } from "../hooks/useUpload";
import { useParamsContext } from "./ParamsContext";

export type BenefitDxState = {
  urlParams: {
    HospitalId?: string;
    UserId?: string;
    DepartmentId?: string;
    MappingId?: string;
    UploadId?: string;
  };
  setUrlParams?: Dispatch<SetStateAction<any>>;
  error: any;
  setError?: Dispatch<SetStateAction<any>>;
  account?: Account;
  hospitals: Hospital[];
  hospital?: Hospital;
  users: User[];
  user?: User;
  department?: Department;
  mapping?: Mapping;
  departments: Department[];
  mappings: Mapping[];
  distributionKeys: DistributionKey[];
  uploads: Upload[];
  upload?: Upload;
  uploadErrors?: UploadError[];
  createHospital: any;
  updateHospital: any;
  deleteHospital: any;
  createUser: any;
  updateUser: any;
  inviteUser: any;
  deleteUser: any;
  createDepartment: any;
  updateDepartment: any;
  deleteDepartment: any;
  createMapping: any;
  createUpload: any;
  updateUpload: any;
  deleteUpload: any;
  updateMapping: any;
  deleteMapping: any;
  setSelectedHospital: any;
  setSelectedUser: any;
  setSelectedDepartment: any;
  setSelectedMapping: any;
  setSelectedUpload: any;
  resetCreateUpload: any;
  createdUpload: any;
  isListDistributionKeysPending: boolean;
  isListHospitalsPending: boolean;
  isCreateHospitalPending: boolean;
  isUpdateHospitalPending: boolean;
  isDeleteHospitalPending: boolean;
  isFetchHospitalPending: boolean;
  isFetchDepartmentPending: boolean;
  isFetchMappingPending: boolean;
  isFetchUserPending: boolean;
  isListUsersPending: boolean;
  isCreateUserPending: boolean;
  isUpdateUserPending: boolean;
  isInviteUserPending: boolean;
  isCreateDepartmentPending: boolean;
  isDeleteDepartmentPending: boolean;
  isUpdateDepartmentPending: boolean;
  isListDepartmentsByHospitalPending: boolean;
  isCreateMappingPending: boolean;
  isDeleteMappingPending: boolean;
  isUpdateMappingPending: boolean;
  isListMappingsByHospitalPending: boolean;
  isListUploadsByMappingPending: boolean;
  isCreateUploadPending: boolean;
  isDeleteUploadPending: boolean;
  isUpdateUploadPending: boolean;
  isListUploadErrorsByUploadPending: boolean;
  isCreateUploadErrorPending: boolean;
  isDeleteUploadErrorPending: boolean;
  isUpdateUploadErrorPending: boolean;
  createUploadError: any;
  updateUploadError: any;
  deleteUploadError: any;
  createError: any;
  updateError: any;
  deleteError: any;
  createUploadErrorError: any;
  updateUploadErrorError: any;
  deleteUploadErrorError: any;
  listUploadsByMappingError: any;
  listUploadsByMapping: any;
  listUploadErrorsByUploadError: any;
  selectDepartmentsForHospital: any;
  isSelectDepartmentsForHospital: any;
  selectDepartmentsForHospitalError: any;
  downloadErrorsFile: any;
  isDownloadErrorsFilePending: boolean;
  downloadErrorsFileError: any;
  generateErrorsDownload: any;
  isGenerateErrorsDownloadPending: boolean;
  generateErrorsDownloadError: any;
  downloadErrorsFileData: any;
};

const initialState: BenefitDxState = {
  urlParams: {},
  setUrlParams: undefined,
  error: null,
  setError: undefined,
  account: undefined,
  hospitals: [],
  hospital: undefined,
  users: [],
  distributionKeys: [],
  user: undefined,
  department: undefined,
  departments: [],
  mapping: undefined,
  mappings: [],
  uploads: [],
  upload: undefined,
  uploadErrors: [],
  createdUpload: undefined,
  createHospital: undefined,
  updateHospital: undefined,
  deleteHospital: undefined,
  createUser: undefined,
  updateUser: undefined,
  inviteUser: undefined,
  deleteUser: undefined,
  createDepartment: undefined,
  updateDepartment: undefined,
  deleteDepartment: undefined,
  createMapping: undefined,
  createUpload: undefined,
  updateMapping: undefined,
  deleteMapping: undefined,
  updateUpload: undefined,
  deleteUpload: undefined,
  setSelectedHospital: undefined,
  setSelectedUser: undefined,
  setSelectedDepartment: undefined,
  setSelectedMapping: undefined,
  setSelectedUpload: undefined,
  resetCreateUpload: undefined,
  isListDistributionKeysPending: false,
  isListHospitalsPending: false,
  isCreateHospitalPending: false,
  isUpdateHospitalPending: false,
  isDeleteHospitalPending: false,
  isFetchHospitalPending: false,
  isFetchUserPending: false,
  isFetchDepartmentPending: false,
  isFetchMappingPending: false,
  isListUsersPending: false,
  isCreateUserPending: false,
  isUpdateUserPending: false,
  isInviteUserPending: false,
  isCreateDepartmentPending: false,
  isDeleteDepartmentPending: false,
  isUpdateDepartmentPending: false,
  isListDepartmentsByHospitalPending: false,
  isCreateMappingPending: false,
  isDeleteMappingPending: false,
  isUpdateMappingPending: false,
  isListMappingsByHospitalPending: false,
  isListUploadsByMappingPending: false,
  isCreateUploadPending: false,
  isDeleteUploadPending: false,
  isUpdateUploadPending: false,
  isListUploadErrorsByUploadPending: false,
  isCreateUploadErrorPending: false,
  isDeleteUploadErrorPending: false,
  isUpdateUploadErrorPending: false,
  createUploadError: undefined,
  updateUploadError: undefined,
  deleteUploadError: undefined,
  createUploadErrorError: undefined,
  updateUploadErrorError: undefined,
  deleteUploadErrorError: undefined,
  createError: undefined,
  updateError: undefined,
  deleteError: undefined,
  listUploadsByMappingError: undefined,
  listUploadsByMapping: undefined,
  listUploadErrorsByUploadError: undefined,
  selectDepartmentsForHospital: undefined,
  isSelectDepartmentsForHospital: undefined,
  selectDepartmentsForHospitalError: undefined,
  downloadErrorsFile: undefined,
  isDownloadErrorsFilePending: false,
  downloadErrorsFileError: undefined,
  generateErrorsDownload: undefined,
  isGenerateErrorsDownloadPending: false,
  generateErrorsDownloadError: undefined,
  downloadErrorsFileData: undefined,
};

const BenefitDxContext = createContext<BenefitDxState>(initialState);

function BenefitDxProvider({ children }: { children: ReactNode }) {
  const { session } = useAuth();
  const { account } = useAccount(
    session?.getIdToken().payload["custom:accountId"],
  );
  const [hospital, setSelectedHospital] = useState<Hospital>();
  const [user, setSelectedUser] = useState<User>();
  const [department, setSelectedDepartment] = useState<Department>();
  const [mapping, setSelectedMapping] = useState<Mapping>();
  const [upload, setSelectedUpload] = useState<Upload>();
  const [error, setError] = useState<Error | null>();

  const {
    listHospitalsError,
    createHospitalError,
    updateHospitalError,
    deleteHospitalError,
    isListHospitalsPending,
    isCreateHospitalPending,
    isUpdateHospitalPending,
    isDeleteHospitalPending,
    hospitals,
    createHospital,
    updateHospital,
    deleteHospital,
  } = useHospitals(account);

  const {
    listUsersError,
    createUserError,
    updateUserError,
    inviteUserError,
    isListUsersPending,
    isCreateUserPending,
    isUpdateUserPending,
    isInviteUserPending,
    users,
    createUser,
    updateUser,
    inviteUser,
    deleteUser,
  } = useUsers(account);

  const {
    distributionKeys,
    listDistributionKeysError,
    isListDistributionKeysPending,
  } = useDistributionKeys();

  const {
    departments,
    createDepartment,
    isCreateDepartmentPending,
    createDepartmentError,
    deleteDepartment,
    isDeleteDepartmentPending,
    deleteDepartmentError,
    updateDepartment,
    isUpdateDepartmentPending,
    updateDepartmentError,
    listDepartmentsByHospitalError,
    isListDepartmentsByHospitalPending,
    selectDepartmentsForHospital,
    isSelectDepartmentsForHospital,
    selectDepartmentsForHospitalError,
  } = useDepartments(hospital);

  const {
    mappings,
    createMapping,
    isCreateMappingPending,
    createMappingError,
    deleteMapping,
    isDeleteMappingPending,
    deleteMappingError,
    updateMapping,
    isUpdateMappingPending,
    updateMappingError,
    listMappingsByHospitalError,
    isListMappingsByHospitalPending,
  } = useMappings(hospital);

  const {
    uploads,
    createUpload,
    updateUpload,
    deleteUpload,
    isListUploadsByMappingPending,
    isCreateUploadPending,
    isDeleteUploadPending,
    isUpdateUploadPending,
    listUploadsByMappingError,
    createUploadError,
    updateUploadError,
    deleteUploadError,
    resetCreateUpload,
    createdUpload,
    downloadErrorsFile,
    isDownloadErrorsFilePending,
    downloadErrorsFileError,
    generateErrorsDownload,
    isGenerateErrorsDownloadPending,
    generateErrorsDownloadError,
    downloadErrorsFileData,
  } = useUploads(mapping);

  const {
    uploadErrors,
    createUploadError: createError,
    updateUploadError: updateError,
    deleteUploadError: deleteError,
    isListUploadErrorsByUploadPending,
    isCreateUploadErrorPending,
    isDeleteUploadErrorPending,
    isUpdateUploadErrorPending,
    createUploadErrorError,
    updateUploadErrorError,
    deleteUploadErrorError,
    listUploadErrorsByUploadError,
  } = useUploadErrors(upload);

  useEffect(() => {
    if (mapping && hospitals && !hospital) {
      setSelectedHospital(
        hospitals.find((hs: any) => hs.Id === mapping?.HospitalId),
      );
    }
  }, [mapping, hospital, hospitals]);

  useEffect(() => {
    setError(
      listHospitalsError ||
        createHospitalError ||
        updateHospitalError ||
        listUsersError ||
        createUserError ||
        updateUserError ||
        inviteUserError ||
        listDistributionKeysError ||
        listMappingsByHospitalError ||
        createMappingError ||
        deleteMappingError ||
        updateMappingError ||
        listDepartmentsByHospitalError ||
        createDepartmentError ||
        deleteDepartmentError ||
        updateDepartmentError ||
        listUploadsByMappingError ||
        createUploadError ||
        updateUploadError ||
        deleteUploadError ||
        listUploadsByMappingError ||
        listUploadErrorsByUploadError ||
        createUploadErrorError ||
        updateUploadErrorError ||
        deleteUploadErrorError,
    );
  }, [
    listHospitalsError,
    createHospitalError,
    updateHospitalError,
    deleteHospitalError,
    listUsersError,
    createUserError,
    updateUserError,
    inviteUserError,
    listDistributionKeysError,
    listMappingsByHospitalError,
    createMappingError,
    deleteMappingError,
    updateMappingError,
    listDepartmentsByHospitalError,
    createDepartmentError,
    deleteDepartmentError,
    updateDepartmentError,
    setError,
    listUploadsByMappingError,
    createUploadError,
    updateUploadError,
    deleteUploadError,
    listUploadErrorsByUploadError,
    createUploadErrorError,
    updateUploadErrorError,
    deleteUploadErrorError,
  ]);

  const state: BenefitDxState = {
    ...initialState,
    error,
    setError,
    account,
    hospitals,
    users,
    hospital,
    user,
    department,
    mapping,
    distributionKeys,
    departments,
    mappings,
    uploads,
    upload,
    uploadErrors,
    createdUpload,
    createHospital,
    updateHospital,
    deleteHospital,
    createUser,
    updateUser,
    inviteUser,
    deleteUser,
    createDepartment,
    updateDepartment,
    deleteDepartment,
    createMapping,
    createUpload,
    updateMapping,
    updateUpload,
    deleteMapping,
    deleteUpload,
    setSelectedHospital,
    setSelectedUser,
    setSelectedDepartment,
    setSelectedMapping,
    setSelectedUpload,
    resetCreateUpload,
    isListDistributionKeysPending,
    isListHospitalsPending,
    isCreateHospitalPending,
    isUpdateHospitalPending,
    isDeleteHospitalPending,
    isListUsersPending,
    isCreateUserPending,
    isUpdateUserPending,
    isInviteUserPending,
    isCreateDepartmentPending,
    isDeleteDepartmentPending,
    isUpdateDepartmentPending,
    isListDepartmentsByHospitalPending,
    isCreateMappingPending,
    isDeleteMappingPending,
    isUpdateMappingPending,
    isListMappingsByHospitalPending,
    isListUploadsByMappingPending,
    isCreateUploadPending,
    isUpdateUploadPending,
    isDeleteUploadPending,
    isListUploadErrorsByUploadPending,
    isCreateUploadErrorPending,
    isUpdateUploadErrorPending,
    isDeleteUploadErrorPending,
    createUploadError,
    updateUploadError,
    deleteUploadError,
    listUploadsByMappingError,
    listUploadErrorsByUploadError,
    createUploadErrorError,
    updateUploadErrorError,
    deleteUploadErrorError,
    createError,
    updateError,
    deleteError,
    selectDepartmentsForHospital,
    isSelectDepartmentsForHospital,
    selectDepartmentsForHospitalError,
    downloadErrorsFile,
    isDownloadErrorsFilePending,
    downloadErrorsFileError,
    generateErrorsDownload,
    isGenerateErrorsDownloadPending,
    generateErrorsDownloadError,
    downloadErrorsFileData,
  };

  return (
    <BenefitDxContext.Provider value={state}>
      {children}
    </BenefitDxContext.Provider>
  );
}

function useBenefitDxContext() {
  return useContext(BenefitDxContext);
}

export { BenefitDxContext, BenefitDxProvider, useBenefitDxContext };
