import React, { useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import {
  Alert,
  FormControl,
  Unstable_Grid2 as Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useDataImportContext,
  WorkerMessage,
} from "../../contexts/DataImportContext";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import LoadingButton from "@mui/lab/LoadingButton";

type ExcelUploadProps = {
  onLoad?: (sheetData: any) => void;
};

const FileInputLabel = styled.label`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const FileInput = styled.input`
  display: none;
`;

const ExcelLoader: React.FC<ExcelUploadProps> = ({ onLoad }) => {
  const {
    workbooks,
    setWorkbooks,
    file,
    setFile,
    sheets,
    sheet,
    setSheet,
    worker,
    rows: rowData,
    columns: columnData,
  } = useDataImportContext();
  const [rows, setRows] = useState<any[]>(rowData);
  const [columns, setColumns] = useState<any[]>(columnData);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  (window as any).FOO = rows;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setLoading(true);
      setFile(event.target.files[0]);

      console.log("event.target.files", event.target.files);
      setWorkbooks({
        ...workbooks,
        [event.target.files[0].name]: {},
      });

      const message: WorkerMessage = {
        type: "ReadWorkbook",
        contents: { file: event.target.files[0] },
      };

      worker.postMessage(message);
    }
  };

  const onSheetSelected = useCallback(
    async (sheetName: string) => {
      const message: WorkerMessage = {
        type: "ReadSheet",
        contents: { sheetName },
      };

      setLoading(true);
      worker.postMessage(message);
    },
    [setLoading, worker],
  );

  useEffect(() => {
    if (sheet) {
      onSheetSelected(sheet);
    }
  }, [sheet, onSheetSelected]);

  useEffect(() => {
    if (onLoad) onLoad({ sheets });
  }, [sheets, onLoad]);

  useEffect(() => {
    console.log(rowData, columnData);
    setRows(rowData);
    setColumns(columnData);
    if (rowData || columnData) {
      setLoading(false);
    }
  }, [rowData, columnData, setRows, setColumns]);

  return (
    <>
      <Grid
        container
        direction={"column"}
        justifyContent={"flex-start"}
        sx={{ width: "100%" }}
      >
        <Grid container direction={"row"} spacing={4}>
          <Grid flex={1}>
            <form>
              <LoadingButton variant="contained" fullWidth loading={loading}>
                <FileInputLabel htmlFor="file-upload-input">
                  {file ? file.name : t("Upload")}
                  <FileInput
                    name="file-upload-input"
                    id="file-upload-input"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12"
                    onChange={onChange}
                  />
                </FileInputLabel>
              </LoadingButton>
            </form>
          </Grid>
          <Grid flex={1}>
            {Boolean(sheets.length) && (
              <FormControl fullWidth>
                <InputLabel>{t("Sheets")}</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  value={sheet}
                  onChange={(e) => {
                    setSheet(e.target.value);
                  }}
                >
                  {sheets.map((sheetName, i) => (
                    <MenuItem key={sheetName} value={sheetName}>
                      {sheetName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid sx={{ width: "100%" }}>
          {Boolean(rows.length) && (
            <DataGridPro
              checkboxSelection
              loading={loading}
              rows={rows}
              autoHeight
              getRowId={(row) => row.Id}
              disableRowSelectionOnClick
              pageSizeOptions={[5, 10, 25, 50, 100]}
              density="compact"
              slots={{ loadingOverlay: LinearProgress, toolbar: GridToolbar }}
              slotProps={{ toolbar: { showQuickFilter: true } }}
              pagination={true}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              columns={columns.map((col) => ({
                field: col,
                headerName: col,
              }))}
              localeText={{
                noRowsLabel: t("No rows") as string,
                noResultsOverlayLabel: t("No results found.") as string,
                toolbarDensity: t("Density") as string,
                toolbarDensityLabel: t("Density") as string,
                toolbarDensityCompact: t("Compact") as string,
                toolbarDensityStandard: t("Standard") as string,
                toolbarDensityComfortable: t("Comfortable") as string,
                toolbarColumns: t("Columns") as string,
                toolbarColumnsLabel: t("Select columns") as string,
                toolbarFilters: t("Filters") as string,
                toolbarFiltersLabel: t("Show filters") as string,
                toolbarFiltersTooltipHide: t("Hide filters") as string,
                toolbarFiltersTooltipShow: t("Show filters") as string,
                toolbarFiltersTooltipActive: (count) =>
                  count !== 1
                    ? (t(`{{count}} active filters`, { count }) as string)
                    : (t(`{{count}} active filter`, { count }) as string),
                toolbarQuickFilterPlaceholder: t("Search…") as string,
                toolbarQuickFilterLabel: t("Search") as string,
                toolbarQuickFilterDeleteIconLabel: t("Clear") as string,
                toolbarExport: t("Export") as string,
                toolbarExportLabel: t("Export") as string,
                toolbarExportCSV: t("Download as CSV") as string,
                toolbarExportPrint: t("Print") as string,
                toolbarExportExcel: t("Download as Excel") as string,
                columnsPanelTextFieldLabel: t("Find column") as string,
                columnsPanelTextFieldPlaceholder: t("Column title") as string,
                columnsPanelDragIconLabel: t("Reorder column") as string,
                columnsPanelShowAllButton: t("Show all") as string,
                columnsPanelHideAllButton: t("Hide all") as string,
                filterPanelAddFilter: t("Add filter") as string,
                filterPanelRemoveAll: t("Remove all") as string,
                filterPanelDeleteIconLabel: t("Delete") as string,
                filterPanelLogicOperator: t("Logic operator") as string,
                filterPanelOperator: t("Operator") as string,
                filterPanelOperatorAnd: t("And") as string,
                filterPanelOperatorOr: t("Or") as string,
                filterPanelColumns: t("Columns") as string,
                filterPanelInputLabel: t("Value") as string,
                filterPanelInputPlaceholder: t("Filter value") as string,
                filterOperatorContains: t("contains") as string,
                filterOperatorEquals: t("equals") as string,
                filterOperatorStartsWith: t("starts with") as string,
                filterOperatorEndsWith: t("ends with") as string,
                filterOperatorIs: t("is") as string,
                filterOperatorNot: t("is not") as string,
                filterOperatorAfter: t("is after") as string,
                filterOperatorOnOrAfter: t("is on or after") as string,
                filterOperatorBefore: t("is before") as string,
                filterOperatorOnOrBefore: t("is on or before") as string,
                filterOperatorIsEmpty: t("is empty") as string,
                filterOperatorIsNotEmpty: t("is not empty") as string,
                filterOperatorIsAnyOf: t("is any of") as string,
                "filterOperator=": "=",
                "filterOperator!=": "!=",
                "filterOperator>": ">",
                "filterOperator>=": ">=",
                "filterOperator<": "<",
                "filterOperator<=": "<=",
                headerFilterOperatorContains: t("Contains") as string,
                headerFilterOperatorEquals: t("Equals") as string,
                headerFilterOperatorStartsWith: t("Starts with") as string,
                headerFilterOperatorEndsWith: t("Ends with") as string,
                headerFilterOperatorIs: t("Is") as string,
                headerFilterOperatorNot: t("Is not") as string,
                headerFilterOperatorAfter: t("Is after") as string,
                headerFilterOperatorOnOrAfter: t("Is on or after") as string,
                headerFilterOperatorBefore: t("Is before") as string,
                headerFilterOperatorOnOrBefore: t("Is on or before") as string,
                headerFilterOperatorIsEmpty: t("Is empty") as string,
                headerFilterOperatorIsNotEmpty: t("Is not empty") as string,
                headerFilterOperatorIsAnyOf: t("Is any of") as string,
                "headerFilterOperator=": t("Equals") as string,
                "headerFilterOperator!=": t("Not equals") as string,
                "headerFilterOperator>": t("Greater than") as string,
                "headerFilterOperator>=": t(
                  "Greater than or equal to",
                ) as string,
                "headerFilterOperator<": t("Less than") as string,
                "headerFilterOperator<=": t("Less than or equal to") as string,
                filterValueAny: t("any") as string,
                filterValueTrue: t("true") as string,
                filterValueFalse: t("false") as string,
                columnMenuLabel: t("Menu") as string,
                columnMenuShowColumns: t("Show columns") as string,
                columnMenuManageColumns: t("Manage columns") as string,
                columnMenuFilter: t("Filter") as string,
                columnMenuHideColumn: t("Hide column") as string,
                columnMenuUnsort: t("Unsort") as string,
                columnMenuSortAsc: t("Sort by ASC") as string,
                columnMenuSortDesc: t("Sort by DESC") as string,
                columnHeaderFiltersTooltipActive: (count) =>
                  count !== 1
                    ? (t(`{{count}} active filters`, { count }) as string)
                    : (t(`{{count}} active filter`, { count }) as string),
                columnHeaderFiltersLabel: t("Show filters") as string,
                columnHeaderSortIconLabel: t("Sort") as string,
                footerRowSelected: (count) =>
                  count !== 1
                    ? (t(`{{count}} rows selected`, {
                        count: count.toLocaleString() as unknown as any,
                      }) as any)
                    : (t(`{{count}} row selected`, {
                        count: count.toLocaleString() as unknown as any,
                      }) as any),

                footerTotalRows: t("Total Rows:") as string,
                footerTotalVisibleRows: (visibleCount, totalCount) =>
                  t(`{{visibleCount}} of {{totalCount}}`, {
                    visibleCount: visibleCount.toLocaleString(),
                    totalCount: totalCount.toLocaleString(),
                  }) as string,
                checkboxSelectionHeaderName: t("Checkbox selection") as string,
                checkboxSelectionSelectAllRows: t("Select all rows") as string,
                checkboxSelectionUnselectAllRows: t(
                  "Unselect all rows",
                ) as string,
                checkboxSelectionSelectRow: t("Select row") as string,
                checkboxSelectionUnselectRow: t("Unselect row") as string,
                booleanCellTrueLabel: t("yes") as string,
                booleanCellFalseLabel: t("no") as string,
                actionsCellMore: t("more") as string,
                pinToLeft: t("Pin to left") as string,
                pinToRight: t("Pin to right") as string,
                unpin: t("Unpin") as string,
                treeDataGroupingHeaderName: t("Group") as string,
                treeDataExpand: t("see children") as string,
                treeDataCollapse: t("hide children") as string,
                groupingColumnHeaderName: t("Group") as string,
                groupColumn: (name) =>
                  t(`Group by {{name}}`, { name }) as string,
                unGroupColumn: (name) =>
                  t(`Stop grouping by {{name}}`, { name }) as string,
                detailPanelToggle: t("Detail panel toggle") as string,
                expandDetailPanel: t("Expand") as string,
                collapseDetailPanel: t("Collapse") as string,
                rowReorderingHeaderName: t("Row reordering") as string,
                aggregationMenuItemHeader: t("Aggregation") as string,
                aggregationFunctionLabelSum: t("sum") as string,
                aggregationFunctionLabelAvg: t("avg") as string,
                aggregationFunctionLabelMin: t("min") as string,
                aggregationFunctionLabelMax: t("max") as string,
                aggregationFunctionLabelSize: t("size") as string,
                MuiTablePagination: {
                  labelRowsPerPage: t("Rows per page:"),
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity={error ? "error" : "success"}>
          {error ? error : t("Excel sheet has been successfully loaded")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default withTheme(ExcelLoader);
