import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid-pro";
import CRUDTable from "../../../components/Table";
import { useDoctors } from "../../../hooks/useDoctors";

export function Hospitals() {
  const { t } = useTranslation();
  const {
    doctors,
    createDoctor,
    updateDoctor,
    deleteDoctor,
    isListDoctorsPending,
    isUpdateDoctorPending,
    isDeleteDoctorPending,
    isCreateDoctorPending,
  } = useDoctors();

  return (
    <Paper sx={{ width: "100%", marginTop: 5 }}>
      <Helmet title={t("Doctors") as string} />
      <Grid container direction={"column"}>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <CRUDTable
              entity={{
                Type: "doctor",
                Fields: [
                  {
                    Name: "FirstName",
                    Label: "First Name",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                  {
                    Name: "LastName",
                    Label: "Last Name",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                  {
                    Name: "DoctorLicenseId",
                    Label: "Doctor License Id",
                    Required: true,
                    Type: "text",
                    InitialValue: "",
                    Editable: true,
                  },
                ],
              }}
              loading={
                isListDoctorsPending ||
                isCreateDoctorPending ||
                isUpdateDoctorPending ||
                isDeleteDoctorPending
              }
              rows={doctors}
              generateUrl={(id: GridRowId) => {
                return `/doctors/${id}`;
              }}
              DetailsView={null}
              create={createDoctor as any}
              update={updateDoctor as any}
              delete={deleteDoctor as any}
            />
          </CardContent>
        </Card>
      </Grid>
    </Paper>
  );
}

export default Hospitals;
