import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Unstable_Grid2 as Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  TextField,
  InputAdornment,
  Stepper,
  Step,
  StepLabel,
  StepButton,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { DepartmentClass } from "../types";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Department } from "../types";
import departmentTypes from "../config/department-types";
import { Stack } from "@mui/system";

const incomeCenters: Record<string, Department> = departmentTypes
  .filter((dep) => dep.Class === DepartmentClass.Income)
  .reduce((acc, department) => {
    (acc as any)[department.Code] = department;
    return acc;
  }, {});

const expenseCenters: Record<string, Department> = departmentTypes
  .filter((dep) => dep.Class === DepartmentClass.Expense)
  .reduce((acc, department) => {
    (acc as any)[department.Code] = department;
    return acc;
  }, {});

const auxiliaryIncomeCenters: Record<string, Department> = departmentTypes
  .filter((dep) => dep.Class === DepartmentClass.AuxiliaryIncome)
  .reduce((acc, department) => {
    (acc as any)[department.Code] = department;
    return acc;
  }, {});

const auxiliaryExpenseCenters: Record<string, Department> = departmentTypes
  .filter((dep) => dep.Class === DepartmentClass.AuxiliaryExpense)
  .reduce((acc, department) => {
    (acc as any)[department.Code] = department;
    return acc;
  }, {});

const allDepartments = {
  ...incomeCenters,
  ...expenseCenters,
  ...auxiliaryIncomeCenters,
  ...auxiliaryExpenseCenters,
};

type DepartmentMap = typeof incomeCenters &
  typeof expenseCenters &
  typeof auxiliaryIncomeCenters &
  typeof auxiliaryExpenseCenters;

interface Props {
  onSubmit: (departments: Department[]) => void;
  exists: Department[];
}

const Fieldset = styled.fieldset`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;

  legend {
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 8px;
    font-size: 1rem;

    img {
      margin-right: 8px;
    }
  }
`;

export const DepartmentSelector: React.FC<Props> = ({
  onSubmit,
  exists = [],
}) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const [selectedDepartments, setSelectedDepartments] = useState<
    Record<keyof DepartmentMap, boolean>
  >({});

  const steps = useMemo(
    () => [
      {
        title: t("Income Centers"),
        departments: incomeCenters,
      },
      {
        title: t("Auxiliary Income Centers"),
        departments: auxiliaryIncomeCenters,
      },
      {
        title: t("Expense Centers"),
        departments: expenseCenters,
      },
      {
        title: t("Auxiliary Expense Centers"),
        departments: auxiliaryExpenseCenters,
      },
      {
        title: t("Review and Save"),
        departments: allDepartments,
      },
    ],
    [t],
  );

  const submit = () => {
    onSubmit(selectedDepartments as any);
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleStep = (step: number) => () => {
    setCurrentStep(step);
  };

  const currentDeps = useMemo(
    () =>
      Object.groupBy(
        Object.values((steps[currentStep].departments as any) || {}),
        (dep: any) => dep.Specialty,
      ),
    [steps, currentStep],
  );

  const selectedCounts = useMemo(() => {
    let selectedCounts = [0, 0, 0, 0, 0];
    steps.forEach((step: any, index: number) => {
      Object.values((step.departments as any) || {}).forEach((dep: any) => {
        if (selectedDepartments[dep.Code]) {
          selectedCounts[index] += 1;
          selectedCounts[4] =
            selectedCounts[0] +
            selectedCounts[1] +
            selectedCounts[2] +
            selectedCounts[3];
        }
      });
    });

    return selectedCounts;
  }, [selectedDepartments, steps]);

  const allSelected =
    selectedCounts[currentStep] ===
    Object.keys(steps[currentStep].departments || {}).length;
  console.log(selectedDepartments);

  // Clear selections
  const clearSelectionsInCurrentStep = () => {
    setSelectedDepartments((prev) => ({
      ...prev,
      ...Object.keys(steps[currentStep].departments).reduce(
        (acc, key) => ({
          ...acc,
          [steps[currentStep].departments[key].Code]: false,
        }),
        {},
      ),
    }));
  };

  return (
    <Grid container width="100%" direction="column" sx={{ padding: 4 }}>
      <Stack p={4} justifyContent={"space-between"}>
        <Stepper activeStep={currentStep} alternativeLabel>
          {steps.map((step: any, index: number) => (
            <Step key={index}>
              <StepButton
                onClick={handleStep(index)}
              >{`${step.title} (${selectedCounts[index]})`}</StepButton>
            </Step>
          ))}
        </Stepper>
        <Grid
          mt={8}
          container
          width="100%"
          justifyContent={"space-between"}
          direction="row"
        >
          <Grid>
            <Button
              type="button"
              disabled={currentStep === 0}
              onClick={handleBack}
            >
              {t("Back")}
            </Button>
          </Grid>
          <Grid>
            <FormControl>
              <FormControlLabel
                label={t(allSelected ? "Deselect all" : "Select all")}
                control={
                  <Checkbox
                    inputProps={{
                      "aria-label":
                        t(allSelected ? "Deselect all" : "Select all") || "",
                    }}
                    name={"select-all"}
                    checked={allSelected}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      if (e.target.checked) {
                        setSelectedDepartments({
                          ...selectedDepartments,
                          ...Object.values(
                            steps[currentStep].departments || {},
                          ).reduce((acc, dep) => {
                            (acc as any)[dep.Code] = true;
                            return acc;
                          }, {}),
                        });
                      } else {
                        clearSelectionsInCurrentStep();
                      }
                    }}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid>
            {currentStep < steps.length - 1 ? (
              <Button type="button" onClick={handleNext}>
                {t("Next")}
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ marginY: 3 }}
                startIcon={<Save />}
                onClick={submit}
              >
                {t("Save")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Stack>
      <Grid width="100%" direction="column" p={4}>
        {Object.entries(currentDeps || {}).map(([key, deps]: [any, any]) => (
          <Fieldset key={key}>
            <legend>
              <img
                alt={key}
                src={`/static/img/flaticon/${deps[0].Icon}.svg`}
                height={32}
                width={32}
              />
              {t(key)}
            </legend>
            <Stack>
              {deps.map((dep: any) => (
                <FormControl key={dep.Code} sx={{ margin: 0, marginLeft: 2 }}>
                  <FormControlLabel
                    key={dep.Code}
                    label={t(dep.Name)}
                    disabled={!!exists.find((d) => d.Code === dep.Code)}
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": t(dep.Name) || "" }}
                        name={dep.Name}
                        checked={!!selectedDepartments[dep.Code]}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setSelectedDepartments({
                            ...selectedDepartments,
                            [dep.Code]: e.target.checked,
                          });
                        }}
                      />
                    }
                  />
                </FormControl>
              ))}
            </Stack>
          </Fieldset>
        ))}
      </Grid>
      <Grid
        container
        width="100%"
        justifyContent={"space-between"}
        direction="row"
      >
        <Grid>
          <Button
            type="button"
            disabled={currentStep === 0}
            onClick={handleBack}
          >
            {t("Back")}
          </Button>
        </Grid>
        <Grid>
          <FormControl>
            <FormControlLabel
              label={t(allSelected ? "Deselect all" : "Select all")}
              control={
                <Checkbox
                  inputProps={{
                    "aria-label":
                      t(allSelected ? "Deselect all" : "Select all") || "",
                  }}
                  name={"select-all"}
                  checked={allSelected}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setSelectedDepartments({
                        ...selectedDepartments,
                        ...Object.values(
                          steps[currentStep].departments || {},
                        ).reduce((acc, dep) => {
                          (acc as any)[dep.Code] = true;
                          return acc;
                        }, {}),
                      });
                    } else {
                      clearSelectionsInCurrentStep();
                    }
                  }}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid>
          {currentStep < steps.length - 1 ? (
            <Button type="button" onClick={handleNext}>
              {t("Next")}
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginY: 3 }}
              startIcon={<Save />}
            >
              {t("Save")}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
