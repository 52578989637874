import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import axios from "../utils/axios";

export function useDistributionKeys() {
  const { session } = useAuth();

  const {
    error: listDistributionKeysError,
    data: distributionKeys,
    isPending: isListDistributionKeysPending,
  } = useQuery({
    enabled: Boolean(session?.isValid()),
    refetchOnWindowFocus: false,
    queryKey: ["distribution-keys"],
    queryFn: async () => (await axios.post(`/distribution-keys`)).data.Items,
  });

  return {
    distributionKeys: distributionKeys || [],
    listDistributionKeysError,
    isListDistributionKeysPending,
  };
}
