import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Unstable_Grid2 as Grid,
  Card,
  Paper,
  Button,
  LinearProgress,
  CardHeader,
  List,
  ListItem,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  gridClasses,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { Add, SyncAlt, ArrowRightAlt } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { MappingType, Mapping, MappingClass } from "../../../../types";
import { useBenefitDxContext } from "../../../../contexts/BenefitDxContext";
import { useTableLocaleText } from "../../../../hooks/useTableLocaleText";
import { Delete } from "@mui/icons-material";

function MappingDetails({ mapping }: { mapping: Mapping }) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      width={"100%"}
      justifyContent={"center"}
      sx={{ overflow: "hidden" }}
    >
      <Helmet title={t("Mapping details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingY={2.5}
        spacing={5}
        wrap="wrap"
        width={"100%"}
      >
        <Grid width={"100%"}>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              title={t("Mapped Fields")}
              avatar={<SyncAlt />}
            ></CardHeader>
            <CardContent>
              <List>
                {Object.entries(mapping.Map).map(([lhs, rhs]) => {
                  return (
                    <ListItem key={lhs}>
                      <Grid
                        container
                        justifyContent="space-between"
                        width={"100%"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                        spacing={5}
                      >
                        <Grid flex={4} alignItems={"center"}>
                          {t(lhs)}
                        </Grid>
                        <Grid container alignItems={"center"} flex={4}>
                          <ArrowRightAlt />
                        </Grid>

                        <Grid container alignItems={"center"} flex={4}>
                          {t(rhs as string)}
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Mappings() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const localeText = useTableLocaleText();
  const [selected, setSelected] = useState<string[]>([]);

  const { pathname } = useLocation();
  const {
    hospital,
    mappings,
    deleteMapping,
    isListMappingsByHospitalPending,
    isUpdateMappingPending,
    isDeleteMappingPending,
    isCreateMappingPending,
  } = useBenefitDxContext();

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<Add />}
          size="large"
          onClick={() => {
            navigate(`/hospitals/${hospital?.Id}/mappings/new`);
          }}
        >
          {t("Add new")}
        </Button>
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "Name",
      headerName: t("Name") as string,
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <Link
          to={`/hospitals/${params.row.HospitalId}/mappings/${params.row.Id}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "Type",
      headerName: t("Mapping Type") as string,
      flex: 1,
      type: "singleSelect",
      valueOptions: Object.keys(MappingType).map((item) => item),
      getOptionLabel(value) {
        const item: string = Object.keys(MappingType).find(
          (item) => item === value,
        )!!;

        return t(item).charAt(0) + t(item).slice(1).toLowerCase();
      },
      editable: false,
    },
    {
      field: "Class",
      headerName: t("Mapping Class") as string,
      flex: 1,
      type: "singleSelect",
      valueOptions: Object.keys(MappingClass).map((item) => item),
      getOptionLabel(value) {
        const item: string = Object.keys(MappingClass).find(
          (item) => item === value,
        )!!;

        return t(item).charAt(0) + t(item).slice(1).toLowerCase();
      },
      editable: false,
    },
  ];

  const handleDeleteClick = (id: GridRowId) => async () => {
    await deleteMapping(id);
  };

  return (
    <Paper>
      <Helmet title={t("Mappings") as string} />
      <Grid container direction={"column"}>
        <Card>
          <CardContent>
            <DataGridPro
              loading={
                isListMappingsByHospitalPending ||
                isUpdateMappingPending ||
                isDeleteMappingPending ||
                isCreateMappingPending
              }
              disableMultipleRowSelection={true}
              slots={{ loadingOverlay: LinearProgress, toolbar: EditToolbar }}
              autoHeight
              getRowId={(row) => row.Id}
              rows={mappings}
              columns={[
                ...columns,
                {
                  field: "actions",
                  type: "actions",
                  width: 100,
                  cellClassName: "actions",
                  getActions: ({ id }) => {
                    return [
                      <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                      />,
                    ];
                  },
                },
              ]}
              sx={{
                width: "100%",
                [`& .${gridClasses.row}`]: { cursor: "pointer" },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: "none",
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
              }}
              hideFooter
              getDetailPanelContent={({ row }) => (
                <MappingDetails mapping={row} />
              )}
              getDetailPanelHeight={() => "auto"}
              detailPanelExpandedRowIds={selected}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                if (selected[0] === newRowSelectionModel[0]) {
                  setSelected([]);
                } else {
                  setSelected(newRowSelectionModel as string[]);
                }
              }}
              localeText={localeText}
            />
          </CardContent>
        </Card>
      </Grid>
    </Paper>
  );
}

export default Mappings;
