export const departmentTypes = [
  {
    ID: 1,
    Code: "Agl_010",
    Name: "Emergency Medicine",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 4400,
    Specialty: "Emergency Medicine",
    Icon: "023-medical-appointment",
  },
  {
    ID: 2,
    Code: "Agl_020",
    Name: "Family Medicine",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 4800,
    Specialty: "Family Medicine",
    Icon: "024-support",
  },
  {
    ID: 3,
    Code: "Agl_041",
    Name: "General Intensive Care",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 3100,
    Specialty: "Anesthesiology and Reanimation",
    Icon: "025-anesthesiology",
  },
  {
    ID: 4,
    Code: "Agl_042",
    Name: "Algology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 3100,
    Specialty: "Anesthesiology and Reanimation",
    Icon: "025-anesthesiology",
  },
  {
    ID: 5,
    Code: "Agl_043",
    Name: "Algology (Anesthesiology and Reanimation)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 3197,
    Specialty: "Algology (Anesthesiology and Reanimation)",
    Icon: "026-ache",
  },
  {
    ID: 6,
    Code: "Agl_044",
    Name: "Algology (Physical Therapy and Rehabilitation)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 3199,
    Specialty: "Algology (Physical Therapy and Rehabilitation)",
    Icon: "027-physical-therapy",
  },
  {
    ID: 7,
    Code: "Agl_045",
    Name: "Algology (Neurology)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 3198,
    Specialty: "Algology (Neurology)",
    Icon: "028-neurology",
  },
  {
    ID: 8,
    Code: "Agl_050",
    Name: "Brain and Neurosurgery",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2400,
    Specialty: "Brain and Nerve Surgery",
    Icon: "030-surgery",
  },
  {
    ID: 9,
    Code: "Agl_060",
    Name: "Child Health and Diseases",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1500,
    Specialty: "Child Health and Diseases",
    Icon: "031-healthcare",
  },
  {
    ID: 10,
    Code: "Agl_061",
    Name: "Child Emergency",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1596,
    Specialty: "Child Emergency",
    Icon: "032-emergency-1",
  },
  {
    ID: 11,
    Code: "Agl_062",
    Name: "Child Allergy",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1598,
    Specialty: "Child Allergy",
    Icon: "033-sneeze",
  },
  {
    ID: 12,
    Code: "Agl_063",
    Name: "Child Endocrinology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1593,
    Specialty: "Child Endocrinology",
    Icon: "034-thyroid",
  },
  {
    ID: 13,
    Code: "Agl_064",
    Name: "Child Endocrinology and Metabolic Diseases",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1595,
    Specialty: "Child Endocrinology and Metabolic Diseases",
    Icon: "035-metabolism",
  },
  {
    ID: 14,
    Code: "Agl_065",
    Name: "Child Infectious Diseases",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1592,
    Specialty: "Child Infectious Diseases",
    Icon: "036-chickenpox",
  },
  {
    ID: 15,
    Code: "Agl_066",
    Name: "Child Gastroenterology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1591,
    Specialty: "Child Gastroenterology",
    Icon: "037-vomit",
  },
  {
    ID: 16,
    Code: "Agl_067",
    Name: "Child Genetic Diseases",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1574,
    Specialty: "Child Genetic Diseases",
    Icon: "038-inheritance",
  },
  {
    ID: 17,
    Code: "Agl_068",
    Name: "Child Chest Diseases",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1590,
    Specialty: "Child Chest Diseases",
    Icon: "039-tuberculosis",
  },
  {
    ID: 18,
    Code: "Agl_069",
    Name: "Child Hematology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1587,
    Specialty: "Child Hematology",
    Icon: "040-hematology",
  },
  {
    ID: 19,
    Code: "Agl_070",
    Name: "Child Hematology and Oncology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1589,
    Specialty: "Child Hematology and Oncology",
    Icon: "041-patient",
  },
  {
    ID: 20,
    Code: "Agl_071",
    Name: "Child Immunology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1588,
    Specialty: "Child Immunology",
    Icon: "043-vaccine",
  },
  {
    ID: 21,
    Code: "Agl_072",
    Name: "Child Immunology and Allergic Diseases",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1594,
    Specialty: "Child Immunology and Allergic Diseases",
    Icon: "044-allergy",
  },
  {
    ID: 22,
    Code: "Agl_073",
    Name: "Child Cardiovascular Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2387,
    Specialty: "Child Cardiovascular Surgery",
    Icon: "046-surgery-1",
  },
  {
    ID: 23,
    Code: "Agl_074",
    Name: "Child Cardiology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1586,
    Specialty: "Child Cardiology",
    Icon: "045-infant",
  },
  {
    ID: 24,
    Code: "Agl_075",
    Name: "Child Metabolic Diseases",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1585,
    Specialty: "Child Metabolic Diseases",
    Icon: "047-metabolism-1",
  },
  {
    ID: 25,
    Code: "Agl_076",
    Name: "Child Nephrology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1584,
    Specialty: "Child Nephrology",
    Icon: "048-donation",
  },
  {
    ID: 26,
    Code: "Agl_077",
    Name: "Child Neurology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1583,
    Specialty: "Child Neurology",
    Icon: "049-people",
  },
  {
    ID: 27,
    Code: "Agl_078",
    Name: "Child Oncology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1582,
    Specialty: "Child Oncology",
    Icon: "050-patient-1",
  },
  {
    ID: 28,
    Code: "Agl_079",
    Name: "Child Rheumatology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1599,
    Specialty: "Child Rheumatology",
    Icon: "051-rheumatology",
  },
  {
    ID: 29,
    Code: "Agl_080",
    Name: "Developmental Pediatrics",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1597,
    Specialty: "Developmental Pediatrics",
    Icon: "052-pediatrics",
  },
  {
    ID: 30,
    Code: "Agl_081",
    Name: "Neonatology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1561,
    Specialty: "Neonatology",
    Icon: "053-neonatal",
  },
  {
    ID: 31,
    Code: "Agl_082",
    Name: "Neonatal Intensive Care",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1561,
    Specialty: "Neonatology",
    Icon: "053-neonatal",
  },
  {
    ID: 32,
    Code: "Agl_090",
    Name: "Child Surgery",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2000,
    Specialty: "Child Surgery",
    Icon: "054-patient-2",
  },
  {
    ID: 33,
    Code: "Agl_100",
    Name: "Child and Adolescent Mental Health and Diseases",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1600,
    Specialty: "Child and Adolescent Mental Health and Diseases",
    Icon: "055-alzheimer",
  },
  {
    ID: 34,
    Code: "Agl_110",
    Name: "Dermatology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1700,
    Specialty: "Skin and Venereal Diseases",
    Icon: "056-std",
  },
  {
    ID: 35,
    Code: "Agl_121",
    Name: "Hand Surgery (General Surgery)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1901,
    Specialty: "Hand Surgery (General Surgery)",
    Icon: "057-surgery-2",
  },
  {
    ID: 36,
    Code: "Agl_122",
    Name: "Hand Surgery (Orthopedics and Traumatology)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2679,
    Specialty: "Hand Surgery (Orthopedics and Traumatology)",
    Icon: "058-hand-bones",
  },
  {
    ID: 37,
    Code: "Agl_123",
    Name: "Hand Surgery (Plastic, Reconstructive and Aesthetic Surgery)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2579,
    Specialty: "Hand Surgery (Plastic, Reconstructive and Aesthetic Surgery)",
    Icon: "059-arm",
  },
  {
    ID: 38,
    Code: "Agl_150",
    Name: "Internal Medicine",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1000,
    Specialty: "Internal Diseases",
    Icon: "060-digestive-system",
  },
  {
    ID: 39,
    Code: "Agl_151",
    Name: "Endocrinology and Metabolic Diseases",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1078,
    Specialty: "Endocrinology and Metabolic Diseases",
    Icon: "061-endocrinology",
  },
  {
    ID: 40,
    Code: "Agl_152",
    Name: "Gastroenterology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1076,
    Specialty: "Gastroenterology",
    Icon: "060-digestive-system",
  },
  {
    ID: 41,
    Code: "Agl_153",
    Name: "Geriatrics",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1073,
    Specialty: "Geriatrics",
    Icon: "063-wheelchair",
  },
  {
    ID: 42,
    Code: "Agl_154",
    Name: "Rheumatology (Physical Medicine and Rehabilitation)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1855,
    Specialty: "Rheumatology (Physical Medicine and Rehabilitation)",
    Icon: "064-rheumatology-1",
  },
  {
    ID: 43,
    Code: "Agl_155",
    Name: "Rheumatology (Internal Medicine)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1055,
    Specialty: "Rheumatology (Internal Medicine)",
    Icon: "065-rheumatology-2",
  },
  {
    ID: 44,
    Code: "Agl_156",
    Name: "Hematology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1070,
    Specialty: "Hematology",
    Icon: "066-hematology-1",
  },
  {
    ID: 45,
    Code: "Agl_157",
    Name: "Nephrology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1062,
    Specialty: "Nephrology",
    Icon: "067-nephrology",
  },
  {
    ID: 46,
    Code: "Agl_158",
    Name: "Medical Oncology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1053,
    Specialty: "Medical Oncology",
    Icon: "068-cancer-cell",
  },
  {
    ID: 47,
    Code: "Agl_159",
    Name: "Occupational and Occupational Diseases",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1068,
    Specialty: "Occupational and Occupational Diseases",
    Icon: "069-doctor",
  },
  {
    ID: 48,
    Code: "Agl_161",
    Name: "Immunology and Allergy Diseases (Skin and Venereal Diseases)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1701,
    Specialty: "Immunology and Allergic Diseases (Skin and Venereal Diseases)",
    Icon: "070-rash",
  },
  {
    ID: 49,
    Code: "Agl_162",
    Name: "Immunology and Allergy Diseases (Chest Diseases)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1198,
    Specialty: "Immunology and Allergic Diseases (Chest Diseases)",
    Icon: "071-asthma",
  },
  {
    ID: 50,
    Code: "Agl_163",
    Name: "Immunology and Allergy Diseases (Internal Medicine)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1069,
    Specialty: "Immunology and Allergic Diseases (Internal Medicine)",
    Icon: "072-allergy-shots",
  },
  {
    ID: 51,
    Code: "Agl_170",
    Name: "Infectious Diseases and Clinical Microbiology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1200,
    Specialty: "Infectious Diseases and Clinical Microbiology",
    Icon: "073-infected",
  },
  {
    ID: 52,
    Code: "Agl_180",
    Name: "Physical Medicine and Rehabilitation",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1800,
    Specialty: "Physical Medicine and Rehabilitation",
    Icon: "074-physical-therapy-1",
  },
  {
    ID: 53,
    Code: "Agl_190",
    Name: "General Surgery",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1900,
    Specialty: "General Surgery",
    Icon: "075-surgery-room",
  },
  {
    ID: 54,
    Code: "Agl_191",
    Name: "Gastroenterology Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1975,
    Specialty: "Gastroenterology Surgery",
    Icon: "076-bariatric-surgery",
  },
  {
    ID: 55,
    Code: "Agl_192",
    Name: "Endocrine Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1900,
    Specialty: "General Surgery",
    Icon: "075-surgery-room",
  },
  {
    ID: 56,
    Code: "Agl_193",
    Name: "Gastrointestinal Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1900,
    Specialty: "General Surgery",
    Icon: "075-surgery-room",
  },
  {
    ID: 57,
    Code: "Agl_194",
    Name: "Obesity Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1900,
    Specialty: "General Surgery",
    Icon: "075-surgery-room",
  },
  {
    ID: 58,
    Code: "Agl_195",
    Name: "Surgical Oncology (General Surgery)",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1900,
    Specialty: "General Surgery",
    Icon: "075-surgery-room",
  },
  {
    ID: 59,
    Code: "Agl_196",
    Name: "Surgical Oncology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 1910,
    Specialty: "Surgical Oncology",
    Icon: "077-surgery-3",
  },
  {
    ID: 60,
    Code: "Agl_200",
    Name: "Chest Surgery",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2200,
    Specialty: "Chest Surgery",
    Icon: "078-chest",
  },
  {
    ID: 61,
    Code: "Agl_210",
    Name: "Chest Diseases",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1171,
    Specialty: "Chest Diseases",
    Icon: "079-lung-sickness",
  },
  {
    ID: 62,
    Code: "Agl_220",
    Name: "Eye Diseases",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2900,
    Specialty: "Eye Diseases",
    Icon: "080-eye",
  },
  {
    ID: 63,
    Code: "Agl_221",
    Name: "Oculoplasty",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2900,
    Specialty: "Eye Diseases",
    Icon: "080-eye",
  },
  {
    ID: 64,
    Code: "Agl_222",
    Name: "Anterior Segment",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2900,
    Specialty: "Eye Diseases",
    Icon: "080-eye",
  },
  {
    ID: 65,
    Code: "Agl_223",
    Name: "Refractive Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2900,
    Specialty: "Eye Diseases",
    Icon: "080-eye",
  },
  {
    ID: 66,
    Code: "Agl_224",
    Name: "Retina",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2900,
    Specialty: "Eye Diseases",
    Icon: "080-eye",
  },
  {
    ID: 67,
    Code: "Agl_225",
    Name: "Strabismus",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2900,
    Specialty: "Eye Diseases",
    Icon: "080-eye",
  },
  {
    ID: 68,
    Code: "Agl_230",
    Name: "Gynecology and Obstetrics",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 3000,
    Specialty: "Gynecology and Obstetrics",
    Icon: "081-maternity",
  },
  {
    ID: 69,
    Code: "Agl_231",
    Name: "Gynecologic Oncology Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 3010,
    Specialty: "Gynecologic Oncology Surgery",
    Icon: "082-surgery-4",
  },
  {
    ID: 70,
    Code: "Agl_232",
    Name: "Gynecologic Oncology Surgery (Gynecology and Obstetrics)",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 3000,
    Specialty: "Gynecology and Obstetrics",
    Icon: "081-maternity",
  },
  {
    ID: 71,
    Code: "Agl_233",
    Name: "In Vitro Fertilization Center",
    Class: "Income",
    SpecialtyType: "Unit",
    MedulaCode: 3000,
    Specialty: "Gynecology and Obstetrics",
    Icon: "081-maternity",
  },
  {
    ID: 72,
    Code: "Agl_234",
    Name: "Perinatology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 3056,
    Specialty: "Perinatology",
    Icon: "130-obstetrics",
  },
  {
    ID: 73,
    Code: "Agl_240",
    Name: "Adult Cardiovascular Surgery",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2300,
    Specialty: "Cardiovascular Surgery",
    Icon: "083-surgery-5",
  },
  {
    ID: 74,
    Code: "Agl_241",
    Name: "Pediatric Cardiovascular Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2300,
    Specialty: "Cardiovascular Surgery",
    Icon: "083-surgery-5",
  },
  {
    ID: 75,
    Code: "Agl_242",
    Name: "Vascular Surgery",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2300,
    Specialty: "Cardiovascular Surgery",
    Icon: "083-surgery-5",
  },
  {
    ID: 76,
    Code: "Agl_250",
    Name: "Cardiology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1100,
    Specialty: "Cardiology",
    Icon: "084-cardiology",
  },
  {
    ID: 77,
    Code: "Agl_260",
    Name: "Ear Nose Throat Diseases",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2800,
    Specialty: "Ear Nose Throat Diseases",
    Icon: "085-otorhinolaryngology",
  },
  {
    ID: 78,
    Code: "Agl_270",
    Name: "Neurology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1300,
    Specialty: "Neurology",
    Icon: "086-neurology-1",
  },
  {
    ID: 79,
    Code: "Agl_280",
    Name: "Nuclear Medicine",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 3400,
    Specialty: "Nuclear Medicine",
    Icon: "087-nuclear-power",
  },
  {
    ID: 80,
    Code: "Agl_290",
    Name: "Orthopedics and Traumatology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2600,
    Specialty: "Orthopedics and Traumatology",
    Icon: "088-knee",
  },
  {
    ID: 81,
    Code: "Agl_291",
    Name: "Pediatric Orthopedics",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2600,
    Specialty: "Orthopedics and Traumatology",
    Icon: "088-knee",
  },
  {
    ID: 82,
    Code: "Agl_292",
    Name: "Spine Surgery",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2600,
    Specialty: "Orthopedics and Traumatology",
    Icon: "088-knee",
  },
  {
    ID: 83,
    Code: "Agl_300",
    Name: "Plastic, Reconstructive and Aesthetic Surgery",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2500,
    Specialty: "Plastic, Reconstructive and Aesthetic Surgery",
    Icon: "089-face-marking",
  },
  {
    ID: 84,
    Code: "Agl_310",
    Name: "Radiation Oncology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 3200,
    Specialty: "Radiation Oncology",
    Icon: "090-radiation",
  },
  {
    ID: 85,
    Code: "Agl_321",
    Name: "Interventional Radiology",
    Class: "Income",
    SpecialtyType: "Principle",
    MedulaCode: 3300,
    Specialty: "Radiology",
    Icon: "091-x-ray",
  },
  {
    ID: 86,
    Code: "Agl_330",
    Name: "Mental Health and Diseases",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 1400,
    Specialty: "Mental Health and Diseases",
    Icon: "092-mental-disorder",
  },
  {
    ID: 87,
    Code: "Agl_340",
    Name: "Sports Medicine",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 4000,
    Specialty: "Sports Medicine",
    Icon: "093-therapy",
  },
  {
    ID: 88,
    Code: "Agl_350",
    Name: "Air and Space Medicine",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 4200,
    Specialty: "Air and Space Medicine",
    Icon: "094-engineer",
  },
  {
    ID: 89,
    Code: "Agl_051",
    Name: "Aviation Medical Center",
    Class: "Income",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Aviation Medical Center",
    Icon: "095-medicine",
  },
  {
    ID: 90,
    Code: "Agl_360",
    Name: "Underwater Medicine and Hyperbaric Medicine",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 4300,
    Specialty: "Underwater Medicine and Hyperbaric Medicine",
    Icon: "096-chamber",
  },
  {
    ID: 91,
    Code: "Agl_370",
    Name: "Medical Ecology and Hydroclimatology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 600,
    Specialty: "Medical Ecology and Hydroclimatology",
    Icon: "097-air-pollution",
  },
  {
    ID: 92,
    Code: "Agl_380",
    Name: "Medical Genetics",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 3600,
    Specialty: "Medical Genetics",
    Icon: "098-dna",
  },
  {
    ID: 93,
    Code: "Agl_390",
    Name: "Urology",
    Class: "Income",
    SpecialtyType: "Major",
    MedulaCode: 2700,
    Specialty: "Urology",
    Icon: "099-penis",
  },
  {
    ID: 94,
    Code: "Agl_391",
    Name: "Pediatric Urology",
    Class: "Income",
    SpecialtyType: "Minor",
    MedulaCode: 2781,
    Specialty: "Pediatric Urology",
    Icon: "100-urology",
  },
  {
    ID: 95,
    Code: "Agl_500",
    Name: "Dental Diseases and Treatment",
    Class: "Income",
    SpecialtyType: "Dental Minor",
    MedulaCode: 5700,
    Specialty: "Dental Diseases and Treatment",
    Icon: "101-toothache",
  },
  {
    ID: 96,
    Code: "Agl_501",
    Name: "Oral, Dental and Jaw Surgery",
    Class: "Income",
    SpecialtyType: "Dental Major",
    MedulaCode: 5100,
    Specialty: "Oral, Dental and Maxillofacial Surgery",
    Icon: "102-dental-surgery",
  },
  {
    ID: 97,
    Code: "Agl_502",
    Name: "Oral, Dental and Jaw Radiology",
    Class: "Income",
    SpecialtyType: "Dental Major",
    MedulaCode: 5600,
    Specialty: "Oral, Dental and Maxillofacial Radiology",
    Icon: "103-x-ray-1",
  },
  {
    ID: 98,
    Code: "Agl_503",
    Name: "Oral, Facial and Jaw Surgery",
    Class: "Income",
    SpecialtyType: "Dental Minor",
    MedulaCode: 5350,
    Specialty: "Oral, Facial and Maxillofacial Surgery",
    Icon: "104-jaw-surgery",
  },
  {
    ID: 99,
    Code: "Agl_504",
    Name: "Pediatric Dentistry",
    Class: "Income",
    SpecialtyType: "Dental Major",
    MedulaCode: 5300,
    Specialty: "Pediatric Dentistry",
    Icon: "105-baby-teeth",
  },
  {
    ID: 100,
    Code: "Agl_505",
    Name: "Endodontics",
    Class: "Income",
    SpecialtyType: "Dental Major",
    MedulaCode: 5550,
    Specialty: "Endodontics",
    Icon: "106-endodontic",
  },
  {
    ID: 101,
    Code: "Agl_506",
    Name: "Orthodontics",
    Class: "Income",
    SpecialtyType: "Dental Major",
    MedulaCode: 5200,
    Specialty: "Orthodontics",
    Icon: "107-braces",
  },
  {
    ID: 102,
    Code: "Agl_507",
    Name: "Periodontology",
    Class: "Income",
    SpecialtyType: "Dental Major",
    MedulaCode: 5500,
    Specialty: "Periodontology",
    Icon: "108-periodontal",
  },
  {
    ID: 103,
    Code: "Agl_508",
    Name: "Prosthetic Dentistry",
    Class: "Income",
    SpecialtyType: "Dental Major",
    MedulaCode: 5400,
    Specialty: "Prosthetic Dentistry",
    Icon: "109-dental-implant",
  },
  {
    ID: 104,
    Code: "Agl_509",
    Name: "Restorative Dentistry",
    Class: "Income",
    SpecialtyType: "Dental Major",
    MedulaCode: 5150,
    Specialty: "Restorative Dentistry",
    Icon: "110-dental-care",
  },
  {
    ID: 105,
    Code: "Agl_900",
    Name: "Beauty Center",
    Class: "Income",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Beauty Center",
    Icon: "111-massage",
  },
  {
    ID: 106,
    Code: "Agl_910",
    Name: "Hair Transplantation",
    Class: "Income",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Hair Transplantation",
    Icon: "112-hair-transplant",
  },
  {
    ID: 107,
    Code: "Agl_920",
    Name: "Podiatry",
    Class: "Income",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Podiatry",
    Icon: "113-podiatry",
  },
  {
    ID: 108,
    Code: "Agl_930",
    Name: "Check-Up Center",
    Class: "Income",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Check-Up Center",
    Icon: "114-medical-report",
  },
  {
    ID: 109,
    Code: "Agl_940",
    Name: "Home Health",
    Class: "Income",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Home Health",
    Icon: "115-basic-needs",
  },
  {
    ID: 110,
    Code: "Ygl_010",
    Name: "Radiology",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Principle",
    MedulaCode: 3300,
    Specialty: "Radiology",
    Icon: "091-x-ray",
  },
  {
    ID: 111,
    Code: "Ygl_020",
    Name: "Biochemistry",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Principle",
    MedulaCode: null,
    Specialty: "Biochemistry",
    Icon: "116-biochemistry",
  },
  {
    ID: 112,
    Code: "Ygl_030",
    Name: "Pathology",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Principle",
    MedulaCode: null,
    Specialty: "Pathology",
    Icon: "117-microscope",
  },
  {
    ID: 113,
    Code: "Ygl_040",
    Name: "Blood Center",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Principle",
    MedulaCode: null,
    Specialty: "Blood Center",
    Icon: "118-blood-type",
  },
  {
    ID: 114,
    Code: "Ygl_050",
    Name: "Anesthesiology and Reanimation",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Major",
    MedulaCode: 3100,
    Specialty: "Anesthesiology and Reanimation",
    Icon: "025-anesthesiology",
  },
  {
    ID: 115,
    Code: "Ygl_060",
    Name: "Adult Bone Marrow Transplantation Unit",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Adult Bone Marrow Transplantation Unit",
    Icon: "119-bone-marrow",
  },
  {
    ID: 116,
    Code: "Ygl_070",
    Name: "Pediatric Bone Marrow Transplantation Unit",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Pediatric Bone Marrow Transplantation Unit",
    Icon: "120-bone-marrow-1",
  },
  {
    ID: 117,
    Code: "Ygl_080",
    Name: "KVC Intensive Care",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "KVC Intensive Care",
    Icon: "121-intensive-care-unit",
  },
  {
    ID: 118,
    Code: "Ygl_090",
    Name: "Angio Unit",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Angio Unit",
    Icon: "122-coronary-angiography",
  },
  {
    ID: 119,
    Code: "Ygl_100",
    Name: "Delivery Room",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Delivery Room",
    Icon: "123-birth",
  },
  {
    ID: 120,
    Code: "Ygl_110",
    Name: "Operating Room",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Operating Room",
    Icon: "124-surgery-room-1",
  },
  {
    ID: 121,
    Code: "Ygl_120",
    Name: "Recovery",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Recovery",
    Icon: "125-hospital-bed-1",
  },
  {
    ID: 122,
    Code: "Ygl_130",
    Name: "Pharmacy",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Pharmacy",
    Icon: "126-drugs",
  },
  {
    ID: 123,
    Code: "Ygl_140",
    Name: "Hotel Management",
    Class: "AuxiliaryIncome",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Hotel Management",
    Icon: "127-hospital-bed-2",
  },
  {
    ID: 124,
    Code: "Ygd_210",
    Name: "Ambulance Services",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Ambulance Services",
    Icon: "128-ambulance",
  },
  {
    ID: 125,
    Code: "Ygd_220",
    Name: "Morgue",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Morgue",
    Icon: "129-coroner",
  },
  {
    ID: 126,
    Code: "Ygd_230",
    Name: "Sterilization",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Sterilization",
    Icon: "disinfection",
  },
  {
    ID: 127,
    Code: "Agd_010",
    Name: "Affiliated Institutions",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Affiliated Institutions",
    Icon: "affiliate-marketing",
  },
  {
    ID: 128,
    Code: "Agd_020",
    Name: "Support Services",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Support Services",
    Icon: "customer-service",
  },
  {
    ID: 129,
    Code: "Agd_030",
    Name: "Finance",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Finance",
    Icon: "finance",
  },
  {
    ID: 130,
    Code: "Agd_040",
    Name: "Pricing",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Pricing",
    Icon: "barcode",
  },
  {
    ID: 131,
    Code: "Agd_050",
    Name: "Head Office",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Head Office",
    Icon: "placeholder",
  },
  {
    ID: 132,
    Code: "Agd_060",
    Name: "General Management",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "General Management",
    Icon: "management",
  },
  {
    ID: 133,
    Code: "Agd_070",
    Name: "Security Services",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Security Services",
    Icon: "guard",
  },
  {
    ID: 134,
    Code: "Agd_080",
    Name: "Human Resources",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Human Resources",
    Icon: "human-resources",
  },
  {
    ID: 135,
    Code: "Agd_090",
    Name: "Business Development",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Business Development",
    Icon: "workshop",
  },
  {
    ID: 136,
    Code: "Agd_100",
    Name: "Corporate Communication",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Corporate Communication",
    Icon: "b2b",
  },
  {
    ID: 137,
    Code: "Agd_110",
    Name: "Financial Jobs",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Financial Affairs",
    Icon: "financial-statement",
  },
  {
    ID: 138,
    Code: "Agd_120",
    Name: "Accounting",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Accounting",
    Icon: "accounts",
  },
  {
    ID: 139,
    Code: "Agd_130",
    Name: "Marketing",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Marketing",
    Icon: "bullhorn",
  },
  {
    ID: 140,
    Code: "Agd_140",
    Name: "Licensing",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Licensing",
    Icon: "license",
  },
  {
    ID: 141,
    Code: "Agd_150",
    Name: "Purchasing",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Purchasing",
    Icon: "purchasing",
  },
  {
    ID: 142,
    Code: "Agd_160",
    Name: "Strategy",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Strategy",
    Icon: "strategy",
  },
  {
    ID: 143,
    Code: "Agd_170",
    Name: "Technical Services",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Technical Services",
    Icon: "service",
  },
  {
    ID: 144,
    Code: "Agd_180",
    Name: "International Patient Center",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "International Patient Center",
    Icon: "medical",
  },
  {
    ID: 145,
    Code: "Agd_190",
    Name: "International Marketing",
    Class: "Expense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "International Marketing",
    Icon: "global-marketing",
  },
  {
    ID: 146,
    Code: "Ygd_010",
    Name: "Archive",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Archive",
    Icon: "file",
  },
  {
    ID: 147,
    Code: "Ygd_020",
    Name: "Archive and Medical Record",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Archive and Medical Record",
    Icon: "medical-record",
  },
  {
    ID: 148,
    Code: "Ygd_030",
    Name: "Outpatient Services",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Outpatient Services",
    Icon: "outpatient",
  },
  {
    ID: 149,
    Code: "Ygd_040",
    Name: "Chief Physician",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Chief Physician",
    Icon: "chief",
  },
  {
    ID: 150,
    Code: "Ygd_050",
    Name: "Information Systems",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Information Systems",
    Icon: "computing",
  },
  {
    ID: 151,
    Code: "Ygd_060",
    Name: "Biomedical",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Biomedical",
    Icon: "biomedical",
  },
  {
    ID: 152,
    Code: "Ygd_070",
    Name: "Call Center",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Call Center",
    Icon: "call-center-agent",
  },
  {
    ID: 153,
    Code: "Ygd_080",
    Name: "Warehouse Logistics",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Warehouse Logistics",
    Icon: "supply-chain",
  },
  {
    ID: 154,
    Code: "Ygd_090",
    Name: "Doctor Entitlement",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Doctor Entitlement",
    Icon: "doctor-entitlement",
  },
  {
    ID: 155,
    Code: "Ygd_100",
    Name: "Patient Rights",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Patient Rights",
    Icon: "advocacy",
  },
  {
    ID: 156,
    Code: "Ygd_110",
    Name: "Patient Services",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Patient Services",
    Icon: "room-service",
  },
  {
    ID: 157,
    Code: "Ygd_120",
    Name: "Patient Admission Discharge",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Patient Admission Discharge",
    Icon: "patient",
  },
  {
    ID: 158,
    Code: "Ygd_130",
    Name: "Nursing Services",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Nursing Services",
    Icon: "nurse",
  },
  {
    ID: 159,
    Code: "Ygd_140",
    Name: "Quality",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Quality",
    Icon: "quality-assurance",
  },
  {
    ID: 160,
    Code: "Ygd_150",
    Name: "Medical Accounting",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Medical Accounting",
    Icon: "expenses",
  },
  {
    ID: 161,
    Code: "Ygd_160",
    Name: "Health Monitoring",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Health Monitoring",
    Icon: "blood-pressure",
  },
  {
    ID: 162,
    Code: "Ygd_170",
    Name: "Cleaning",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Cleaning",
    Icon: "mop",
  },
  {
    ID: 163,
    Code: "Ygd_180",
    Name: "Medical Record",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Medical Record",
    Icon: "health-report",
  },
  {
    ID: 164,
    Code: "Ygd_190",
    Name: "International Patient Services",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "International Patient Services",
    Icon: "student",
  },
  {
    ID: 165,
    Code: "Ygd_200",
    Name: "Inpatient Services",
    Class: "AuxiliaryExpense",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "Inpatient Services",
    Icon: "inpatient",
  },
  {
    ID: 166,
    Code: "Dgr_010",
    Name: "Gift Shop",
    Class: "Other",
    SpecialtyType: "Business",
    MedulaCode: null,
    Specialty: "Gift Shop",
    Icon: "gift-basket",
  },
  {
    ID: 167,
    Code: "Dgr_020",
    Name: "Cafeteria",
    Class: "Other",
    SpecialtyType: "Business",
    MedulaCode: null,
    Specialty: "Cafeteria",
    Icon: "cafeteria",
  },
  {
    ID: 168,
    Code: "Dgr_030",
    Name: "Guest House",
    Class: "Other",
    SpecialtyType: "Business",
    MedulaCode: null,
    Specialty: "Guest House",
    Icon: "guesthouse",
  },
  {
    ID: 169,
    Code: "Dgr_040",
    Name: "Parking Lot",
    Class: "Other",
    SpecialtyType: "Business",
    MedulaCode: null,
    Specialty: "Parking Lot",
    Icon: "parking-area",
  },
  {
    ID: 170,
    Code: "Dgr_050",
    Name: "TPN",
    Class: "Other",
    SpecialtyType: "Unit",
    MedulaCode: null,
    Specialty: "TPN",
    Icon: "life-support",
  },
  {
    ID: 171,
    Code: "Dgr_060",
    Name: "Cooking Hall",
    Class: "Other",
    SpecialtyType: "Business",
    MedulaCode: null,
    Specialty: "Cooking Hall",
    Icon: "dinner",
  },
];

export default departmentTypes;
