import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { useBenefitDxContext } from "../../../../contexts/BenefitDxContext";
import { SimpleMapper } from "../../../../components/SimpleMapper";

function CreateMapping() {
  const { createMapping, account, hospital, mapping } = useBenefitDxContext();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet
        title={mapping ? "Edit mapping " + mapping.Name : "Add new mapping"}
      />
      <Grid
        justifyContent="flex-start"
        container
        spacing={6}
        direction={"column"}
        width={"100%"}
        height={"100%"}
      >
        <Grid height={"calc(100% - 24px)"} width={"100%"}>
          <SimpleMapper
            onSubmit={async (payload) => {
              await createMapping(payload);
              navigate(`/hospitals/${hospital?.Id}/mappings`);
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateMapping;
