import axios from "../utils/axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { User } from "../types";
import { Account } from "../types";

export function useUsers(account: Account | undefined) {
  const client = useQueryClient();
  const { session } = useAuth();

  const {
    error: listUsersError,
    data: users,
    isPending: isListUsersPending,
  } = useQuery({
    enabled: Boolean(account?.Id) && session?.isValid(),
    queryKey: ["accounts", account?.Id, "users"],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      (await axios.post(`/accounts/${account?.Id}/users`)).data.Items,
  });

  const create = async (payload: Partial<User>) =>
    await axios.post(`/users/new`, {
      AccountId: account?.Id,
      ...payload,
    });

  const {
    mutate: createUser,
    isPending: isCreateUserPending,
    error: createUserError,
  } = useMutation({
    mutationFn: create,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["accounts", account?.Id, "users"],
      });
    },
  });

  const update = async (updatedUser: User) =>
    await axios.put(`/users/${updatedUser?.Id}`, updatedUser);

  const {
    mutate: updateUser,
    isPending: isUpdateUserPending,
    error: updateUserError,
  } = useMutation({
    mutationFn: update,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["accounts", account?.Id, "users"],
      });
    },
  });

  const del = async (updatedUser: User) =>
    await axios.delete(`/users/${updatedUser?.Id}`);

  const {
    mutate: deleteUser,
    isPending: isDeleteUserPending,
    error: deleteUserError,
  } = useMutation({
    mutationFn: del,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["accounts", account?.Id, "users"],
      });
    },
  });

  const invite = async (updatedUser: User) =>
    await axios.get(`/users/${updatedUser?.Id}/invite`);

  const {
    mutate: inviteUser,
    isPending: isInviteUserPending,
    error: inviteUserError,
  } = useMutation({
    mutationFn: invite,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["accounts", account?.Id, "users"],
      });
    },
  });

  return {
    listUsersError,
    createUserError,
    updateUserError,
    inviteUserError,
    deleteUserError,
    isListUsersPending,
    isCreateUserPending,
    isUpdateUserPending,
    isInviteUserPending,
    isDeleteUserPending,
    users: users || [],
    createUser,
    updateUser,
    inviteUser,
    deleteUser,
  };
}
