function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        fill="#5A4146"
        d="M371.613 227.096v5.795a66.072 66.072 0 003.39 20.891l13.126 39.378h24.774v-90.839h-16.516c-13.682.001-24.774 11.092-24.774 24.775z"
      ></path>
      <path
        fill="#694B4B"
        d="M484.684 244.251l-13.974 48.91-66.065-57.806c-9.122 0-16.516-7.395-16.516-16.516 0-13.682 11.092-24.774 24.774-24.774h57.806c9.122 0 16.516 7.395 16.516 16.516v15.522a66.042 66.042 0 01-2.541 18.148z"
      ></path>
      <path fill="#E6AF78" d="M404.64 317.94H454.188V354.489H404.64z"></path>
      <path
        fill="#D29B6E"
        d="M404.645 333.104c7.659 3.112 16.011 4.864 24.774 4.864s17.115-1.752 24.774-4.864v-15.169h-49.548v15.169z"
      ></path>
      <path
        fill="#D5DCED"
        d="M494.031 349.351l-39.84-11.382-24.772 16.439-24.774-16.44-39.838 11.383a24.774 24.774 0 00-17.968 23.821v60.376a8.258 8.258 0 008.258 8.258h148.645a8.258 8.258 0 008.258-8.258v-60.376a24.774 24.774 0 00-17.969-23.821z"
      ></path>
      <path
        fill="#AFB9D2"
        d="M437.677 441.805L421.161 441.805 425.29 354.484 433.548 354.484z"
      ></path>
      <path
        fill="#F0C087"
        d="M429.419 326.193c-27.365 0-49.548-22.184-49.548-49.548v-9.675c0-4.38 1.74-8.581 4.837-11.679l14.975-14.975c3.171-3.171 7.507-4.994 11.989-4.853 26.398.833 49.764 6.488 62.537 18.963 3.127 3.054 4.759 7.326 4.759 11.696v10.523c0 27.365-22.184 49.548-49.549 49.548z"
      ></path>
      <path
        fill="#E6AF78"
        d="M404.645 269.018c0-9.526 8-17.098 17.507-16.492 16.671 1.064 41.409 3.85 56.586 11.15-.495-3.484-1.992-6.773-4.529-9.251-12.773-12.475-36.139-18.13-62.537-18.963l-.001.001v-.001c-4.481-.141-8.818 1.683-11.988 4.853l-14.974 14.974a16.513 16.513 0 00-4.838 11.679v9.675c0 22.596 15.141 41.621 35.82 47.579-6.883-8.492-11.045-19.272-11.045-31.063l-.001-24.141z"
      ></path>
      <path
        fill="#C7CFE2"
        d="M478.968 397.779c0-6.571 2.61-12.872 7.256-17.518l21.257-21.257c2.841 4.061 4.519 8.95 4.519 14.169v60.376a8.258 8.258 0 01-8.258 8.258h-24.774v-44.028z"
      ></path>
      <path
        fill="#959CB5"
        d="M433.548 371h-8.258a4.13 4.13 0 01-4.129-4.129v-12.387h16.516v12.387a4.129 4.129 0 01-4.129 4.129z"
      ></path>
      <g fill="#C7CFE2">
        <path d="M429.419 354.409L415.422 365.1a4.955 4.955 0 01-7.354-1.558l-12.556-22.93 5.054-7.709a3.303 3.303 0 015.009-.611l23.844 22.117zM429.419 354.409l13.997 10.692a4.955 4.955 0 007.354-1.558l12.556-22.93-5.054-7.709a3.303 3.303 0 00-5.009-.611l-23.844 22.116z"></path>
      </g>
      <path
        fill="#5A4146"
        d="M147.822 322.745c-7.057-18.698-12.654-50.841-13.863-67.576-2.3-31.846-26.299-57.806-58.741-57.806s-56.441 25.961-58.741 57.806c-1.209 16.734-6.806 48.878-13.863 67.576-1.555 4.122.24 8.667 4.299 10.507 7.562 3.427 23.685 10.141 43.13 12.756h50.349c19.354-2.621 35.59-9.339 43.13-12.756 4.06-1.84 5.855-6.385 4.3-10.507z"
      ></path>
      <path
        fill="#694B4B"
        d="M143.523 333.253c4.058-1.84 5.854-6.385 4.298-10.507-7.056-18.698-12.654-50.841-13.862-67.576-2.299-31.846-26.299-57.806-58.74-57.806l-.245.001c-24.893.101-33.69 34.05-12.261 46.717 1.287.761 2.112 1.127 2.112 1.127l18.769 100.8h16.799c19.354-2.623 35.59-9.34 43.13-12.756z"
      ></path>
      <path
        fill="#E6AF78"
        d="M134.95 362.588l-26.724-13.361a16.516 16.516 0 01-9.13-14.774l.002-24.775h-49.55v24.776a16.515 16.515 0 01-9.13 14.772l-26.724 13.362A24.771 24.771 0 000 384.745v48.802a8.258 8.258 0 008.258 8.258h132.129a8.258 8.258 0 008.258-8.258v-48.801a24.773 24.773 0 00-13.695-22.158z"
      ></path>
      <path
        fill="#D29B6E"
        d="M74.323 342.709c8.892 0 17.409-1.833 25.217-5.096-.205-1.041-.444-2.076-.444-3.161l.002-24.775h-49.55v24.776c0 1.091-.239 2.131-.446 3.176 7.813 3.246 16.326 5.08 25.221 5.08z"
      ></path>
      <path
        fill="#D5DCED"
        d="M134.95 362.588l-19.038-9.519c-8.828 13.632-24.139 22.673-41.589 22.673s-32.762-9.041-41.59-22.674l-19.038 9.52A24.772 24.772 0 000 384.745v48.802a8.258 8.258 0 008.258 8.258h132.129a8.258 8.258 0 008.258-8.258v-48.801a24.773 24.773 0 00-13.695-22.158z"
      ></path>
      <path
        fill="#F0C087"
        d="M74.323 326.193c-25.192 0-45.992-18.8-49.137-43.135-.456-3.526 1.239-6.983 4.413-8.584 3.802-1.918 9.327-5.152 14.617-9.872 5.891-5.256 9.347-10.799 11.299-14.868 1.681-3.504 5.545-5.486 9.311-4.525 29.076 7.416 48.871 22.543 56.053 28.719 1.928 1.658 3.039 4.103 2.841 6.639-2.001 25.53-23.352 45.626-49.397 45.626z"
      ></path>
      <path
        fill="#E6AF78"
        d="M120.878 273.927c-7.181-6.176-26.977-21.303-56.053-28.719-3.766-.961-7.63 1.021-9.311 4.525-1.478 3.082-3.921 7.008-7.546 11.016l-.005.028c-1.125 1.275-2.323 2.553-3.747 3.825-5.29 4.721-10.815 7.954-14.617 9.872-3.174 1.601-4.868 5.059-4.413 8.585 2.825 21.855 19.927 39.251 41.625 42.569-9.887-6.726-17.262-15.976-17.262-32.466v-11.776c1.876-1.385 3.765-2.766 5.663-4.46a65.745 65.745 0 0011.81-13.933c22.243 6.941 37.323 18.502 43.04 23.418 1.565 1.372 5.449 4.952 9.993 9.215 1.955-4.705 3.248-9.753 3.663-15.058.199-2.537-.912-4.982-2.84-6.641z"
      ></path>
      <path
        fill="#C7CFE2"
        d="M5.034 369.859C1.853 374.081 0 379.26 0 384.745v48.802a8.258 8.258 0 008.258 8.258h24.774v-41.61c0-5.017-2.281-9.763-6.199-12.897L5.034 369.859z"
      ></path>
      <path
        fill="#FF507D"
        d="M374.643 351.318l-69.095-25.126L256 342.709l-49.548-16.516-69.095 25.126a33.032 33.032 0 00-21.744 31.043v51.186a8.258 8.258 0 008.258 8.258h264.258a8.258 8.258 0 008.258-8.258v-51.186a33.031 33.031 0 00-21.744-31.044z"
      ></path>
      <path
        fill="#707487"
        d="M247.349 359.226L239.484 441.805 272.516 441.805 264.651 359.226z"
      ></path>
      <path
        fill="#5B5D6E"
        d="M264.67 370.571h-17.34a5.78 5.78 0 01-5.781-5.781v-22.081h28.901v22.081a5.78 5.78 0 01-5.78 5.781z"
      ></path>
      <path
        fill="#D23C69"
        d="M387.498 359.855c5.576 5.985 8.889 13.956 8.889 22.506v51.186a8.258 8.258 0 01-8.258 8.258h-41.29v-27.608c0-8.761 3.48-17.163 9.675-23.357l30.984-30.985z"
      ></path>
      <path
        fill="#5A4146"
        d="M346.839 155.889v-69.18c0-9.122-7.395-16.516-16.516-16.516h-99.097c-36.486 0-66.065 29.578-66.065 66.065v19.631a82.572 82.572 0 004.238 26.114l2.749 8.247a24.772 24.772 0 011.271 7.834v4.238H338.58v-4.238c0-2.663.429-5.308 1.271-7.834l2.749-8.247a82.553 82.553 0 004.239-26.114z"
      ></path>
      <path
        fill="#694B4B"
        d="M206.452 103.741c0 18.528 15.02 33.548 33.548 33.548h4.645l2.242 65.032h91.693v-4.238c0-2.663.429-5.308 1.271-7.834l2.749-8.247a82.572 82.572 0 004.238-26.114V86.709c0-9.122-7.395-16.516-16.516-16.516H240c-18.528 0-33.548 15.02-33.548 33.548z"
      ></path>
      <path
        fill="#E6AF78"
        d="M206.45 268.39H305.54999999999995V342.71H206.45z"
      ></path>
      <path
        fill="#D29B6E"
        d="M206.452 296.31c14.588 8.451 31.477 13.366 49.548 13.366s34.961-4.915 49.548-13.366v-27.924h-99.097l.001 27.924z"
      ></path>
      <g fill="#D23C69">
        <path d="M256 342.709l-26.338 26.338c-3.54 3.54-9.391 3.141-12.417-.847l-27.309-35.984 7.143-15.053c2.108-4.442 7.606-6.07 11.792-3.49L256 342.709zM256 342.709l26.338 26.338c3.54 3.54 9.391 3.141 12.417-.847l27.309-35.984-7.143-15.053c-2.108-4.442-7.606-6.07-11.792-3.49L256 342.709z"></path>
      </g>
      <path
        fill="#F0C087"
        d="M256 293.161c-45.608 0-82.581-36.973-82.581-82.581v-9.675c0-4.38 1.74-8.581 4.837-11.679l6.841-6.841a16.516 16.516 0 004.837-11.679V150.91c0-3.824 2.568-7.146 6.289-8.025 19.531-4.613 80.308-15.54 121.669 14.88 2.686 1.975 4.171 5.22 4.171 8.554v4.387c0 4.38 1.74 8.581 4.837 11.679l6.841 6.841a16.516 16.516 0 014.837 11.679v9.675c.003 45.608-36.97 82.581-82.578 82.581z"
      ></path>
      <path
        fill="#E6AF78"
        d="M317.893 157.766c-29.09-21.395-67.731-22.321-94.925-19.392-11.471 1.235-20.949 3.144-26.743 4.512-3.721.879-6.289 4.201-6.289 8.025v19.795c0 4.381-1.74 8.582-4.838 11.68l-6.841 6.841a16.517 16.517 0 00-4.838 11.68v9.674c0 42.224 31.71 76.985 72.602 81.92-14.249-14.839-23.054-34.948-23.054-57.146v-60.361c0-8.369 6.223-15.363 14.526-16.404 19.818-2.485 56.116-3.979 84.57 12.118v-4.388c.002-3.334-1.486-6.58-4.17-8.554z"
      ></path>
      <path
        fill="#D23C69"
        d="M124.502 359.855c-5.576 5.985-8.889 13.956-8.889 22.506v51.186a8.258 8.258 0 008.258 8.258h41.29v-27.608c0-8.761-3.48-17.163-9.675-23.357l-30.984-30.985z"
      ></path>
    </svg>
  );
}

export default Icon;
