import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Delete, Mail, Person, Security } from "@mui/icons-material";
import {
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Skeleton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { KeyValuePair } from "../../../components/KeyValuePair";
import LoadingButton from "@mui/lab/LoadingButton";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";

export function User() {
  const { Id } = useParams();
  const { t } = useTranslation();
  const {
    user,
    inviteUser,
    deleteUser,
    updateUser,
    isListUsersPending,
    users,
    setSelectedUser,
  } = useBenefitDxContext();

  useEffect(() => {
    setSelectedUser(users.find((usr) => usr.Id === Id));
  }, [Id, setSelectedUser, users]);

  const Status = useMemo(() => {
    if (!user?.Cognito) {
      return t("Created");
    }

    if (user.Cognito.UserStatus === "CONFIRMED") {
      return t("Active");
    }

    return t("Invited");
  }, [user, t]);

  return (
    <React.Fragment>
      <Helmet title={t("User details") as string} />
      <Grid
        justifyContent="flex-start"
        container
        direction={"row"}
        paddingTop={5}
        spacing={5}
        wrap="wrap"
      >
        <Grid xs={12} sm={4} md={4}>
          <Card>
            <CardHeader
              title={
                isListUsersPending ? (
                  <Skeleton animation="wave" height={10} width="80%" />
                ) : (
                  t("User")
                )
              }
              avatar={
                isListUsersPending ? (
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                ) : (
                  <Person />
                )
              }
            ></CardHeader>
            <CardContent>
              <Grid container justifyContent="flex-start" direction={"column"}>
                <KeyValuePair
                  Key={"Email"}
                  Value={user?.Email}
                  Editable={true}
                  Loading={isListUsersPending}
                  OnSubmit={async (Email) => {
                    await updateUser({ ...user!!, Email });
                  }}
                />
                <KeyValuePair
                  Key={"FirstName"}
                  Value={user?.FirstName}
                  Editable={true}
                  Loading={isListUsersPending}
                  OnSubmit={async (FirstName) => {
                    await updateUser({ ...user!!, FirstName });
                  }}
                />
                <KeyValuePair
                  Key={"LastName"}
                  Value={user?.LastName}
                  Editable={true}
                  Loading={isListUsersPending}
                  OnSubmit={async (LastName) => {
                    await updateUser({ ...user!!, LastName });
                  }}
                />
                <KeyValuePair
                  Key={"Status"}
                  Value={Status}
                  Editable={false}
                  Loading={isListUsersPending}
                />
              </Grid>
            </CardContent>
            <CardActions>
              {!user?.Cognito && (
                <LoadingButton
                  loading={isListUsersPending}
                  loadingPosition="start"
                  onClick={async () => {
                    await inviteUser(user!!);
                  }}
                  variant="contained"
                  fullWidth
                  startIcon={<Mail />}
                >
                  {t("Send invitation")}
                </LoadingButton>
              )}
            </CardActions>
            <CardActions>
              <LoadingButton
                loading={isListUsersPending}
                loadingPosition="start"
                onClick={() => deleteUser(user!!)}
                color="error"
                variant="outlined"
                fullWidth
                startIcon={<Delete />}
              >
                {t("Delete user")}
              </LoadingButton>
            </CardActions>
          </Card>
        </Grid>
        <Grid xs={12} sm={8} md={8}>
          <Card>
            <CardHeader
              title={t("Security settings")}
              avatar={<Security />}
            ></CardHeader>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"column"}
              ></Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default User;
