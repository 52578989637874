import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Unstable_Grid2 as Grid, Tab, Tabs, Box } from "@mui/material";
import {
  Link,
  Outlet,
  useParams,
  useLocation,
  matchPath,
} from "react-router-dom";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function useRouteMatch(patterns: string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export function Hospital() {
  const { HospitalId } = useParams();
  const matches = useMediaQuery("(max-width:699px)");
  const { hospitals, setSelectedHospital } = useBenefitDxContext();
  const { t } = useTranslation();
  const [value] = React.useState(0);
  const hospitalRoutes = [
    `/hospitals/:HospitalId/departments`,
    `/hospitals/:HospitalId/reports`,
    `/hospitals/:HospitalId/mappings`,
    `/hospitals/:HospitalId/services`,
    `/hospitals/:HospitalId/materials`,
    `/hospitals/:HospitalId/transactions`,
    `/hospitals/:HospitalId`,
  ];

  useEffect(() => {
    setSelectedHospital(hospitals.find((hs) => hs.Id === HospitalId));
  }, [HospitalId, setSelectedHospital, hospitals]);

  const routeMatch = useRouteMatch(hospitalRoutes);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <React.Fragment>
      <Helmet title={t("Hospital Details") as string} />
      <Grid justifyContent={"flex-start"} container flexDirection={"column"}>
        <Grid
          container
          justifyContent={matches ? "space-between" : "flex-end"}
          direction={"row"}
          width={"100%"}
        >
          <Grid width={matches ? "100%" : "auto"}>
            <Tabs
              value={currentTab}
              indicatorColor="secondary"
              textColor="inherit"
              variant={matches ? "fullWidth" : "standard"}
              sx={{ height: 54, width: "100%" }}
            >
              <Tab
                label={matches ? undefined : t("Hospital")}
                sx={{ paddingY: 0, paddingX: 4, minWidth: 0 }}
                component={Link}
                to={""}
                value={hospitalRoutes[6]}
                icon={
                  <img
                    style={{ width: 24, height: 24 }}
                    src={`/static/img/flaticon/hospital.svg`}
                    alt={t("Hospital") as string}
                  />
                }
                iconPosition="start"
              />
              <Tab
                label={matches ? undefined : t("Departments")}
                component={Link}
                sx={{ paddingY: 0, paddingX: 4, minWidth: 0 }}
                to={"departments"}
                value={hospitalRoutes[0]}
                icon={
                  <img
                    style={{ width: 24, height: 24 }}
                    src={`/static/img/flaticon/emergency.svg`}
                    alt={t("Departments") as string}
                  />
                }
                iconPosition="start"
              />
              <Tab
                wrapped={false}
                label={matches ? undefined : t("Reports")}
                component={Link}
                sx={{ paddingY: 0, paddingX: 4, minWidth: 0 }}
                to={"reports"}
                value={hospitalRoutes[1]}
                icon={
                  <img
                    style={{ width: 24, height: 24 }}
                    src={`/static/img/flaticon/market-analysis.svg`}
                    alt={t("Reports") as string}
                  />
                }
                iconPosition="start"
              />
              <Tab
                wrapped={false}
                label={matches ? undefined : t("Data mappings")}
                component={Link}
                to={"mappings"}
                sx={{ paddingY: 0, paddingX: 4, minWidth: 0 }}
                value={hospitalRoutes[2]}
                icon={
                  <img
                    style={{ width: 24, height: 24 }}
                    src={`/static/img/flaticon/exchange.svg`}
                    alt={t("Data mappings") as string}
                  />
                }
                iconPosition="start"
              />
              <Tab
                wrapped={false}
                label={matches ? undefined : t("Services")}
                component={Link}
                to={"services"}
                sx={{ paddingY: 0, paddingX: 4, minWidth: 0 }}
                value={hospitalRoutes[3]}
                icon={
                  <img
                    style={{ width: 24, height: 24 }}
                    src={`/static/img/flaticon/health-check.svg`}
                    alt={t("Services") as string}
                  />
                }
                iconPosition="start"
              />
              <Tab
                wrapped={false}
                label={matches ? undefined : t("Materials")}
                component={Link}
                to={"materials"}
                sx={{ paddingY: 0, paddingX: 4, minWidth: 0 }}
                value={hospitalRoutes[4]}
                icon={
                  <img
                    style={{ width: 24, height: 24 }}
                    src={`/static/img/flaticon/material-management.svg`}
                    alt={t("Materials") as string}
                  />
                }
                iconPosition="start"
              />
              <Tab
                wrapped={false}
                label={matches ? undefined : t("Transactions")}
                component={Link}
                to={"transactions"}
                sx={{ paddingY: 0, paddingX: 4, minWidth: 0 }}
                value={hospitalRoutes[5]}
                icon={
                  <img
                    style={{ width: 24, height: 24 }}
                    src={`/static/img/flaticon/cash-flow.svg`}
                    alt={t("Transactions") as string}
                  />
                }
                iconPosition="start"
              />
            </Tabs>
          </Grid>
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <TabPanel value={value} index={value}>
            <Outlet />
          </TabPanel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Hospital;
