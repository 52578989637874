import React from "react";
import { Helmet } from "react-helmet-async";

import {
  Unstable_Grid2 as Grid,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  CardHeader,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBenefitDxContext } from "../../../contexts/BenefitDxContext";
import { NavLink } from "react-router-dom";
import { Add } from "@mui/icons-material";

type OverviewCardProps = {
  title: string;
  items: Array<{ Label: string; Link: string }>;
  description: string;
  link: string;
  icon?:
    | "emergency"
    | "hospital"
    | "key"
    | "surgical-area"
    | "team"
    | "transaction";
};

const OverviewCard = ({
  title,
  description,
  items,
  link,
  icon,
}: OverviewCardProps) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader
        avatar={
          Boolean(icon) ? (
            <Avatar variant="rounded">
              <img
                style={{ width: "100%", height: "100%" }}
                src={`/static/img/flaticon/${icon}.svg`}
                alt={icon}
              />
            </Avatar>
          ) : null
        }
        action={
          <Tooltip title={t("Add new")}>
            <IconButton
              component={NavLink}
              to={link}
              aria-label="add"
              id={`create-new-${title}`}
            >
              <Add />
            </IconButton>
          </Tooltip>
        }
        title={title}
      />
      <CardContent sx={{ p: 4 }}>
        <Typography variant="body2" sx={{ paddingY: 2 }}>
          {description}
        </Typography>
        <List>
          {items.map((item, i) => (
            <ListItem component={NavLink} to={item.Link} key={i}>
              {item.Label}
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

function Overview() {
  const { t } = useTranslation();
  const { hospitals, users } = useBenefitDxContext();
  return (
    <React.Fragment>
      <Helmet title="Overview" />

      <Grid justifyContent="space-between" container spacing={5} paddingTop={5}>
        <Grid xs={6}>
          <OverviewCard
            icon="hospital"
            title={t("Hospitals")}
            description={t(
              "View and compare statistics from all your hospitals"
            )}
            items={hospitals.map((hospital) => ({
              Label: `${hospital.Name}`,
              Link: `/hospitals/${hospital.Id}`,
            }))}
            link={"/hospitals/new"}
          />
        </Grid>

        <Grid xs={6}>
          <OverviewCard
            title={t("Users")}
            icon="team"
            description={t(
              "Manage the users who have access to your BenefitDx account"
            )}
            items={users.map((user) => ({
              Label: `${user.FirstName} ${user.LastName}`,
              Link: `/users/${user.Id}`,
            }))}
            link={"/users/new"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Overview;
