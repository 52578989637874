import styled from "@emotion/styled";
import {
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  InputBase,
  Typography as MuiTypography,
  Paper,
  Skeleton,
  ClickAwayListener,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Check, Edit } from "@mui/icons-material";
const Typography = styled(MuiTypography)(spacing);
export type KeyValuePairProps = {
  Key: string;
  Value: any;
  Loading: boolean;
  Editable?: boolean;
  OnSubmit?: (newValue: string) => void;
};

const PairContainer = styled(Grid)`
  margin-bottom: 20px;
  & > p {
    overflow-wrap: anywhere;
  }
`;

const ButtonOnHoverGrid = styled(Grid)`
  button {
    width: 48px;
    height: 48px;
    display: none;
  }

  &:hover {
    button {
      display: block;
    }
  }
`;

export const generateLabelFromKey = (key: string) =>
  key
    .split(/(?=[A-Z])/g)
    .map((k, i) => {
      if (!i) {
        return k;
      }
      return k.toLowerCase();
    })
    .join(" ");

export function KeyValuePair(props: KeyValuePairProps) {
  const { t } = useTranslation();
  const [key] = useState<string>(props.Key);
  const [value, setValue] = useState<any>(props.Value);
  const [editing, setEditing] = useState<boolean>(false);
  const { Editable, Loading, OnSubmit } = props;
  const label = useMemo(() => generateLabelFromKey(key), [key]);

  useEffect(() => {
    setValue(props.Value);
  }, [props.Value]);

  if (Loading) {
    return (
      <>
        <Skeleton height={24} width="60%" animation="wave" />
        <Skeleton width="100%" height={24} animation="wave" />
      </>
    );
  }

  return (
    <PairContainer
      flex={1}
      container
      direction={"column"}
      marginBottom={5}
      height={"48px"}
    >
      <ClickAwayListener
        onClickAway={() => {
          setEditing(false);
        }}
      >
        {editing ? (
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              height: "48px",
            }}
          >
            <InputBase
              sx={{ flex: 1, height: "100%" }}
              type="text"
              placeholder={label}
              inputProps={{ "aria-label": label }}
              defaultValue={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            <Divider sx={{ m: 0.5, height: 28 }} orientation="vertical" />
            <IconButton
              color="success"
              sx={{ p: "10px" }}
              onClick={() => {
                setEditing(false);
                if (OnSubmit) OnSubmit(value);
              }}
            >
              <Check />
            </IconButton>
          </Paper>
        ) : (
          <ButtonOnHoverGrid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ cursor: Editable ? "pointer" : "default", height: "48px" }}
          >
            <Grid container direction={"column"}>
              <Typography variant="subtitle2">
                {t(generateLabelFromKey(key))}
              </Typography>
              <Box marginTop={2}>{value}</Box>
            </Grid>
            {Editable && (
              <IconButton
                onClick={() => {
                  setEditing(true);
                }}
              >
                <Edit />
              </IconButton>
            )}
          </ButtonOnHoverGrid>
        )}
      </ClickAwayListener>
    </PairContainer>
  );
}
