import React from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { Link } from "react-router-dom";

import {
  AppBar,
  Button as MuiButton,
  Container,
  Unstable_Grid2 as Grid,
  Box,
  Toolbar,
  ListItemButton,
} from "@mui/material";

import { ReactComponent as Logo } from "../../../vendor/logo.svg";
import { useTranslation } from "react-i18next";
import Navbar from "../../../components/navbar/Navbar";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
  transform: rotate(90deg);
`;

const AppBarComponent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Navbar />
    </React.Fragment>
  );
};

export default AppBarComponent;
