import axios from "../utils/axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { Department, Hospital } from "../types";

export function useDepartments(hospital: Hospital | undefined) {
  const client = useQueryClient();
  const { session } = useAuth();

  const create = async (payload: Partial<Department>) =>
    await axios.post(`/departments/new`, {
      AccountId: hospital?.AccountId,
      HospitalId: hospital?.Id,
      ...payload,
    });

  const {
    mutate: createDepartment,
    isPending: isCreateDepartmentPending,
    error: createDepartmentError,
  } = useMutation({
    mutationFn: create,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["departments"],
      });
    },
  });
  const {
    mutate: deleteDepartment,
    isPending: isDeleteDepartmentPending,
    error: deleteDepartmentError,
  } = useMutation({
    mutationFn: async (DepartmentId: string) =>
      await axios.delete(`/departments/${DepartmentId}`),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["departments"],
      });
    },
  });

  const {
    error: listDepartmentsByHospitalError,
    data: departments,
    isPending: isListDepartmentsByHospitalPending,
  } = useQuery({
    enabled: Boolean(hospital?.Id) && session?.isValid(),
    refetchOnWindowFocus: false,
    queryKey: ["hospitals", hospital?.Id, "departments"],
    queryFn: async () =>
      (await axios.post(`/hospitals/${hospital?.Id}/departments`)).data.Items,
  });

  const update = async (payload: Partial<Department>) =>
    await axios.put(`/departments/${payload.Id}`, {
      HospitalId: hospital?.Id,
      ...payload,
    });

  const {
    mutate: updateDepartment,
    isPending: isUpdateDepartmentPending,
    error: updateDepartmentError,
  } = useMutation({
    mutationFn: update,
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["hospitals", hospital?.Id, "departments"],
      });
    },
  });

  const {
    mutate: selectDepartmentsForHospital,
    isPending: isSelectDepartmentsForHospital,
    error: selectDepartmentsForHospitalError,
  } = useMutation({
    mutationFn: async (payload: string[]) =>
      await axios.post(
        `/hospitals/${hospital?.Id}/select-departments`,
        payload,
      ),
    onSuccess: () => {
      // Invalidate and refetch
      client.invalidateQueries({
        queryKey: ["hospitals", hospital?.Id, "departments"],
      });
    },
  });

  return {
    departments: departments || [],
    createDepartment,
    isCreateDepartmentPending,
    createDepartmentError,
    deleteDepartment,
    isDeleteDepartmentPending,
    deleteDepartmentError,
    updateDepartment,
    isUpdateDepartmentPending,
    updateDepartmentError,
    listDepartmentsByHospitalError,
    isListDepartmentsByHospitalPending,
    selectDepartmentsForHospital,
    isSelectDepartmentsForHospital,
    selectDepartmentsForHospitalError,
  };
}
